<app-toolbar>
  <div [formGroup]="shareForm">
    <!-- <div class="addthis_inline_share_toolbox_eclv"></div> -->
    <app-toolbar-item>
      <button (click)="share()" data-toggle="tooltip" data-placement="left" title="Share"
        class="btn text-gray text-hover-mint">
        <i _ngcontent-serverapp-c1212335392="" class="fa-regular fa-share" aria-hidden="true"></i>  
        <!--<svg role="img">
          <use xlink:href="#icon-share"></use>
        </svg>-->
      </button>
    </app-toolbar-item>
    <app-toolbar-item>
      <button (click)="createRss()" data-toggle="tooltip" data-placement="left" title="RSS"
        class="btn text-gray text-hover-mint">
        <i _ngcontent-serverapp-c1212335392="" class="fa-solid fa-rss" aria-hidden="true"></i>        
        <!--<svg role="img">
          <use xlink:href="#icon-rss"></use>
        </svg>-->
      </button>
    </app-toolbar-item>
  </div>
</app-toolbar>