import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { profileInternalActions } from '../actions';
import { ProfileInternalOutputApiModel } from '../models/api-models/output';
import { ProfileInternalHttpService } from '../services/profile-internal.http-service';

@Injectable()
export class ProfileInternalEffects {

    
    loadInternalProfileEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profileInternalActions.LoadInternalProfile>(profileInternalActions.ActionTypes.LOAD_INTERNAL_PROFILE),
        switchMap((action: profileInternalActions.LoadInternalProfile) => {
            return this.ProfileInternalHttpService.loadProfile(action.companyId).pipe(
                map((response: ProfileInternalOutputApiModel) => {
                    return new profileInternalActions.LoadInternalProfileSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new profileInternalActions.LoadInternalProfileFailure(error));
                })
            )
        })
    ));

    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profileInternalActions.UpdateProfile>(profileInternalActions.ActionTypes.UPDATE_PROFILE),
        switchMap((action: profileInternalActions.UpdateProfile) => {
            return this.ProfileInternalHttpService.updateProfile(action.companyId, action.payload).pipe(
                map(() => {
                    return new profileInternalActions.UpdateProfileSuccess();
                }),
                catchError((error: any) => {
                    return of(new profileInternalActions.UpdateProfileFailure(error));
                }))
        })
    ));

    
    getdomainName$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profileInternalActions.GetDomainName>(profileInternalActions.ActionTypes.GET_DOMAIN_NAME),
        switchMap((action: profileInternalActions.GetDomainName) => {
            return this.ProfileInternalHttpService.getDomainName(action.domainname).pipe(
                map((response: any) => {
                    return new profileInternalActions.GetDomainNameSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new profileInternalActions.GetDomainNameFailure(error));
                }))
        })
    ));

    
    getdomainDetail$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profileInternalActions.GetDomainDetail>(profileInternalActions.ActionTypes.GET_DOMAIN_DETAIL),
        switchMap((action: profileInternalActions.GetDomainDetail) => {
            return this.ProfileInternalHttpService.getDomainDetail(action.sendgridid).pipe(
                map((response: any) => {
                    return new profileInternalActions.GetDomainDetailSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new profileInternalActions.GetDomainDetailFailure(error));
                }))
        })
    ));

    
    savetodatabaseeffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profileInternalActions.SaveDatabase>(profileInternalActions.ActionTypes.SAVE_DATABASE),
        switchMap((action: profileInternalActions.SaveDatabase) => {
            return this.ProfileInternalHttpService.savedomaintodatbase(action.companyId,action.sendGrididIfd,action.payload).pipe(
                map((response: any) => {
                    return new profileInternalActions.SaveDatabaseSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new profileInternalActions.SaveDatabaseFailure(error));
                }))
        })
    ));

    
    verifyfromsendgriapi$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profileInternalActions.VerifySendgrid>(profileInternalActions.ActionTypes.VERIFY_SENDGRID),
        switchMap((action: profileInternalActions.VerifySendgrid) => {
            return this.ProfileInternalHttpService.verifydomainfromsendgrid(action.sendGrididIfd).pipe(
                map((response: any) => {
                    return new profileInternalActions.VerifySendgridSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new profileInternalActions.VerifySendgridFailure(error));
                }))
        })
    ));


    constructor(
        private actions$: Actions,
        private ProfileInternalHttpService: ProfileInternalHttpService) { }
}

