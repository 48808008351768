import { Component, ElementRef, Inject, SimpleChanges, ViewChild } from "@angular/core";
import { Observable, Subscription, BehaviorSubject, Subject } from "rxjs";
import { Store } from "@ngrx/store";

import { ComponentBase } from "@framework/base/component";
import { CoreState } from "@app/store/core/states";
import { StoryViewModel } from "@modules/story/models/view-models";
import { companySelectors } from "@modules/company/store/selectors";
import { CompanyViewModel } from "@modules/company/models/store-view-models";
import { RssFeedLinkModalComponent } from "@modules/shared/components/commons/rss-feed-link-modal/rss-feed-link-modal.component";
import { ShareModalContainerComponent } from '@modules/shared/components/commons/share-modal-container/share-modal-container.component';
import { ModalService, SocialSharingService } from "@framework/services";
import { storySelector } from "@modules/story/store/selectors";
import { map, filter, takeUntil } from "rxjs/operators";
import { CompanyHttpService } from "@modules/company/services";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { environment } from '@app/env/environment';
import { Router } from '@angular/router';

@Component({
  selector: "co-pub-company-toolbar",
  templateUrl: "./company-toolbar.component.html",
  styleUrls: ["./company-toolbar.component.scss"]
})
export class CompanyToolbarComponent extends ComponentBase {
  // @ViewChild("addthis") addthis: ElementRef;

  // Private variables
  private selectSelectedCompany$: Observable<CompanyViewModel>;
  // private _selectQueryData$: Observable<SearchStoryInputApiModel | GetStoriesInputApiModel>;

  private selectSelectedCompanySubscription: Subscription;
  private _selectQueryDataSubscription: Subscription;

  public queryData: any;

  // Public variables
  public storyDetail: StoryViewModel;
  public isLoading: boolean = true;
  public selectedCompany: CompanyViewModel;

  private _unsubscribeAll: Subject<void>;
  public campaignQueryData: any;
  public featuredQueryData: any;
  public shareForm: UntypedFormGroup;
  private shareUrl: string;

  constructor(
    private store$: Store<CoreState>,
    private modalService: ModalService,
    private _companyService: CompanyHttpService,
    private socialSharingService: SocialSharingService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    super();
    this._unsubscribeAll = new Subject();
    this.selectSelectedCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    // this._selectQueryData$ = this.store$.select<SearchStoryInputApiModel | GetStoriesInputApiModel>(storySelector.selectQueryData);
    this.initForm();
  }

  private initForm() {
    this.shareForm = this.formBuilder.group({
      link: [this.shareUrl]
    });
  }

  // Life cycle hook
  protected onInit(): void {
    this.shareUrl = window.location.origin + this.router.url;
    this.subscribe();

    this._companyService.campaignQueryData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this.campaignQueryData = value;
      });
    this._companyService.featuredQueryData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        this.featuredQueryData = value;
      });
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void { }
  protected onDoCheck(): void { }
  protected onAfterContentInit(): void {
    setTimeout(()=>{
      if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
    },500)
    
  }
  protected onAfterContentChecked(): void { }
  protected onAfterViewInit(): void { }
  protected onAfterViewChecked(): void { }
  // Private functions
  private subscribe() {
    this.selectSelectedCompanySubscription = this.selectSelectedCompany$.subscribe(
      selectedCompany => {
        if (selectedCompany) {
          this.selectedCompany = selectedCompany;
          this.isLoading = false;
        }
        // this.addthis.nativeElement.setAttribute("data-url", this.shareUrl);

        setTimeout(()=>{
          if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
        },500)
        
        this.shareForm.controls.link.setValue(this.shareUrl);
      }
    );

    this._selectQueryDataSubscription = this.store$
      .pipe(
        map(state => storySelector.selectQueryData(state)),
        filter(val => val !== null)
      )
      .subscribe(resQueryData => {
        this.queryData = resQueryData;
      });
  }

  private unsubscribe() {
    this.selectSelectedCompanySubscription.unsubscribe();
    this._selectQueryDataSubscription.unsubscribe();

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Public functions
  public share() {
    let publicUrl  = `${window.location.origin}${environment.endpointUrl}/companies/${this.queryData.companyId}/stories/?${this.queryData.queryWithoutPaination}&rss=1&public=1`
    localStorage.setItem('feed_rss',publicUrl)
    this.modalService
      .showModal(ShareModalContainerComponent, {})
      .then(() => {
        localStorage.removeItem('feed_rss')
       });
  }

  public createRss() {
    const url = `${window.location.origin}${environment.endpointUrl}/companies/${this.queryData.companyId}/stories/?${this.queryData.queryWithoutPaination}&rss=1`;
    this.modalService.showModal(RssFeedLinkModalComponent, url);
  }
}
