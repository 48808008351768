<div *ngIf="campaign" [ngClass]="{'add-overlay':campaign.enable_overlay}" [background-image]="campaign.headerImage" class="b-timeline">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5">
        <div class="m-ld-info">
          <div *ngIf="campaign.avatarImage" class="m-ld-info__logo">
            <img class="avatar avatar--xl" [src]="campaign.avatarImage" alt="{{campaign.title}} logo" />
          </div>
          <div class="m-ld-info__content hero">
            <h2 *ngIf="campaign.title">{{campaign.title}}</h2>
            <h4 *ngIf="campaign.description">{{campaign.description}}</h4>
            <p *ngIf="campaign.person" class="mb-0 mt-2">{{campaign.person}}</p>
          </div>
        </div>
      </div>
      <div class="d-block d-md-inline-flex btn-group mr-5 btn-campaign-design" *ngIf="!insideIframe">
        <a type="button" *ngFor="let button of getButtons()" 
           [style.background-color]="button.background" 
           [style.color]="button.foreground" 
           class="btn btn-sq btn-white" 
           href="{{button.url}}" 
           target="_blank" 
           rel="noopener noreferrer" 
           tabindex="0"
           [attr.aria-label]="'Visit' + button.label">
          {{button.label}}
        </a>
        <ng-container *ngIf="campaign && (campaign.interview_style) && showSubmitStory">
          <a *ngIf="campaign.daysLeft >= 0" 
             type="button" 
             (click)="navgateToInterviewStyle()"
             class="btn btn-sq btn-pink btn-tab" 
             [style.background]="btnsubmit" 
             [style.color]="txtsubmit" 
             tabindex="0"
             [attr.aria-label]="'Submit story for' +  campaign.title">
            {{campaign?.submit_story_label}}
          </a>
        </ng-container>
        <ng-container *ngIf="campaign && !campaign.interview_style && showSubmitStory">
          <a *ngIf="selectedCompany.isInboundStoriesEnabled && campaign.daysLeft >= 0 && showSubmitStory"  
             [style.background]="btnsubmit" 
             [style.color]="txtsubmit" 
             [routerLink]="['/t/' + campaign.companySlug + '/testimonial/create']" 
             [queryParams]="{cs: campaign.slug}" 
             class="btn btn-sq btn-pink btn-tab" 
             type="button" 
             tabindex="0"
             [attr.aria-label]="'Create testimonial for' + campaign.title">
            {{campaign?.submit_story_label}}
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>