import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { userActions } from '../actions';
import { UserHttpService } from '../services';
import { ChangeUsernameService } from '../services/change-user.http-service';

@Injectable()
export class UserEffects {

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.Load>(userActions.ActionTypes.LOAD),
    switchMap((action: userActions.Load) => {
      return this.UserHttpService.load(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new userActions.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new userActions.LoadFail(error));
        }));
    })
  ));

  
  createEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.Create>(userActions.ActionTypes.CREATE),
    switchMap((action: userActions.Create) => {
      return this.UserHttpService.create(action.companyId, action.queryData).pipe(
        map((response) => {
          return new userActions.CreateSuccess({...response,companyId:action.companyId, queryData:action.queryData});
        }),
        catchError((error: any) => {
          return of(new userActions.CreateFail(error));
        }));
    })
  ));

  
  createCSVEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.CreateCSV>(userActions.ActionTypes.CREATE_CSV),
    switchMap((action: userActions.CreateCSV) => {
      return this.UserHttpService.createCSV(action.companyId, action.queryData).pipe(
        map((response) => {
          return new userActions.CreateCSVSuccess(response);
        }),
        catchError((error: any) => {
          return of(new userActions.CreateCSVFail(error));
        }));
    })
  ));

  
  enableAccessEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.EnableAccess>(userActions.ActionTypes.ENABLE_ACCESS),
    switchMap((action: userActions.EnableAccess) => {
      return this.UserHttpService.enableAccess(action.companyId, action.ids).pipe(
        map((respone) => {
          return new userActions.EnableAccessSuccess();
        }),
        catchError((error: any) => {
          return of(new userActions.EnableAccessFail(error));
        }));
    })
  ));

  
  disableAccessEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.DisableAccess>(userActions.ActionTypes.DISABLE_ACCESS),
    switchMap((action: userActions.DisableAccess) => {
      return this.UserHttpService.disableAccess(action.companyId, action.ids).pipe(
        map((respone) => {
          return new userActions.DisableAccessSuccess();
        }),
        catchError((error: any) => {
          return of(new userActions.DisableAccessFail(error));
        }));
    })
  ));

  
  makeAdminEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.MakeAdmin>(userActions.ActionTypes.MAKE_ADMIN),
    switchMap((action: userActions.MakeAdmin) => {
      return this.UserHttpService.makeAdmin(action.companyId, action.ids).pipe(
        map((respone) => {
          return new userActions.MakeAdminSuccess();
        }),
        catchError((error: any) => {
          return of(new userActions.MakeAdminFail(error));
        }));
    })
  ));


  
  changeRolesOfUserEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.ChangeRoles>(userActions.ActionTypes.CHANGE_ROLES),
    switchMap((action: userActions.ChangeRoles) => {
      return this.UserHttpService.changeUserRoles(action.companyId,action.ids,action.roleId).pipe(
        map((respone) => {
          return new userActions.ChangeRolesSuccess();
        }),
        catchError((error: any) => {
          return of(new userActions.ChangeRolesFail(error));
        }));
    })
  ));

  
  makeRegularEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.MakeRegular>(userActions.ActionTypes.MAKE_REGULAR),
    switchMap((action: userActions.MakeRegular) => {
      return this.UserHttpService.makeRegular(action.companyId, action.ids).pipe(
        map((respone) => {
          return new userActions.MakeRegularSuccess();
        }),
        catchError((error: any) => {
          return of(new userActions.MakeRegularFail(error));
        }));
    })
  ));

  
  sendEmailAccessEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.SendEmail>(userActions.ActionTypes.SEND_EMAIL),
    switchMap((action: userActions.SendEmail) => {
      return this.UserHttpService.sendEmail(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new userActions.SendEmailSuccess();
        }),
        catchError((error: any) => {
          return of(new userActions.SendEmailFail(error));
        }));
    })
  ));


  
  changeusername$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userActions.ChangeUsername>(userActions.ActionTypes.CHANGE_USER_NAME),
    switchMap((action: userActions.ChangeUsername) => {
      return this.changesvc.changeusername(action.payload,action.id).pipe(
        map(() => {
          return new userActions.ChangeUsernameSuccess();
        }),
        catchError((error: any) => {
          return of(new userActions.ChangeUsernameFail(error));
        })
      );
    })
  ));


 

  constructor(
    private actions$: Actions,
    private UserHttpService: UserHttpService,
    private changesvc:ChangeUsernameService) { }
}
