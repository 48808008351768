import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';



@Injectable()
export class ApprovalFlowHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public GetListApproval(companyId: number, query:any) {
  if(!query){
    return this.get(`/${companyId}/campaigns/responses/?timestamp:${Date.now().toString()}`).pipe(
        map((response: any) => {
            return response;
        })
    )
  }
  else{
    return this.get(`/${companyId}/campaigns/responses/?${query}timestamp:${Date.now().toString()}`,).pipe(
        map((response: any) => {
            return response;
        })
    )
  }

    }

    public rejectFullStory(comapny_id,payload){
        return this.patch(`/${comapny_id}/campaigns/responses/story/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        ) 
    }

    public GetIsApproval(companyId: number, query:any) {
        if(!query){
          return this.get(`/${companyId}/campaigns/responses/`).pipe(
              map((response: any) => {
                  return response;
              })
          )
        }
        else{
          return this.get(`/${companyId}/campaigns/responses/?${query}`,).pipe(
              map((response: any) => {
                  return response;
              })
          )
        }
      
          }

    public GetListOfPersons(companyId: number,story_Id:any) {
        return this.get(`/${companyId}/campaigns/steps/users_detail/?story_id=${story_Id}`).pipe(
            map((response: any) => {
                return response;
            })
        )
    }

    public ApproveRejectStep(companyId: number, responseId: number, payload: any) {
        return this.patch(`/${companyId}/campaigns/responses/${responseId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public CreateComment(companyId: number, response_Id:any, payload:any) {
        return this.post(`/${companyId}/campaigns/responses/${response_Id}/comments/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public UpdateComment(companyId: number, response_Id: any,commnt_id:any ,payload:any) {
        return this.put(`/${companyId}/campaigns/responses/${response_Id}/comments/${commnt_id}/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }


    public DeleteComment(companyId: number, response_Id: any, commentId:any) {
        return this.delete(`/${companyId}/campaigns/responses/${response_Id}/comments/${commentId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public GetComment(companyId: number, response_Id: any) {
        return this.get(`/${companyId}/campaigns/responses/${response_Id}/comments/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public GetUserList(companyId: number,role){
        return this.get(`/${companyId}/campaigns/steps/users-by-role/?role=${role}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public createStep(companyId: number, campaign_Id:any, payload:any){
        return this.post(`/${companyId}/campaigns/${campaign_Id}/steps/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }




    public getStep(companyId: number,campaign_id:any){
        return this.get(`/${companyId}/campaigns/${campaign_id}/steps/?timestamp:${Date.now().toString()}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteStep(companyId: number, campaign_id:any,step_id){
        return this.delete(`/${companyId}/campaigns/${campaign_id}/steps/${step_id}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

}
