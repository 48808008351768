import { PaginationModel } from "@framework/models/pagination-models";
import { SearchQueryBuilder } from "@framework/models/commons";

export interface SearchStoryInputApiModel {
    companyId: number;
    isMyStoriesOnly: boolean;
    tags: any[];
    campaigns: any[];
    authors?: any[];
    peoples: any[];
    keywords: string[];
    public: boolean;
    userId: number;
    internal?: boolean
    pagination: PaginationModel;
    includeStory: boolean;
    includeTestimonial: boolean;
    internaltags?:any;
    spotlightOnly?: boolean;
    fromToken?: boolean;

}

export class SearchStoryInputApiModel {
    constructor() {
        this.pagination = new PaginationModel(12);
        this.includeStory = true;
        this.includeTestimonial = true;
    }

    // Private functions
    private buildQuery(isIncludePagiantion: boolean) {
        let queryBuilder = new SearchQueryBuilder();

        // Put queries

        if (this.keywords && this.keywords.length > 0) {
            queryBuilder.andQuery(this.keywords);
        }

        if (this.tags && this.tags.length > 0) {
            queryBuilder.andFilter('tags', encodeURIComponent(this.tags.join(',')));
        }

        if (this.campaigns && this.campaigns.length > 0) {
            queryBuilder.andFilter('campaigns', this.campaigns.join(','));
        }

        if (this.authors && this.authors.length > 0) {
            queryBuilder.andFilter('authors', this.authors.join(','));
        }


        if (this.peoples && this.peoples.length > 0) {
            queryBuilder.andFilter('players', this.peoples.join(','));
        }

        if (this.isMyStoriesOnly) {
            queryBuilder.andUser(this.userId.toString());
        }

        if(this.internal){
            queryBuilder.andParam('internal', '1');
        }

        if (this.spotlightOnly) {
            queryBuilder.andParam('spotlight_only', 'True');
        }

        if (!this.includeStory) {
            queryBuilder.andParam('includeStories', 'no');
        }

        if (!this.includeTestimonial) {
            queryBuilder.andParam('includeTestimonials', 'no');
        }

        if (this.public) {
            queryBuilder.andParam('public', '1');
        }

        if(this.fromToken){
            queryBuilder.andParam('fromToken', '1');
        }

        if (isIncludePagiantion) {

            if (this.pagination.sortBy) {
                queryBuilder.addSort(this.pagination.sortBy, this.pagination.sort);
            }

            queryBuilder.setPage(this.pagination.page);
            queryBuilder.setLimit(this.pagination.limit);
        }


        return queryBuilder.build();
    }

    // Public functions
    public get query() {
        return this.buildQuery(true);
    }

    public get queryWithoutPaination() {
        return this.buildQuery(false);
    }

    public init(queryParams: any) {
        this.keywords = [queryParams.keyword];
        this.userId = queryParams.uid;
        if (this.userId) {
            this.isMyStoriesOnly = true;
        }

        this.includeStory = queryParams.includeStories == 'no' ? false : true;
        this.includeTestimonial = queryParams.includeTestimonials == 'no' ? false : true;

        this.pagination = new PaginationModel(12);

        if (queryParams.filter) {
            const filterItems = queryParams.filter.split('|');
            filterItems.forEach((fItem: string) => {
                if (fItem.indexOf('tags:') > -1) {
                    this.tags = fItem.split(':')[1].split(',');
                }
                if (fItem.indexOf('campaigns:') > -1) {
                    this.campaigns = fItem.split(':')[1].split(',');
                }
                if (fItem.indexOf('players:') > -1) {
                    this.peoples = fItem.split(':')[1].split(',');
                }
            });
        }
    }

    
}
