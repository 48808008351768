import { Action } from '@ngrx/store';

import { SearchCompanyOutputApiModel, WorkAttributeOutputApiModel } from '../../models/api-models/output';

export enum ActionTypes {
    LOAD_WORK_ATTRIBUTES = "[Company Setting] Load Work Attributes",
    LOAD_WORK_ATTRIBUTES_SUCCESS = "[Company Setting] Load Work Attributes Success",
    LOAD_WORK_ATTRIBUTES_FAILURE = "[Company Setting] SeaLoad Work Attributesrch Failure"
}

export class LoadWorkAttributes implements Action {
    readonly type = ActionTypes.LOAD_WORK_ATTRIBUTES;

    constructor(public companyIdSlug: number | string) { }
}

export class LoadWorkAttributesSuccess implements Action {
    readonly type = ActionTypes.LOAD_WORK_ATTRIBUTES_SUCCESS;

    constructor(public response: WorkAttributeOutputApiModel[]) { }
}

export class LoadWorkAttributesFailure implements Action {
    readonly type = ActionTypes.LOAD_WORK_ATTRIBUTES_FAILURE;

    constructor(public error: any) { }
}

export type Actions =
    LoadWorkAttributes | LoadWorkAttributesSuccess | LoadWorkAttributesFailure;
