<div class="modal-header pb-0">
  <h4 class="modal-title">Consent Form</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body" [formGroup]="consentForm">
  <div class="template_html">
    <div [innerHtml]="template"></div>
  </div>
<div class="option_body">
<h6 *ngIf="show_name">
    My Name <span class="madatory">*</span>
</h6>
<div class="row mb-4" *ngIf="show_name">
  <div class="col-12" *ngIf="responseData?.is_real_name">
    <div class="custom-control custom-radio">
        <label class="mb-0">
            <input type="radio" formControlName="from_name" value="is_real_name"
                class="custom-control-input">
            <span class="custom-control-label">My real full name can be used</span>
        </label>
    </div>
</div>
<div class="col-12"  *ngIf="responseData?.is_first_name">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_name" value="is_first_name"
              class="custom-control-input">
          <span class="custom-control-label">My First Name only can be used</span>
      </label>
  </div>
</div>
<div class="col-12" *ngIf="responseData?.is_initials_name" >
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_name" value="is_initials_name"
              class="custom-control-input">
          <span class="custom-control-label">My Initials only can be used</span>
      </label>
  </div>
</div>
<div class="col-12" *ngIf="responseData?.is_fake_name">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_name" value="is_fake_name"
              class="custom-control-input">
          <span class="custom-control-label">Use a fake name to hide my identity</span>
      </label>
  </div>
</div>
</div>
<h6 *ngIf="show_image">
    My Image in video or print <span class="madatory">*</span>
</h6>
<div class="row mb-4" *ngIf="show_image">
  <div class="col-12" *ngIf="responseData?.is_full_image">
    <div class="custom-control custom-radio">
        <label class="mb-0">
            <input type="radio" formControlName="from_image" value="is_full_image"
                class="custom-control-input">
            <span class="custom-control-label">My image can be used completely</span>
        </label>
    </div>
</div>
<div class="col-12"  *ngIf="responseData?.is_blurred_image">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_image" value="is_blurred_image"
              class="custom-control-input">
          <span class="custom-control-label">Images of me must be blurred or hidden</span>
      </label>
  </div>
</div>
<div class="col-12" *ngIf="responseData?.is_image_social_media">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_image" value="is_image_social_media"
              class="custom-control-input">
          <span class="custom-control-label">I allow my image or likeness in digital media</span>
      </label>
  </div>
</div>
<div class="col-12"  *ngIf="responseData?.is_video_use">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_image" value="is_video_use"
              class="custom-control-input">
          <span class="custom-control-label">I allow my image or likeness in video</span>
      </label>
  </div>
</div>
<div class="col-12" *ngIf="responseData?.is_image_in_any_media">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_image" value="is_image_in_any_media"
              class="custom-control-input">
          <span class="custom-control-label">I allow my image or likeness in any media </span>
      </label>
  </div>
</div>
<div class="col-12" *ngIf="responseData?.not_use_image">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_image" value="not_use_image"
              class="custom-control-input">
          <span class="custom-control-label">Do not use my image or likeness</span>
      </label>
  </div>
</div>
</div>
<h6 *ngIf="show_voice"> 
    My Voice <span class="madatory">*</span>
</h6>
<div class="row mb-4" *ngIf="show_voice">
  <div class="col-12"  *ngIf="responseData?.is_real_voice">
    <div class="custom-control custom-radio">
        <label class="mb-0">
            <input type="radio" formControlName="from_voice" value="is_real_voice"
                class="custom-control-input">
            <span class="custom-control-label">My real voice can be used</span>
        </label>
    </div>
</div>
<div class="col-12" *ngIf="responseData?.is_masked_voice">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_voice" value="is_masked_voice"
              class="custom-control-input">
          <span class="custom-control-label">My real voice must be masked or adjusted</span>
      </label>
  </div>
</div>
<div class="col-12" *ngIf="responseData?.is_voice_transcription">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_voice" value="is_voice_transcription"
              class="custom-control-input">
          <span class="custom-control-label">Do not use my voice, but transcription is allowed.</span>
      </label>
  </div>
</div>
</div>

<h6 *ngIf="show_other">
  Other
</h6>
<div class="row mb-4" *ngIf="show_other">
<div class="col-12" *ngIf="responseData?.is_name_advertising">
  <div class="custom-control custom-radio">
      <label class="mb-0">
          <input type="radio" formControlName="from_other" value="is_name_advertising"
              class="custom-control-input">
          <span class="custom-control-label">I allow my name to be used for advertising</span>
      </label>
  </div>
</div>
<div class="col-12" *ngIf="responseData?.is_image_media_advertising">
<div class="custom-control custom-radio">
    <label class="mb-0">
        <input type="radio" formControlName="from_other" value="is_image_media_advertising"
            class="custom-control-input">
        <span class="custom-control-label">I allow my image or likeness to be used in advertising</span>
    </label>
</div>
</div>
</div>
<div class="sign_container" *ngIf="signatureNeeded === false">
    <img src="{{ signatureImg }}" alt="" srcset="" />
  </div>
<div class="signature_content">
    <!-- app.component.html -->
    <h6>Please Add your signature here <span class="madatory">*</span></h6>
    <canvas
      #canvas
      (touchstart)="startDrawing($event)"
      (touchmove)="moved($event)"
      width="280"
      height="50"
      style="border: 1px solid #efefef;"
    ></canvas>
    <p class="error-message" *ngIf="signatureNeeded">Signature is required</p>
    
    <div class="buttons flex justify-between mt-2">
      <button class="btn" (click)="clearPad()">Clear</button>
      <button  class="btn" (click)="savePad()">Submit the signature</button>
    </div>
    
    </div>
  </div>
<div class="modal-footer" >
  <div>
    <button (click)="close()" type="button" data-dismiss="modal"
    class="btn btn-outline btn-gray-light min-width">Cancel</button>
  <button (click)="submitForm()" [disabled]="consentForm.invalid"  type="button" class="btn btn-pink min-width">
    <i
    *ngIf="isUpdating" class="fas fa-spinner fa-pulse"></i>
   Save
  </button>
  </div>

</div>
</div>


<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->