import { Component, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import { CoreState } from '@app/store/core/states';
import { environment } from '@app/env/environment';
import * as Saction from '@modules/company-admin/store/actions'

import { Page } from '@framework/base';
import { campaignActions, companyActions, searchBoxActions } from '@modules/company/store/actions';
import { companySelectors, campaignSelectors, searchBoxSelectors } from '@modules/company/store/selectors';
import { CompanyViewModel, CampaignViewModel, SearchBoxStateViewModel } from '@modules/company/models/store-view-models';
import { storyActions } from '@modules/story/store/actions';
import { SeoService } from '@modules/shared/services/seo.service';
import { MetaTagConfig } from "@modules/shared/models/common/seo.model";
import { storySelector } from '@modules/story/store/selectors';
import { StoryFacetedSearchViewModel } from '@modules/story/models/store-view-models';
import { ScriptActions } from '@modules/company-admin/store/actions';
import { isPlatformBrowser } from '@angular/common';
import { CompanyHttpService } from '@modules/company/services';
declare const gtag: Function;
declare var dataLayer;
declare var window:any;
declare var $: any;

let measuremnetId;
let globalTag;
let pagename;
@Component({
  selector: 'app-pb-campaign-page',
  templateUrl: './public-campaign.page.html',
  styleUrls: ['./public-campaign.page.scss']
})
export class CampaignPage extends Page {
  // Private variables
  private selectCompany$: Observable<CompanyViewModel>;
  private selectCampaignDetail$: Observable<CampaignViewModel>;
  private baseUrl: string = environment.baseUrl;

  private selectCompanySubscription: Subscription;

  private selectCampaignDetailSubscription: Subscription;
  public selectFacetedSearchResultSubscription: Subscription;
  private selectSearchBoxStatusSubscription: Subscription;
 private LoadSuccessSubscriptin: Subscription;
 private LoadButtonColorSuccessSubscriptin: Subscription;
  public companyId: any;
  public campaignSlug: string = '';
  public submit_text_Color: any;
  public submit_btn:any;
  // Public variables
  private selectSearchBoxStatus$: Observable<SearchBoxStateViewModel>;
  public selectedCompany: CompanyViewModel;
  public companySlug:any;
  public campaign: CampaignViewModel;
  public isIframeMode: boolean = false;
  public showtagsboolean: boolean = true;
  public showHeaderboolean: boolean = true;
  public campaignid: any;
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  public weAppreciate: number = 8;  
  public frequenttags: any;
  public searchBoxStatus: SearchBoxStateViewModel;
  public showFooter: boolean = true;
  private selectFacetedSearchResult$: Observable<StoryFacetedSearchViewModel>;
  public  isBrowser: boolean;
  public weValue: number = 14;
  public ourVibes: number = 13;
  public ourteams: number = 2;
  public ourChallenges: number = 11;
  counter = new Array(3);

  constructor(private activatedRoute: ActivatedRoute, private actions$: Actions,private router: Router, private store$: Store<CoreState>,@Inject(PLATFORM_ID) platformId: Object,private seoService: SeoService,private companyHttpService: CompanyHttpService) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectCampaignDetail$ = this.store$.select(campaignSelectors.selectCampaign);
    this.selectSearchBoxStatus$ = this.store$.select(searchBoxSelectors.selectSearchBoxStatus);
    this.selectFacetedSearchResult$ = this.store$.select(storySelector.selectFacetedSearchResults);

    this.captureParams();
  }

  // Life cycle hook
  protected onInit(): void {
    if(this.isBrowser){
      this.updateTagsForSSR();
      this.subscribe();
      if (window.location !== window.parent.location) {
        // this.insideIframe = true;
        var storedata = window.location.href.split("?");
        if(storedata.length == 2){
          if(storedata[1].split('&').includes('showHeader=true')){
            this.showHeaderboolean = true;
          }
          else{
            this.showHeaderboolean = false;
          }
  
          if(storedata[1].split('&').includes('showtagheader=true')){
            this.showtagsboolean = true;
          }
          else{
            this.showtagsboolean = false;
          }
          if(storedata[1].split('&').includes('showfooter=true')){
            this.showFooter = true;
            localStorage.setItem('showfooter','true')
          }
          else{
            this.showFooter = false;
            localStorage.setItem('showfooter','false')
          }
        }
        else{
          this.showHeaderboolean = false;
          this.showtagsboolean = false;
          this.showFooter = true;
          localStorage.setItem('showfooter','true')
        }
        //document.getElementsByClassName("site-container")[0]['style'].padding = 0;
      } else {
        localStorage.setItem('showfooter','true')
        this.showHeaderboolean = true;
        this.showtagsboolean = true;
        this.showFooter = true;
      }
    }
    else{
      this.updateTagsForSSR();
    }
 
  }
  protected onDestroy(): void {
    if(this.isBrowser){
      this.unsubscribe();
      this.resetStates();
      this.removeScripts();
    }

    this.seoService.clearMetaTags();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    
  }
  protected onAfterViewChecked(): void {
    if (this.isIframeMode) {
      this.sendPostMessage();
    }
  }

  // Private functions
  private addMetaTags(): void {
    const config: MetaTagConfig = new MetaTagConfig();
    config.title = this.campaign.title;
    config.url = 'https://'+this.companySlug+'/c/'+this.companySlug+'/campaigns';
    config.image = this.campaign.headerImage;
    config.description = this.campaign.description;
    this.seoService.updateMetaTags(config);
  }

  private subscribe() {
    this.selectCompanySubscription = this.selectCompany$.subscribe((company) => {
      if (company) {
        this.companyId = company.id
        this.store$.dispatch(new Saction.storyActions.GetColor(this.companyId));
        this.selectedCompany = company;
        this.currentCompany = company;
        this.loadScripts();
        pagename = this.currentCompany.name+'PUBLIC_CAMPAIGN_PAGE'
        this.loadDetail();
      }
    });

    this.LoadSuccessSubscriptin = this.actions$.pipe(
      ofType<ScriptActions.LoadSuccess>(ScriptActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((action:any) => {
         
      if(action && action.response.results && action.response.results.length){
        var scripts = action.response.results.filter(script => script.is_accessible);
        if(this.isBrowser){
          this.appendScriptinProduct(scripts);
        }

  
      }
    });

    this.selectCampaignDetailSubscription = this.selectCampaignDetail$.subscribe((campaign) => {
      if (campaign) {
        this.campaign = campaign;
        this.campaignid = campaign.id;
       this.addMetaTags();
      }
    });

    this.LoadButtonColorSuccessSubscriptin = this.actions$.pipe(
      ofType<Saction.storyActions.GetColorSuccess>(Saction.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action:any) => {
      
       if(action && action.response && action.response && action.response.length){
        this.submit_text_Color = action.response.find(item => item.button == 10).color;
        this.submit_btn = action.response.find(item => item.button == 7).color;
       }
    })

    this.selectSearchBoxStatusSubscription = this.selectSearchBoxStatus$.subscribe((state: any) => {
      this.searchBoxStatus = state;
    });

    this.selectFacetedSearchResultSubscription = this.selectFacetedSearchResult$.subscribe((result: StoryFacetedSearchViewModel) => {
      if (result) {
        this.frequenttags = result.frequent_tags
      }
    });
  }

  private unsubscribe() {
    this.selectCompanySubscription.unsubscribe();
    this.selectCampaignDetailSubscription.unsubscribe();
    this.LoadSuccessSubscriptin.unsubscribe();
    this.store$.dispatch(new campaignActions.Reset())
  }

  private loadDetail() {
    this.store$.dispatch(new campaignActions.LoadDetail(this.selectedCompany.id, this.campaignSlug));
  }

  public removeScripts(){
    $("#pvt__scripts").empty();
   }


  public appendScriptinProduct(script){

    let googleAnalyticsScript = script.find(scripttype => scripttype.type == 'GoogleAnalytics');

    if(googleAnalyticsScript && $("#pvt__scripts").html() === ""){
      this.googleAnalyticsScripts(googleAnalyticsScript.measurement_id)
      measuremnetId = googleAnalyticsScript.measurement_id
      setTimeout(()=>{
        gtag('event', 'page_view', {
          page_title: pagename,
          page_path: window.location.pathname,
          page_location: window.location.href,
          send_to: measuremnetId
       })
      },1000)
    }
    // else{
    //   setTimeout(()=>{
    //     gtag('event', 'page_view', {
    //       page_title: pagename,
    //       page_path: window.location.pathname,
    //       page_location: window.location.href,
    //       send_to: measuremnetId
    //    })
    //   },1000)
    // }
  }

    getNames(number){
    var name = '';
    if(this.frequenttags){
      if(number == 2){
        name = this.frequenttags[number]?.header_title.toLowerCase() == 'roles' ? 'Teams' : this.frequenttags[number]?.header_title;
         
      }
      if(number == 8){
        name = this.frequenttags[number]?.header_title.toLowerCase() == 'impact' ? 'Skills' : this.frequenttags[number]?.header_title;
         
      }
      if(number == 13){
        name = this.frequenttags[number]?.header_title.toLowerCase() == 'feeling' ? 'Vibes' : this.frequenttags[number]?.header_title;
         
      }
      if(number == 14){
        name = this.frequenttags[number]?.header_title.toLowerCase() == 'values' ? 'Values' : this.frequenttags[number]?.header_title;
         
      }
    }

    return name;
  }


  public googleAnalyticsScripts(tag){
  /**  let elementScript = document.createElement('script');
         elementScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
         elementScript.async = true;
         document.getElementById('pvt__scripts').appendChild(elementScript);
    let elementScriptText = document.createElement('script');
    elementScriptText.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', ${tag},{send_page_view: false});`
    document.getElementById('pvt__scripts').appendChild(elementScriptText); */
    globalTag = tag;
    let elementScript = document.createElement('script');
    elementScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
    elementScript.async = true;
    document.getElementById('pvt__scripts').appendChild(elementScript);
  
    // Wait for the script to load before calling gtag()
    elementScript.onload = () => {
      // Ensure window.dataLayer is initialized
      window.dataLayer = window.dataLayer || [];
      
      // Define gtag function globally
      window['gtag'] = function gtag() {
        window.dataLayer.push(arguments);
      };
  
      // Initialize gtag
      gtag('js', new Date());
  
      // Configure Google Analytics
      gtag('config', globalTag, { send_page_view: false });
    };

  }

  private captureParams() {
    this.campaignSlug = this.activatedRoute.snapshot.params['pageSlug'];
    const companySlug = this.activatedRoute.snapshot.params['slug'];
    this.companySlug = companySlug;
    this.isIframeMode = this.activatedRoute.snapshot.queryParams.iframe == 1;

    this.store$.dispatch(new companyActions.LoadPublicInfo(companySlug));
  }

  private getDocHeight() {
    const doc = window.document;
    // from http://stackoverflow.com/questions/1145850/get-height-of-entire-document-with-javascript
    const body = doc.body, html = doc.documentElement;
    const height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);
    return height;
  }

  private sendPostMessage() {
    if (window) {
      const message = JSON.stringify({ 'docHeight': this.getDocHeight() })
      window.parent.postMessage(message, '*');
    }
  }

  private resetStates(){
    this.store$.dispatch(new storyActions.ResetState());
    this.store$.dispatch(new searchBoxActions.ResetState());
  }
  // Public functions
  filterTag(tag){

  }
 public hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.3)';
    }
  }

  public loadScripts(){
    this.store$.dispatch(new ScriptActions.Load(this.currentCompany.id));
  }

  checktagshistory(tag){

    var tagStore = [];
    if(this.searchBoxStatus && this.searchBoxStatus.searchpayload){

      if(this.searchBoxStatus.searchpayload.tags.length){
        
        tagStore = this.searchBoxStatus.searchpayload.tags;
        tagStore.push(tag);
      }
      else{
          
         tagStore = [tag]
      }
    }
    else{
      tagStore = [tag];
    }
    return tagStore;
  }

  updateTagsForSSR(){
    this.companyHttpService.loadDetailCampaign(this.companySlug, this.campaignSlug).subscribe((res: any)=>{
     if(res){
      this.ssraddMetaTags(res);
     }
 
    })
   }

   private ssraddMetaTags(res): void {
    const config: MetaTagConfig = new MetaTagConfig();
    config.title = res.title;
    config.url = 'https://'+this.companySlug+'.'+environment.customUrl+'/c/'+this.companySlug+'/campaigns/'+this.campaignSlug;
    if(!res.header_media){
      config.image ='https://'+this.companySlug+'.'+environment.customUrl+res.header_image;
    }
    else{
      config.image = res.header_image;
    }
    config.alt= res.title;
    if(res.description){
      config.description = res.description;
    }
    else{
      config.description = res.title +'Campaign';
    }
 
    
    this.seoService.updateMetaTags(config);
  }

  showorhide(data){
    if(this.frequenttags && this.frequenttags && this.frequenttags[data.toString()]){
      return this.frequenttags[data.toString()]?.is_header_part;
    }
    // return true;
  }
}
