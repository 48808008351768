export interface CreateUserInputApiModel {
    firstname: string;
    lastname: string;
    email: string;
    company_name: string;
    boost_requested: boolean;
    name_public: boolean;
    company_public: boolean;
    title_public: boolean;
    relationship_public: boolean;
    relationship?: number;
    title?: string;
    logo?:any;
    show_relationship_public?:boolean;
    show_company_public?:boolean;
    show_name_public?:boolean;
    show_title_public?:boolean;

    
}

export class CreateUserInputApiModel {
    constructor() {

    }
}
