

import { CampaignEntity } from '@modules/company/store/entities';
import { StoryViewModel } from '@modules/story/models/view-models';
import { CampaignQueryObjectsModel } from "@modules/company/models/commons";

export interface CampaignButtonViewModel {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export interface CampaignStoryAuthorViewModel {
    authorId: number;
    avatar: string;
    displayName: string;
    title: string;
    relationship: string;
    companyName: string;
    slug: string;
    companySlug: string;
    companyWebsite: string;
}

export interface CampaignStoryViewModel {
    id: number;
    title: string;
    slug: string;
    likesCount: number;
    commentsCount: number;
    author: CampaignStoryAuthorViewModel;
}

export interface CampaignViewModel {
    id: number;
    title: string;
    queryObjects: CampaignQueryObjectsModel;
    buttons: CampaignButtonViewModel[];
    slug: string;
    headerImage: string;
    avatarImage: string;
    person: string;
    description: string;
    start_date: Date;
    end_date: Date;
    companySlug: string;
    isInboundStoriesEnabled: boolean;
    isPublic: boolean;
    stories: CampaignStoryViewModel[];
    storyPrompt:any[];
    is_draft?: boolean;
    creator?: string;
    show_story_prompt?: any;
    show_submit_story_btn?: any;
    frequent_tags?: any;
    enable_overlay?: boolean;
    order_campaign?:number;
    interview_style?:boolean
    story_count?:number;
    fromDraft?:boolean;
    request_story_label?:any;
    submit_story_label?:any;
    id_campaign_consent?:any
}

export class CampaignViewModel {
    constructor(entity: CampaignEntity) {
        this.is_draft = entity.is_draft;
        this.creator = entity.creator;
        this.id = entity.id;
        this.title = entity.title;
        this.queryObjects = entity.queryObjects;
        this.buttons = entity.buttons;
        this.slug = entity.slug;
        this.headerImage = entity.headerImage;
        this.avatarImage = entity.avatarImage;
        this.person = entity.person;
        this.description = entity.description;
        this.start_date = entity.start_date;
        this.end_date = entity.end_date;
        this.companySlug = entity.companySlug;
        this.isInboundStoriesEnabled = entity.isInboundStoriesEnabled;
        this.isPublic = entity.isPublic;
        this.stories = entity.stories;
        this.storyPrompt = entity.storyPrompt;
        this.enable_overlay = entity.enable_overlay
        this.show_story_prompt = entity.show_story_prompt;
        this.show_submit_story_btn = entity.show_submit_story_btn
        this.frequent_tags = entity.frequent_tags
        this.order_campaign = entity.order_campaign
        this.interview_style = entity?.interview_style
        this.story_count = entity?.story_count;
        this.submit_story_label = entity?.submit_story_label;
        this.request_story_label = entity?.request_story_label;
        this.id_campaign_consent = entity?.id_campaign_consent
    }

    get daysLeft(): number {
        const now = new Date();
        const end = new Date(this.end_date);
        const timeDifference = end.getTime() - now.getTime();
        const millisecondsInADay = 1000 * 60 * 60 * 24;
         const daysLefts = Math.floor(timeDifference / millisecondsInADay);
        // Plus 1 when end date = to day
        const dayy = daysLefts + 1
        return dayy
    }
}
