import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, filter, take, withLatestFrom } from 'rxjs/operators';
import { routerActions, appActions } from '@app/store/core/actions';
import { SegmentAnalyticsService } from '@framework/analytics/segment-analytics/services';
import {  userProfileActions } from '@modules/user/store/actions';
import { UserProfileViewModel } from '@modules/user/store/models';
import { AuthService } from '@framework/services';
import { from } from 'rxjs';
import { userActions } from '@modules/company-admin/store/actions';
// declare var LOU;

@Injectable()
export class CommonAnalyticsEffects {
    routerChangeEffect$ = createEffect(() => this.actions$.pipe(
        ofType<routerActions.Navigated>(routerActions.ActionsType.NAVIGATED),
        filter((action) => action.pageName && action.pageName.length > 0),
        tap((action) => {
            this.switchPage(action.pageName);
        })
    ), { dispatch: false });

    getUserProfileEffect$ = createEffect(() => this.actions$.pipe(
        ofType<userProfileActions.LoadCurrentProfileSuccess>(userProfileActions.ActionTypes.LOAD_CURRENT_PROFILE_SUCCESS),
        filter(action => action.response && action.response.id > 0),
        take(1),
        tap((action) => {
            const userProfile: UserProfileViewModel = new UserProfileViewModel();
            userProfile.id = action.response.id;
            userProfile.signupDate = action.response.signed_up_at;
            userProfile.displayName = action.response.display_name;
            userProfile.firstName = action.response.first_name;
            userProfile.lastName = action.response.last_name;
            userProfile.email = action.response.email;
            userProfile.status = action.response.status
            if (action.response.company) {
                userProfile.company = {
                    id: action.response.company.company_id,
                    name: action.response.company.company_name,
                    createdAt: action.response.company.time_created,
                    email: action.response.company.company_email,
                    logo: action.response.company.avatar,
                    slug: action.response.company.slug,
                    isPublic: action.response.company.is_public,
                };
            }
            this.identityAsUser(userProfile);
        })
    ), { dispatch: false });

    getUserActionEffect$ = createEffect(() => this.actions$.pipe(
         ofType<userActions.CreateSuccess>(userActions.ActionTypes.CREATE_SUCCESS),
       tap((action: any) => {
         this.identityAsNewUser(action.response.queryData.firstname,action.response.queryData.lastname,action.response.result.user_details.id_user,action.response.queryData.email)
     })
    ), { dispatch: false })

    appInitializedEffect$ = createEffect(() => this.actions$.pipe(
        ofType<appActions.AppInitialized>(appActions.ActionTypes.APP_INITIALIZED),
        withLatestFrom(
            from(this.authService.isAuthenticated())
        ),
        tap(([action, isAuthenticated]) => {
            if (!isAuthenticated) {
                this.identityAsAnonymous();
            }
        })
    ), { dispatch: false });


    

    constructor(private actions$: Actions,
        private segmentAnalyticsService: SegmentAnalyticsService,
        private authService: AuthService
    ) {

    }

    getUserType(type: number) {
        switch (type) {
            case 1:
                return 'Invited';
            case 2:
                return 'Named';
            case 3:
                return 'Registered';
        }
    }

    private switchPage(pageName: string) {
        // this.segmentAnalyticsService.page(pageName);
    }

    private identityAsAnonymous() {
        this.segmentAnalyticsService.identify('anonymous');
    }


    private identityAsUser(userProfile: UserProfileViewModel) {
        // LOU.identify(userProfile.id.toString());

        this.segmentAnalyticsService.identify(userProfile.id.toString(), {
            name: userProfile.displayName,
            first_name: userProfile.firstName,
            last_name: userProfile.lastName,
            email: userProfile.email,
            'User Status': this.getUserType(userProfile.status),
            'Last Seen': Math.round((new Date()).getTime() / 1000),
            'User signed up': Math.round((new Date(userProfile.signupDate +'+00:00')).getTime() / 1000),
            'Companyid': userProfile.company.id,
            company: {
                id: userProfile.company.id,
                name: userProfile.company.name,
                time_created: userProfile.company.createdAt,
                email: userProfile.company.email
            },
            companyname: userProfile.company.name
        });
    }

    private identityAsNewUser(firstName:string,lastname:string,user_id:string,email:string) {
        this.segmentAnalyticsService.identify(user_id.toString(), {
            name: firstName+''+lastname,
            first_name: firstName,
            last_name: lastname,
            email: email,
            'User Status': this.getUserType(1),
        });
    }
}
