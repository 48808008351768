import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { GetAddUserInputApiModel, GetStoryInputApiModel, GetUserInputApiModel } from '../models/api-models/input';

@Injectable()
export class PolicyHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public addPolicy(companyId: number, payload) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.post(`/${companyId}/policy/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public getPolicy(companyId: number) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.get(`/${companyId}/policy/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getConsent(companyId: number) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
            if(companyId){
                return this.get(`/${companyId}/consent/`).pipe(
                    map((response: any) => {
                        return response;
                    })
                );
            }

        }

        public getMultipleConsent(companyId: number) {
            // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
                if(companyId){
                    return this.get(`/${companyId}/consents/`).pipe(
                        map((response: any) => {
                            return response;
                        })
                    );
                }
    
        }

            public addMultipleConsent(companyId: number,payload) {
            // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
                if(companyId){
                    return this.post(`/${companyId}/consents/`,payload).pipe(
                        map((response: any) => {
                            return response;
                        })
                    );
                }
    
        }


        public updateMultipleConsent(companyId: number,consent_id:any,payload) {
            // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
                if(companyId){
                    return this.put(`/${companyId}/consents/${consent_id}/`,payload).pipe(
                        map((response: any) => {
                            return response;
                        })
                    );
                }
    
        }


        public deleteMultipleConsent(companyId: number,consent_id:any) {
            // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
                if(companyId){
                    return this.delete(`/${companyId}/consents/${consent_id}/`).pipe(
                        map((response: any) => {
                            return response;
                        })
                    );
                }
    
        }


        public getaMultipleConsent(companyId: number,consent_id) {
            // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
                if(companyId){
                    return this.get(`/${companyId}/consents/${consent_id}/`).pipe(
                        map((response: any) => {
                            return response;
                        })
                    );
                }
    
        }




    public updateConsentToStory(companyId: number,payload,story_id) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
            if(companyId){
                return this.patch(`/${companyId}/consent/story/${story_id}/`,payload).pipe(
                    map((response: any) => {
                        return response;
                    })
                );
            }

    }

    public updatePolicy(companyId: number, payload) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.patch(`/${companyId}/policy/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updateConcent(companyId: number, payload) {
        if(companyId){
            return this.patch(`/${companyId}/consent/`, payload).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(

    }

    public update(companyId: number, payload) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.patch(`/${companyId}/policy/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deletePolicy(companyId: number, policy_id) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.delete(`/${companyId}/policy?${policy_id}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public checkPolicySlug(companyId, slug){
        return this.get(`/${companyId}/policy?slug=${slug}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}