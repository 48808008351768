import { Action } from '@ngrx/store';

import { SearchStoryInputApiModel } from '@modules/story/models/api-models/input';

export enum ActionTypes {
    SHOW = "[Search Box] Show",
    HIDE = "[Search Box] Hide",
    SEARCH = "[Search Box] Search",
    SEARCHPAYLOAD = "[Serach Box] SearchPayload",
    CLEAR = "[Search Box] Clear",
    SAVE_SEARCH_RESULT = "[Search Box] Save Search Result",
    TAB_SWAITCH = "[Search Box] Tab Switch",
    RESET_PAGE = "[Search Box] Reset  Page",
    ResetState = "[Search Box] Reset State"
}

export class Show implements Action {
    readonly type = ActionTypes.SHOW;
}

export class Hide implements Action {
    readonly type = ActionTypes.HIDE;
}

export class Search implements Action {
    readonly type = ActionTypes.SEARCH;

    constructor(public searchPayload: Partial<SearchStoryInputApiModel>,public tagPayload?:any) { }
}

export class SearchPayload implements Action {

    readonly type = ActionTypes.SEARCHPAYLOAD;

    constructor(public tagPayload?:any) { }
}
export class ResetPage implements Action {

    readonly type = ActionTypes.RESET_PAGE;
}

export class Clear implements Action {
    readonly type = ActionTypes.CLEAR;
}

export class SaveSearchResult implements Action {
    readonly type = ActionTypes.SAVE_SEARCH_RESULT;
}

export class ResetState implements Action {
    readonly type = ActionTypes.ResetState;
}

export class TabSwitch implements Action {
    readonly type = ActionTypes.TAB_SWAITCH;
    constructor(public tabname?:any) { }
}

export type Actions =
    Show | Hide | Search | Clear | SaveSearchResult | ResetState | SearchPayload | TabSwitch;
