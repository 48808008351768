<!-- <div class="question-prompt" *ngIf="!hideQuestion">
<h3>{{placeholder}}</h3>
</div> -->


<quill-editor #quillEditor [placeholder]="placeholderText" (keydown)="onkeyDown($event)" (onEditorCreated)="editorCreated($event)"(onFocus)="onFocus($event)" (onBlur)="onBlur()"
    [maxLength]="maxLength" [(ngModel)]="quillData" (ngModelChange)="onChange($event)" 
    (onContentChanged)="contentChanged($event)" [styles]="{fontSize: fontSize}" [autofocus]="true" [readOnly]="isEditable" [modules]="quillModule" theme="bubble">
</quill-editor>
<div id="custom-tag-add">
    <button type="button" class="close"  (click)="addInstanttag()">
        <span class="btn-preview-circle close_mention_hastag_btn" aria-hidden="true">
            <svg role="img" class="mr-2">
                <use xlink:href="#icon-plus-circle"></use>
            </svg>
        </span>
    </button>
</div>
<div id="custom-form" class="mention-player-container" [ngClass]="{'adjust_hastag_container':showTagsForm}">
    <button type="button" class="close" id="custom-form-close" (click)="closeMentionPlayerForm()">
        <span class="btn-preview-circle close_mention_hastag_btn" aria-hidden="true">
            <svg role="img">
                <use xlink:href="#icon-close"></use>
            </svg></span>
    </button>
    <div class="mention-player-form" *ngIf="showMentionPlayerForm">
        <div class="form-row" [formGroup]="mentionPlayerForm">
            <ng-container *ngIf="showPlayerForm" >
                <div class="form-group col-12 mb-1">
                    <gs-input-material hideErrorMessage="true" autoFocus="true" label="Name" name="First Name"
                        placeholder="Name" formControlName="firstName" [formControl]="mentionPlayerForm.controls.firstName"
                        type="text">
                    </gs-input-material>
                </div>
                <div class="form-group col-12 mb-1">
                    <gs-input-material [hideErrorMessage]="true" type="email" label="Email" name="Email" placeholder="Email"
                        formControlName="email" [formControl]="mentionPlayerForm.controls.email" type="text">
                    </gs-input-material>
                </div>
            </ng-container>
            <ng-container *ngIf="showTagsForm">
                <div class="form-group col-12 mb-1">
                    <gs-select-material label="Select a Category" name="Tag" formControlName="tagId" (change)="onTagChange($event)"
                    [formControl]="mentionPlayerForm.controls.tagId" [data]="storeActivatedtags" valueField="id" displayField="title">
                  </gs-select-material>
                </div>
            </ng-container>


        </div>
        <ng-container *ngIf="showPlayerForm">
            <button class="mention-add-btn btn btn-mint" [disabled]="mentionPlayerForm.controls.firstName.invalid || mentionPlayerForm.controls.email.invalid" (click)="addNewUser()">+
                Add</button>
        </ng-container>
        
        <!-- <ng-container *ngIf="showTagsForm">
            <button class="mention-add-btn btn btn-mint" [disabled]="mentionPlayerForm.controls.tagName.invalid || mentionPlayerForm.controls.tagId.invalid || disabled" (click)="addNewtags()">+
                Add</button>
        </ng-container> -->
            
        <button class="mention-add-btn btn mint btn-outline btn-mint"
            (click)="closeMentionPlayerForm()">Dismiss</button>
    </div>
    <div class="mention-player-message-container" *ngIf="showPlayerForm && !showMentionPlayerForm && !showTagsForm">
        <div class="mention-player-message">People not found!</div>
        <button class="mention-add-btn mt-3 btn mint btn-mint" (click)="showMentionPlayerFormEvent()">+ Add</button>
        <button class="mention-add-btn mt-3 btn mint btn-outline btn-mint"
            (click)="closeMentionPlayerForm()">Dismiss</button>
    </div>

    <div class="mention-player-message-container hasTag__Container"  *ngIf="false">
            <div class="mentiontagsheading">Add {{showtex}} to:</div>
            <div id="hastagsbox" class="mb-0 ml-3">
                <div #box tabindex={{i}} id="tagItem{{i}}" [ngClass]="{'cureentselctedcell': keyPress == i}" class="mb-3 cell"  *ngFor="let item of storeActivatedtags ;let i = index;">
                    <!-- <input  class="custom-control-input" (change)="onTagChange($event)"  type="checkbox" [value]="item.id" [checked]="item?.checked" [(ngModel)]="item.checked"/>  -->
                    <div style="cursor: pointer;" (click)="onTagChange(item)" class=""   [ngStyle]="{'color': item.color}" >
                        <svg role="img" style="height: 1rem; width:1rem" [ngStyle]="{'color': item.color}">
                            <use attr.xlink:href="{{item.icon}}"></use>
                          </svg> &nbsp;&nbsp; {{item.title}}</div>
                </div>
            </div>
       
        
    </div>

    <div class="mention-player-message-container hasTag__Container"  *ngIf="!showMentionPlayerForm && showTagsForm">
        <div class="mentiontagsheading">Add {{showtex}} to:</div>
        <div id="hastagsbox" class="mb-0 ml-3" style="position: relative;
        left: -16px;">
            <div #box tabindex={{i}} id="tagItem{{i}}"  class="mb-3 cell" [ngStyle]="{'border-color': item.color}"  *ngFor="let item of storeActivatedtags ;let i = index;">
                <!-- <input  class="custom-control-input" (change)="onTagChange($event)"  type="checkbox" [value]="item.id" [checked]="item?.checked" [(ngModel)]="item.checked"/>  -->
                <div style="cursor: pointer;" (click)="onTagChange(item)" class=""   [ngStyle]="{'color': item.color}" >
                    <svg role="img" style="height: 1rem; width:1rem" [ngStyle]="{'color': item.color}">
                        <use attr.xlink:href="{{item.icon}}"></use>
                      </svg>&nbsp;{{item.title}}</div>
            </div>
        </div>
   
    
</div>
</div>