<div *ngIf="!insideIframe" [ngClass]="{'forEmbed_mode':isEmbed}">
  <!-- START HEADER-->

  <div class="site-header">
    <nav class="navbar navbar-expand-lg bg-white fixed-top c2">
      <!-- <a [routerLink]="['/c/' + selectedCompany.slug]" class="navbar-brand">
      <img [src]="selectedCompany.logo" [alt]="selectedCompany.name" *ngIf="selectedCompany.logo" />
      <img src="/assets/front-end-minify/img/logo-short.png" [alt]="selectedCompany.name"
        *ngIf="!selectedCompany.logo" />
    </a> -->
      <ng-container *ngIf="selectedCompany.enable_public">
        <a           aria-label="Company Home"
        tabindex="0" [routerLink]="['/c/' + selectedCompany.slug]" class="navbar-brand">
          <img [src]="removeUrl(selectedCompany.logo)" [alt]="selectedCompany.name + ' Logo'" *ngIf="selectedCompany.logo" />
          <img             [alt]="selectedCompany.name + ' Logo'" src="/assets/front-end-minify/img/logo-short.png" [alt]="selectedCompany.name"
            *ngIf="!selectedCompany.logo" />
        </a>
      </ng-container>
      <ng-container *ngIf="!selectedCompany.enable_public">
        <a       rel="noopener"
        class="navbar-brand"
        aria-label="Company Website"
        tabindex="0" [href]="selectedCompany?.website" target="_blank" class="navbar-brand">
          <img [src]="removeUrl(selectedCompany.logo)" [alt]="selectedCompany.name" *ngIf="selectedCompany.logo" />
          <img             [alt]="selectedCompany.name + ' Logo'"
          *ngIf="!selectedCompany.logo" src="/assets/front-end-minify/img/logo-short.png" [alt]="selectedCompany.name"
            />
        </a>
      </ng-container>

      <ul class="navbar-nav" role="menu">
        <li role="menuitem">
          <!-- <a [routerLink]="['/c/' + selectedCompany.slug]"
          class="pb-company-title mr-1 mr-lg-2">{{selectedCompany.name}}</a>
        <a *ngIf="(isLoggedIn | async) && (currentUrl === '/c/' + selectedCompany.slug)" [routerLink]="['/company' ]"
          class="btn btn-sm btn-blue-light-extra text-white text-smaller public" title="Exit Public Page">exit<span
            class="d-none d-sm-inline"> public</span></a> -->

          <ng-container *ngIf="selectedCompany.enable_public">
            <a   tabindex="0" [routerLink]="['/c/' + selectedCompany.slug]"
              class="pb-company-title mr-1 mr-lg-1">{{selectedCompany.name}}</a>
            <a *ngIf="(isLoggedIn | async) && (currentUrl === '/c/' + selectedCompany.slug)"
              [routerLink]="['/company' ]" class="btn btn-sm btn-blue-light-extra text-white text-smaller public"
              title="Exit Public Page" tabindex="0" style="display: inline-block;">Exit<span class="d-none d-sm-inline">
                Public</span></a>
          </ng-container>

          <ng-container *ngIf="!selectedCompany.enable_public">
            <a href="javascript:void(0)" style="cursor: default;"
              class="pb-company-title mr-1 mr-lg-2">{{selectedCompany.name}}</a>
            <a *ngIf="(isLoggedIn | async) && (currentUrl === '/c/' + selectedCompany.slug)"
              [routerLink]="['/company' ]" class="btn btn-sm btn-blue-light-extra text-white text-smaller public"
              title="Exit Public Page" style="display: inline-block;">Exit<span class="d-none d-sm-inline">
                Public</span></a>
          </ng-container>


        </li>

        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl === '/c/' + selectedCompany.slug + '/campaigns' || currentUrl.indexOf('/c/' + selectedCompany.slug + '/campaigns') > -1">
          <span class="gt">-</span>
        </li>
        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl === '/c/' + selectedCompany.slug + '/campaigns' || currentUrl.indexOf('/c/' + selectedCompany.slug + '/campaigns') > -1">
          <a tabindex="0" [ngClass]="{'active': currentUrl === '/c/' + selectedCompany.slug + '/campaigns'}"
            [routerLink]="['/c/' + selectedCompany.slug + '/campaigns']">Campaigns</a>
          <a *ngIf="(isLoggedIn | async) && (currentUrl === '/c/' + selectedCompany.slug + '/campaigns')"
            [routerLink]="['/company/campaigns']"
            class="btn btn-sm btn-blue-light-extra text-white text-smaller public ml-2 mr-1" title="Exit Public Page"
            style="display: inline-block;">Exit
            Public</a>
        </li>

        <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/c/' + selectedCompany.slug + '/campaigns/') > -1">
          <span class="gt">-</span>
        </li>
        <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/c/' + selectedCompany.slug + '/campaigns/') > -1">
          <a tabindex="0" [ngClass]="{'pb-page-title': true,'active': currentUrl.indexOf('/c/' + selectedCompany.slug + '/campaigns/') > -1}"
            [routerLink]="['/c/' + selectedCompany.slug + '/campaigns/' + campaign?.slug]">{{campaign?.title}}</a>
          <a *ngIf="(isLoggedIn | async)" [routerLink]="['/company/campaigns/' + campaign?.slug]"
            class="btn btn-sm btn-blue-light-extra text-white text-smaller public ml-2 mr-1" title="Exit Public Page"
            style="display: inline-block;">Exit
            Public</a>
        </li>

        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl === '/c/' + selectedCompany.slug + '/featured' || currentUrl.indexOf('/c/' + selectedCompany.slug + '/featured') > -1 || currentUrl.indexOf('/c/' + selectedCompany.slug + '/myspotlight') > -1">
          <span class="gt">-</span>
        </li>
        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl === '/c/' + selectedCompany.slug + '/featured' || currentUrl.indexOf('/c/' + selectedCompany.slug + '/featured') > -1">
          <a tabindex="0" [ngClass]="{'active': currentUrl === '/c/' + selectedCompany.slug + '/featured'}"
            [routerLink]="['/c/' + selectedCompany.slug + '/featured']">Spotlights</a>
          <a tabindex="0" *ngIf="(isLoggedIn | async) && (currentUrl === '/c/' + selectedCompany.slug + '/featured')"
            [routerLink]="['/company/featured' ]"
            class="btn btn-sm btn-blue-light-extra text-white text-smaller public ml-2 mr-1" title="Exit Public Page"
            style="display: inline-block;">Exit
            Public</a>
        </li>

        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl === '/c/' + selectedCompany.slug + '/myspotlight' || currentUrl.indexOf('/c/' + selectedCompany.slug + '/myspotlight') > -1">
          <a tabindex="0" [ngClass]="{'active': currentUrl === '/c/' + selectedCompany.slug + '/myspotlight'}">My Spotlights</a>
        </li>

        <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/c/' + selectedCompany.slug + '/featured/') > -1">
          <span class="gt">-</span>
        </li>
        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl.indexOf('/c/' + selectedCompany.slug + '/myspotlight/') > -1">
          <span class="gt">-</span>
        </li>
        <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/c/' + selectedCompany.slug + '/featured/') > -1">
          <a tabindex="0"  [ngClass]="{'pb-page-title': true,'active': currentUrl.indexOf('/c/' + selectedCompany.slug + '/featured/') > -1}"
            [routerLink]="['/c/' + selectedCompany.slug + '/featured/' + featuredPage?.slug]">{{featuredPage?.title}}</a>
          <a *ngIf="(isLoggedIn | async)" tabindex="0" [routerLink]="['/company/featured/' + featuredPage?.slug ]"
            class="btn btn-sm btn-blue-light-extra text-white text-smaller public ml-2 mr-1" title="Exit Public Page"
            style="display: inline-block;">Exit
            Public</a>
        </li>

        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl.indexOf('/c/' + selectedCompany.slug + '/myspotlight/') > -1">
          <a tabindex="0" [ngClass]="{'pb-page-title': true,'active': currentUrl.indexOf('/c/' + selectedCompany.slug + '/myspotlight/') > -1}"
            [routerLink]="['/c/' + selectedCompany.slug + '/myspotlight/' + featuredPage?.slug]">{{featuredPage?.title}}</a>
          <a tabindex="0" *ngIf="(isLoggedIn | async)" [routerLink]="['/company/myspotlight/' + featuredPage?.slug ]"
            class="btn btn-sm btn-blue-light-extra text-white text-smaller public ml-2 mr-1" title="Exit Public Page"
            style="display: inline-block;">Exit
            Public</a>
        </li>

        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl.indexOf('/c/' + selectedCompany.slug) > -1 && !(currentUrl.indexOf('/c/' + selectedCompany.slug + '/campaigns') > -1) && !(currentUrl.indexOf('/c/' + selectedCompany.slug + '/featured') > -1) && !(currentUrl === '/c/' + selectedCompany.slug) && !(currentUrl.indexOf('/c/' + selectedCompany.slug + '/myspotlight') > -1)">
          <span class="gt">-</span>
        </li>
        <li class="d-none d-lg-inline sm"
          *ngIf="currentUrl.indexOf('/c/' + selectedCompany.slug) > -1 && !(currentUrl.indexOf('/c/' + selectedCompany.slug + '/campaigns') > -1) && !(currentUrl.indexOf('/c/' + selectedCompany.slug + '/featured') > -1)">
          <a tabindex="0" [ngClass]="{'pb-story-title': true,'active': currentUrl === '/c/' + selectedCompany.slug + '/' + storyDetail?.slug}"
            [routerLink]="['/c/' + selectedCompany.slug + '/' + storyDetail?.slug]">{{storyDetail?.title}}</a>
          <a tabindex="0" *ngIf="(isLoggedIn | async) && storyDetail?.public" [routerLink]="['/company/' + storyDetail?.slug]"
            [queryParams]="queryParamsDeatil()"
            class="btn btn-sm btn-blue-light-extra text-white text-smaller public ml-2 mr-1" title="Exit Public Page"
            style="display: inline-block;">Exit
            Public</a>
        </li>
        <li class="d-none d-lg-inline sm">
          <a tabindex="0" *ngIf="(isLoggedIn | async)"
            class="btn btn-sm btn-blue-light-extra text-white text-smaller public mr-3 ml-3"
            (click)="copyExternalLink()" title="Share link"
            style="display: inline-block;margin-top: 1rem;zoom: 1.02;">Copy &nbsp;<i class="fa-regular fa-link"></i>
          </a>
        </li>
      </ul>
      <div class="mr-3 d-lg-none">
        <button *ngIf="login"   aria-label="Open Login Menu"
        tabindex="0"[routerLink]="['/user/login']" class="btn btn-trans text-gray-dark-extra">
          <svg role="img">
            <use xlink:href="#icon-menu"></use>
          </svg>
        </button>
      </div>
      <div id="navbarSupportedContent" class="collapse navbar-collapse reversed">
        <div class="ml-auto">
          <a *ngIf="login" tabindex="0" [routerLink]="['/user/login']"
            [queryParams]="{u: currentUserProfile?.email, link: redirectParam}"
            class="btn btn-sq text-small btn-hdr">Login</a>
          <a *ngIf="register" tabindex="0" [routerLink]="['/user/signup']" [queryParams]="{u: currentUserProfile?.email}"
            class="btn btn-sq btn-hdr text-small btn-mint">Register</a>
        </div>
      </div>
    </nav>
  </div>

  <!-- CLOSE HEADER-->
</div>