<div *ngIf="landingPage" [ngClass]="{'add-overlay':landingPage.enable_overlay}" [background-image]="landingPage.headerImage" class="b-timeline" role="banner">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5">
        <div class="m-ld-info">
          <div *ngIf="landingPage.avatarImage" class="m-ld-info__logo">
            <img class="avatar avatar--xl" [src]="landingPage.avatarImage" [alt]="landingPage.title + ' logo'" />
          </div>
          <div class="m-ld-info__content hero">
            <h1>{{landingPage.title}}</h1>
            <h4 *ngIf="landingPage.slogan">{{landingPage.slogan}}</h4>
            <p *ngIf="landingPage.person" class="mb-0 mt-2">{{landingPage.person}}</p>
          </div>
        </div>
      </div>
      <div class="d-block d-md-inline-flex btn-group mr-5" *ngIf="!insideIframe" role="group" aria-label="Action buttons">
        <a *ngFor="let button of getButtons()" 
           [style.background-color]="button.background" 
           [style.color]="button.foreground" 
           class="btn btn-sq btn-white" 
           href="{{button.url}}" 
           target="_blank" 
           rel="noopener noreferrer" 
           tabindex="0"
           [attr.aria-label]="button.label"
           role="button">
          {{button.label}}
        </a>
        <a *ngIf="landingPage && landingPage?.isInboundStoriesEnabled && showSubmitsttorybutton" 
           [routerLink]="['/t/' + landingPage.companySlug + '/testimonial/create']" 
           class="btn btn-sq btn-pink" 
           tabindex="0"
           aria-label="Submit Story"
           role="button">Submit Story</a>
      </div>
    </div>
  </div>
</div>