<div class="site-container">
    <!-- START HEADER-->
    <app-main-header></app-main-header>
    <div class="personal-settings__header">
        <div class="navbar-settings">
            <ul role="tablist" class="nav">
                <li class="nav-item">
                    <a data-toggle="tab" [ngClass]="{'active': tabName == 'MyApprovals'}" (click)="switchtab('MyApprovals')" class="nav-link">My Approvals</a>
                </li>

            </ul> 
        </div>
    </div>
    <div class="approval_filter">
        <ng-container *ngIf="tabName == 'MyApprovals'">
            <app-grid-view-filter [options]="gridViewFilterOptions" (filter)="filter($event)"></app-grid-view-filter>
        </ng-container>
        <ng-container  *ngIf="tabName != 'MyApprovals'">
            <app-grid-view-filter [options]="gridViewFilterOptionsAllApprovals" (filter)="allApprovalfilter($event)"></app-grid-view-filter>
        </ng-container>

    </div>

    <!--<ng-container *ngIf="tabName == 'MyApprovals'">
        <div class="text-center loader" *ngIf="loader">
            <p>Please wait</p> <br/>
            <i
            class="fas fa-spinner fa-pulse"></i></div>
        <div class="col-lg-8 offset-lg-2 mt-4" *ngIf="!loader">-->

        <ng-container *ngIf="tabName == 'MyApprovals'">
            <div class="text-center loader" *ngIf="loader">
                <div class="skeleton-row">
                    <div class="skeleton-cell"></div>
                    <div class="skeleton-cell"></div>
                    <div class="skeleton-cell"></div>
                    <div class="skeleton-cell"></div>
                </div>
                <div class="skeleton-row">
                    <div class="skeleton-cell"></div>
                    <div class="skeleton-cell"></div>
                    <div class="skeleton-cell"></div>
                    <div class="skeleton-cell"></div>
                </div>
            </div>
            <div class="col-lg-8 offset-lg-2 mt-4" *ngIf="!loader">

    
    <table>
        <thead>
            <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Author</th>
                <th>Published</th>
                <th>Prior Approver</th>
                <th>My Task</th>
                <th>My Approval</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of items; let i = index;">
                <td>{{ item.response_id }}</td>
                <td style="cursor: pointer;" [routerLink]="[ '/company/'+item?.story_id+'/'+item.response_id+'/approval' ]" class="text-blue-light">{{ item?.story_title }}</td>
                <td >{{ item?.author }}</td>
                <td >{{ item?.story_published_date | date}}</td>
                <td >{{ item?.prior_approver }}</td>
                <td >{{ item?.step_name }}</td>
                <td>{{ item?.is_approved === null ? 'Pending' : ( item?.is_approved ? 'Approved' : 'Rejected')  }}</td>
                <td class="action_conatiner" *ngIf="item?.is_approved == null">
                    <div  class="approvalrequest approvalrequest_btn"  [ngClass]="{'to_be_approved': item.is_approved == null}">
                        <span class="action-icon approved" (click)="approve(item,i)">        <a  
                            type="button" class="">
                              Approve  <!--<i class="fa-solid fa-circle-check" style="color: #fff;"></i>-->
                            </a></span>
                    </div>
                    <div class="approvalrequest reject_btn"  [ngClass]="{'to_be_rejected': item.is_approved == null}">
                        <span class="action-icon rejected" (click)="reject(item,i)">        <a  
                            type="button" class="">
                              Reject  <!--<i class="fa-duotone fa-solid fa-circle-xmark" style="color: #fff;"></i>-->
                            </a></span>
                    </div>

                </td>
                <td class="action_conatiner" *ngIf="item?.is_approved != null">
                    <div *ngIf="item?.is_approved" class="approvalrequest approvalrequest_btn">
                        <span class="action-icon approved">        <a  
                            type="button" class="">
                              Approved  <!--<i class="fa-solid fa-circle-check" style="color: #fff;"></i>-->
                            </a></span>
                    </div>
                    <div class="approvalrequest reject_btn" *ngIf="!item?.is_approved">
                        <span class="action-icon rejected">        <a  
                            type="button" class="">
                              Rejected  <i class="fa-duotone fa-solid fa-circle-xmark" style="color: #fff;"></i>
                            </a></span>
                    </div>

                </td>
            </tr>
        </tbody>
    </table>
    
    
            
        </div>
    </ng-container>
   

</div>











