import { Component, SimpleChanges, Inject, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

import { ComponentModalBase } from '@framework/base/component';
import { SocialSharingService } from '@framework/services';
import { Observable, Subscription } from "rxjs";
import { userProfileSelectors } from "@modules/user/store/selectors";
import { Store } from "@ngrx/store";
import { CoreState } from "@app/store/core/states";
import { UserProfileViewModel } from "@modules/user/store/models";
import { environment } from '@app/env/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'co-share-modal-container',
  templateUrl: './share-modal-container.component.html',
  styleUrls: ['./share-modal-container.component.scss']
})
export class ShareModalContainerComponent extends ComponentModalBase<string> {
  @ViewChild("shareholic") shareholic: ElementRef;

  // Private variables
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectCurrentUserProfileSubscription: Subscription;

  // Public varibales
  public currentUserProfile: UserProfileViewModel;

  // Private variables
  private shareUrl: string;
  // Public variables
  public owlOptions: any = {
    dots: false,
    nav: true,
    margin: 16,
    autoWidth: true,
  };
  public shareForm: UntypedFormGroup;

  constructor(
    private store$: Store<CoreState>,
    private socialSharingService: SocialSharingService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    super();
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.initForm();
  }

  // Life cycle hook
  protected onInit(): void {
    let feed_rss = localStorage.getItem("feed_rss");
    this.shareForm.controls.publicValue.setValue(feed_rss ? feed_rss : "");
    if (!_.isEmpty(this.initialState) && !Object.keys(this.initialState).includes('tracking') && !Object.keys(this.initialState).includes('campaigntracking')) {
      this.shareUrl = this.initialState;
    } else if(Object.keys(this.initialState).includes('tracking')){
      this.shareUrl = window.location.origin + this.router.url + "&tr="+this.initialState['tracking'] ;
    }
    else if(Object.keys(this.initialState).includes('campaigntracking')){
      this.shareUrl = window.location.origin + this.router.url + "?tr="+this.initialState['campaigntracking'] ;
    }
    else{
      this.shareUrl = window.location.origin + this.router.url
    }
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    this.subscribe();
  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.pipe(
      take(1)
    ).subscribe(userProfile => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        // this.shareUrl += '?sb=' + this.currentUserProfile.id;
      }
      this.shareholic.nativeElement.setAttribute("data-link", this.shareUrl);
      if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
      this.shareForm.controls.link.setValue(this.shareUrl);
    });
  }

  private unsubscribe() {
    this.selectCurrentUserProfileSubscription.unsubscribe();
  }

  private initForm() {
    this.shareForm = this.formBuilder.group({
      link: [this.shareUrl],
      publicValue:[{value:'', disabled:true}]
    });
  }

  // Public functions
  public close() {
    this.onClose.emit();
  }
}
