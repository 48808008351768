import { Component, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';

import { ComponentBase } from '@framework/base/component';
import {
  GridViewOptionsModel,
  GridViewColumnModel,
  GridViewActionModel,
  GridViewActionResultModel,
  GridViewPageSizeOptionModel,
  GridViewPaginationModel,
  GridViewColumnLinkDataModel
} from './models';
import { Constants } from '../../../constants';
import { GridViewFilterOptionsModel } from '../grid-view-filter/models/grid-view-filter-options.model';
import { LandingPageViewModel } from '@modules/company/models/store-view-models';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss']
})
export class GridViewComponent extends ComponentBase {
  // Inputs
  @Input() options: GridViewOptionsModel<any> = new GridViewOptionsModel<any>();
  @Input() isVisible: boolean = true;
  @Input() isDragandDrop: boolean = false;
  // Outputs
  @Output() action: EventEmitter<GridViewActionResultModel<any>> = new EventEmitter<GridViewActionResultModel<any>>();
  @Output() columnDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input()  companyslug:any;
  @Output() OpenaModal
  @Output() select: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() paging: EventEmitter<GridViewPaginationModel> = new EventEmitter<GridViewPaginationModel>();
  @Input() rolesDataList:GridViewFilterOptionsModel;
  @Output() OnDragandDrop: EventEmitter<any> = new EventEmitter<any>();
  // Private variables
  @Output() sort: EventEmitter<any> = new EventEmitter<any>()

  public sortString = 'asc';
  public currentSortType = ''
  private dynamicOperators = {
    '<': (a: any, b: any) => {
      return a < b;
    },
    '>': (a: any, b: any) => {
      return a > b;
    },
    '<=': (a: any, b: any) => {
      return a <= b;
    },
    '>=': (a: any, b: any) => {
      return a >= b;
    },
    '==': (a: any, b: any) => {
      return a == b;
    },
    '===': (a: any, b: any) => {
      return a === b;
    }
  };
  // Public variables
  public pageSizeOptions: GridViewPageSizeOptionModel[] = [
    {
      value: 25,
      label: 'Show 25 per page'
    },
    {
      value: 50,
      label: 'Show 50 per page'
    },
    {
      value: 100,
      label: 'Show 100 per page'
    },
    {
      value: 250,
      label: 'Show 250 per page'
    }
  ];
 
  public pagination: GridViewPaginationModel = new GridViewPaginationModel();
  public isSelectAll: boolean = false;
  public canPrev: boolean = false;
  public canNext: boolean = true;
  public totalPage: number = 0;
  public showSearchbar: boolean = false;
  constructor() {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.pagination.pageSize = 25;
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {
    this.pagination.totalCount = this.options.totalCount;
    this.canNext = this.pagination.pageIndex < this.pagination.totalPageCount;
    this.totalPage = this.pagination.totalPageCount;
    setTimeout(() => {
      this.selectChange();
    }, 200);
  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions
  private subscribe() {

  }

  private unsubscribe() {

  }
  // Public functions
  public columnTrackByFunc(index: number, item: GridViewColumnModel) {
    return item.name;
  }

  public dataTrackByFunc(index: number, item: any) {
    return item[this.options.identityColumn];
  }

  public actionOnClick(item: any, action: GridViewActionModel) {
    const outputData: GridViewActionResultModel<any> = {
      action: action,
      dataItem: item
    };
    this.action.emit(outputData);
  }

  public fieldChange(item: any, name: string = null) {
    if (item) { item.fieldsToUpdate = [name]; }
    this.columnDataChange.emit(item);
  }

  public selectChange() {
    const selectItems = this.options.data.filter(item => item.checked);
    this.select.emit(selectItems);
  }

  public selectAllChange() {
    this.options.data.forEach(item => {
      item.checked = this.isSelectAll;
    });

    this.selectChange();
  }

  public paginationChange() {
    this.pagination.pageIndex = Number(this.pagination.pageIndex);
    this.pagination.pageSize = Number(this.pagination.pageSize);
    if (this.pagination.pageIndex > this.pagination.totalPageCount) {
      this.pagination.pageIndex = this.pagination.totalPageCount;
    }
    if (this.pagination.pageIndex < 1) {
      this.pagination.pageIndex = 1;
    }

    this.canPrev = this.pagination.pageIndex > 1;
    this.canNext = this.pagination.pageIndex < this.pagination.totalPageCount;

    this.paging.emit(this.pagination);
  }

  public prevPage() {
    if (!this.canPrev) return;
    this.pagination.pageIndex -= 1;
    this.paginationChange();
  }

  public nextPage() {
    if (!this.canNext) return;
    this.pagination.pageIndex += 1;
    this.paginationChange();
  }

  public resetPageIndex() {
    this.pagination.pageIndex = 1;
  }
  public checkConditionsforlinks(item: any, action: GridViewActionModel){
    if ((action.label == 'Get Unique Link')) {
      if ((item && item?.public)) {
        return false;
      }

      else {
        return true
      }
    }
    else if ((action.eventName == 'DELETE PROMPT')) {
      if ((item && (item?.story_count > 0))) {
        return false;
      }

      else {
        return true
      }
    }
    else if (action.label == 'Get Video link') {
      if (item.is_video && action.label == 'Get Video link' && ((item && !item?.public))) {
        return true;
      }
      else {
        return false;
      }
    }

    else if (action.label == 'Advocacy Page Link') {
      if (item.public && action.label == 'Advocacy Page Link') {
        return true;
      }
      else {
        return false;
      }
    }

    else if (action.label == 'Advocacy Page Link') {
      if (item.public && action.label == 'Advocacy Page Link') {
        return true;
      }
      else {
        return false;
      }
    }
    else if (action.label == 'Get Consent Link') {
      if (item.id_campaign_consent && action.label == 'Get Consent Link') {
        return true;
      }
      else {
        return false;
      }
    }
    else if (action.label == 'Reject Story') {
      if (item.final_approver != null  && action.label == 'Reject Story') {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return true;
    }

  }
  public checkConditionsToHideAction(item: any, action: GridViewActionModel) {
    if (!action.conditionsToHide) { return true; }
    let conditions = '';
    for (let i = 0; i < action.conditionsToHide.length; i++) {
      if (i > 0) { conditions += ' && '; }
      conditions += this.dynamicOperators[action.conditionsToHide[i].operator](item[action.conditionsToHide[i].columnName], action.conditionsToHide[i].value);
    }
    return eval(conditions);
  }

  public assembleLink(item: GridViewColumnModel, linkData: GridViewColumnLinkDataModel) {
    let url = linkData.url;
    for (let i = 0; i < linkData.keys.length; i++) {
      url = url.replace(linkData.keys[i], item[linkData.values[i]]);
    }
    return url;
  }

  getUrl(item: GridViewColumnModel, linkData: GridViewColumnLinkDataModel){
    let url;
    for (let i = 0; i < linkData.keys.length; i++) {
      url =  item[linkData.values[i]];
    }
    return url;
  }

  getSpotlightUrl(item, linkData: GridViewColumnLinkDataModel) {

    let url = ''
    if(item.board_visible){
      url+='/company/featured/'+item.slug
    }
    else{
      url+='/company/myspotlight/'+item.slug
    }

    return url

  }

  getDraftlink(item, linkData:GridViewColumnLinkDataModel){
    let url = '';

    if(item.interview_style){
      if(item.requested_by == null){
  
        if(!item.publishedAt){
          url+='interview/campaign/edit/' +item.id+ '/'+this.companyslug
    
        }else{
        url+=  'company/' + item.slug
        }
    
      }
      else{
         if(!item.publishedAt && !item.published_to_draft){
           url+= 'interview/campaign/edit/' +item.id+ '/'+this.companyslug+'?requestedby=1'
         }
         else if (!item.publishedAt && item.published_to_draft){
          url+=  url+='interview/campaign/edit/' +item.id+ '/'+this.companyslug;
         }
         else{
          url+=  'company/' + item.slug
          }
    
      }
    }
    else{
      if(item.requested_by == null){
  
        if(!item.publishedAt){
          url+='story/' +item?.id + '/preview';
    
        }else{
        url+=  'company/' + item.slug
        }
    
      }
      else{
         if(!item.publishedAt && !item.published_to_draft){
           url+=  'story/'+item.id+'/0/edit?requestedby=1'
         }
         else if (!item.publishedAt && item.published_to_draft){
          url+='story/' +item?.id + '/preview';
         }
         else{
          url+=  'company/' + item.slug
          }
    
      }
    }

    return url;
   
  }
  getRolesGridValue(gridval:GridViewFilterOptionsModel,value:any)
  {
    gridval.filters.forEach((item,i)=>{
      item.defaultValue = value['userRole']
    })
   
    return gridval;
  }

  filter(event,item)
  {
    if (item) { item.fieldsToUpdate = ['admin']; item.userRole = event}
     this.columnDataChange.emit(item);
  }
  sorting(column){
  if(this.currentSortType == column.name){

    this.currentSortType = column.name
    if(this.sortString == 'asc'){
      let obj = column
      obj['sortkey'] =  'asc'
      this.sortString = ''
       this.sort.emit(obj) 
      
    }
    else{
      let obj = column
      obj['sortkey'] =  'desc'
      this.sortString = 'asc'
       this.sort.emit(obj)
    }
  }
  else{
    this.currentSortType = column.name;
    let obj = column
    obj['sortkey'] =  'asc'
    this.sortString = ''
     this.sort.emit(obj) 
  }

  }
  openModal(){

  }
  onDrop(event: CdkDragDrop<string[]>){
    moveItemInArray(this.options.data, event.previousIndex, event.currentIndex);

      var data = this.options.data
      var arrayCta = [];
      data.forEach((dts,id)=>{
        dts['order'] = id + 1;
        let obj = {
          id: dts.id,
          order: id + 1
        }
        arrayCta.push(obj)
      })

      this.OnDragandDrop.emit(arrayCta)
  }
}
