import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { reportLeadsActions } from '../actions';
import { ReportLeadsHttpService } from '../services';

@Injectable()
export class ReportLeadsEffects {

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<reportLeadsActions.Load>(reportLeadsActions.ActionTypes.LOAD),
    switchMap((action: reportLeadsActions.Load) => {
      return this.ReportLeadsHttpService.load(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new reportLeadsActions.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new reportLeadsActions.LoadFail(error));
        }));
    })
  ));

  
  loadSpotlightLeadsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<reportLeadsActions.LoadSpotlightLeads>(reportLeadsActions.ActionTypes.LOAD_SPOTLIGHT_LEADS),
    switchMap((action: reportLeadsActions.LoadSpotlightLeads) => {
      return this.ReportLeadsHttpService.loadSpotlightLeads(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new reportLeadsActions.LoadSpotlightLeadsSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new reportLeadsActions.LoadSpotlightLeadsFail(error));
        }));
    })
  ));





  constructor(
    private actions$: Actions,
    private ReportLeadsHttpService: ReportLeadsHttpService) { }
}
