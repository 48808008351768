import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { CommentInputApiModel } from '@modules/shared/models/api-models/input';
import { commentActions } from '@modules/shared/store/actions';
import {Observable, Subscription} from 'rxjs';
import { TextAreaEmojiComponent } from '@modules/shared/components/custom-controls';
import {UserProfileViewModel} from "@modules/user/store/models";
import {userProfileSelectors} from "@modules/user/store/selectors";
import { ApprovalFlowActions } from '@modules/company-admin/store/actions';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-approver-comment-post',
  templateUrl: './approver-comment-post.component.html',
  styleUrls: ['./approver-comment-post.component.scss']
})
export class ApproverCommentPostComponent extends ComponentBase {
  @Input() resourceId: number;
  @Input() resourceType: string;

  @ViewChild(TextAreaEmojiComponent)
  textAreaEmojiComponent: TextAreaEmojiComponent;

  // Private variables
  private postCommentSuccessSubscription: Subscription;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectCurrentUserProfileSubscription: Subscription;

  // Public variables
  public commentForm: UntypedFormGroup;
  public isConfirmCanceling: boolean = false;
  public isPostProcessing: boolean = false;
  public isShowControl: boolean = false;
  public response_id:any;
  public currentUserProfile: UserProfileViewModel;

  constructor(private store$: Store<CoreState>, private formBuilder: UntypedFormBuilder, private actions$: Actions, private activateRoute: ActivatedRoute) {
    super();

    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.response_id = this.activateRoute.snapshot.params.responseId
    this.initForm();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unscubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private initForm() {
    this.commentForm = this.formBuilder.group({
      content: ['', Validators.required]
    });
  }

  private subscribe() {
    this.postCommentSuccessSubscription = this.actions$.pipe(
      ofType<ApprovalFlowActions.CreateCommentSuccess>(ApprovalFlowActions.ActionTypes.CREATE_COMMENT_SUCCESS)
    ).subscribe(() => {
      this.isPostProcessing = false;
      this.commentForm.reset();
      this.store$.dispatch(new ApprovalFlowActions.GetComment(this.currentUserProfile?.company?.id, this.activateRoute.snapshot.paramMap.get('responseId')))
    });
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
      }
    });
  }

  private unscubscribe() {
    this.postCommentSuccessSubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
  }
  // Public functions
  public post() {
    if (!this.commentForm.valid || !this.resourceId || !this.resourceType || this.isPostProcessing) return;

    this.isPostProcessing = true;
    const postData: any = {
        content: this.commentForm.controls.content.value,
        author_id:this.currentUserProfile?.id
    };
    this.store$.dispatch(new ApprovalFlowActions.CreateComment(this.currentUserProfile?.company?.id,this.response_id,postData));
  }

  public cancel() {
    this.isConfirmCanceling = true;
  }

  public confirmYes() {
    this.isConfirmCanceling = false;
    this.isShowControl = false;
    this.commentForm.reset();
  }

  public confirmNo() {
    this.isConfirmCanceling = false;
  }

  public textAreaFocus() {
    this.isShowControl = true;
  }
}

