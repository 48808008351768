import { TestimonialOutputApiModel } from '../models/api-models/output';

export interface TestimonialEntity {
    id: number;
    internalId: number;
    campaignId: number;
    title: string;
    slug: string;
    author: string;
    leads: number;
    likes: number;
    players: number;
    published: Date;
    status: string;
    approver: string;
    approved: boolean;
    visible: boolean;
    active: boolean;
    public: boolean;
    internal: boolean;
    author_company?: any;
    author_email?: any;
    author_type?: any;
    job_title?: any;
    consent_details?:any;
    final_approver?: any;
    id_campaign_consent?:any;
}

export class TestimonialEntity {
    constructor(data?: TestimonialOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: TestimonialOutputApiModel) {
        this.id = data.id;
        this.internalId = data.internal_id;
        this.campaignId = data.id_campaign;
        this.title = data.title;
        this.slug = data.slug;
        this.author = data.author;
        this.leads = data.lead_count;
        this.likes = data.like_count;
        this.players = data.player_count;
        this.published = data.published;
        this.status = data.status;
        this.approver = data.approver;
        this.approved = data.approved;
        this.visible = data.visible;
        this.active = data.active;
        this.public = data.public;
        this.internal = data.internal;
        this.job_title = data?.job_title
        this.author_company = data?.author_company;
        this.author_email = data?.author_email
        this.author_type = data?.author_type
        this.consent_details = data?.consent_details
        this.final_approver = data?.final_approver
        this.id_campaign_consent = data?.id_campaign_consent
    }

}
