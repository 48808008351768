<co-pu-informations-skeleton [hidden]="currentCompany"></co-pu-informations-skeleton>

<div #info class="mobile-view b-information gsSlideUp" [ngClass]="{'is-active': !(currentCompany)}">
  <div class="container" role="main">
    <ng-container *ngIf="!showIFrame">
      <div [@showhide]="searchBoxStatus?.isShow ? 'hide' : 'show'">
        <div class="mobile-view" *ngIf="currentCompany?.name">
          <div class="card" tabindex="0" role="complementary">
            <ng-container>
              <div class="row padstyle" [ngClass]="{'showLogo': currentCompany?.showLogoHeader}">
                <ng-container *ngIf="!currentCompany?.showLogoHeader">
                  <div class="main-container">
                    <div class="col-4 mt-2 imageed">
                      <img class="imagess"
                        [src]="currentCompany.author?.avatar ? currentCompany.author?.avatar : 'assets/front-end-minify/img/avatar-default.png'"
                        alt="{{currentCompany.author?.displayName}}'s avatar">
                    </div>
                  </div>
                  <div class="col-7 ml-2 mb-1">
                    <!-- <p class="authorname" data-toggle="tooltip" data-placement="right"
                      title="{{currentCompany.author?.displayName}}">{{currentCompany.author?.displayName}} <br> 
                      <span class="title">{{currentCompany.author?.title}}</span></p> -->
                  </div>
                </ng-container>
                <ng-container *ngIf="currentCompany?.showLogoHeader">
                  <div class="main-container">
                    <div class="col-4 mt-2 imageed">
                      <img class="imagess"
                        [src]="currentCompany?.logo ? currentCompany?.logo : 'assets/front-end-minify/img/avatarstyle.png'"
                        alt="{{currentCompany?.name}} logo">
                    </div>
                  </div>
                  <div class="col-7 ml-2 mb-1">
                    <!-- <p class="authorname" data-toggle="tooltip" data-placement="right"
                      title="{{currentCompany?.name}}">{{currentCompany?.name}} <br>
                      <span class="title" (click)="navigatetocomapnywebsite()" style="cursor: pointer;" *ngIf="currentCompany?.website">{{ currentCompany?.website.includes('//') ?
                      currentCompany?.website.split('//')[1] : currentCompany?.website }}</span>
                    </p> -->
                  </div>
                </ng-container>
              </div>
              <div class="add__margin"> 
                <ng-container *ngIf="!currentCompany?.showLogoHeader">
                  <p class="authorname" data-placement="right" data-toggle="tooltip"
                    title="{{currentCompany.author?.displayName}}">{{currentCompany.author?.displayName}} <br> 
                    <span class="title">{{currentCompany.author?.title}}</span></p>
                </ng-container>
                <ng-container *ngIf="currentCompany?.showLogoHeader">
                  <p class="authorname" data-toggle="tooltip" data-placement="right"
                    title="{{currentCompany?.name}}">{{currentCompany?.name}} <br>
                    <span class="title" *ngIf="currentCompany?.website">{{ currentCompany?.website.includes('//') ?
                    currentCompany?.website.split('//')[1] : currentCompany?.website }}</span> <br>
                  </p>
                </ng-container>
              </div>
              <div class="container pos-container">
                <div style="height:10px;"></div>
                <div class="quote" [formGroup]="quoteForm">
                  <textarea placeholder="Enter your quote here" class="input-style noBorder" [readonly]="isEditQuote" formControlName="quoteFormControl" type="text" [attr.aria-label]="'Company quote'"></textarea>             
                </div>
                <div class="row footstyle">
                  <div class="col-4 aligncntr">
                    <h1 class="headingtags">{{currentCompany.countStories}}</h1>
                    <p class="tiltletags">Stories</p>
                  </div>
                  <div class="col-4 aligncntr"> 
                    <h1 class="headingtags">{{currentCompany.countAuthors}}</h1>
                    <p class="tiltletags">Author<span *ngIf="currentCompany.countAuthors != 1">s</span></p>
                  </div>
                  <div class="col-4 aligncntr">
                    <h1 class="headingtags">{{currentCompany.countContributors}}</h1>
                    <p class="tiltletags">Contributor<span *ngIf="currentCompany.countContributors != 1">s</span></p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showtags">
      <div [ngClass]="{'addmarginforleft': !searchBoxStatus.isShow && !showIFrame,'changecursor': isCompany}">
        <div class="row mobile-view ml-3" [ngClass]="{'serchallign': searchBoxStatus.isShow,'aligntags': showIFrame, 'contents':falgforFalse == 0 }">
          <div class="col-6 col-lg-3 col-md-4 px-2 mb-3 mb-md-0" *ngIf="showorhide(ourteams)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title" *ngIf="currentCompany?.frequentTags && showtags">{{getNames(ourteams)}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--purple" *ngIf="currentCompany.frequentTags && showtags">
                  <div class="l-chips__item" *ngFor="let tag of currentCompany.frequentTags[ourteams]?.data">
                    <a (click)="filterTag(tag)" class="chip-item" role="button" tabindex="0"
                      [ngStyle]="{'background': hexToRgbA(currentCompany.frequentTags[ourteams]?.color) , 'color': currentCompany.frequentTags[ourteams]?.color}">{{tag}}</a>
                  </div>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!currentCompany.frequentTags || !currentCompany.frequentTags[ourteams] || currentCompany.frequentTags[ourteams]?.data.length === 0">
                  How your crew is feeling on display here.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3 col-md-4 px-2 mb-3 mb-md-0" *ngIf="showorhide(weAppreciate)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title" *ngIf="currentCompany?.frequentTags && showtags">{{getNames(weAppreciate)}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--impact" *ngIf="currentCompany.frequentTags && showtags">
                  <div class="l-chips__item" *ngFor="let tag of currentCompany.frequentTags[weAppreciate]?.data">
                    <a (click)="filterTag(tag)" class="chip-item" role="button" tabindex="0"
                      [ngStyle]="{'background': hexToRgbA(currentCompany.frequentTags[weAppreciate]?.color) ,'color':currentCompany.frequentTags[weAppreciate]?.color}">{{tag}}</a>
                  </div>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!currentCompany.frequentTags || !currentCompany.frequentTags[weAppreciate] || currentCompany.frequentTags[weAppreciate]?.data.length === 0">
                  Stories reveal trending skills and talents here.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3 col-md-4 px-2 mb-3 mb-md-0" *ngIf="showorhide(weValue)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title"*ngIf="currentCompany?.frequentTags && showtags">{{getNames(weValue)}}</div>
              <div class="m-infor-boxes__content">
                <div class="l -chips l-chips--green" *ngIf="currentCompany.frequentTags && showtags">
                  <div class="l-chips__item" *ngFor="let tag of currentCompany.frequentTags[weValue]?.data">
                    <a (click)="filterTag(tag)" class="chip-item" role="button" tabindex="0"
                      [ngStyle]="{'background': hexToRgbA(currentCompany.frequentTags[weValue]?.color) , 'color':currentCompany.frequentTags[weValue]?.color}">{{tag}}</a>
                  </div>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!currentCompany.frequentTags || !currentCompany.frequentTags[weValue] || currentCompany.frequentTags[weValue]?.data.length === 0">
                  The latest values in action featured here.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3 col-md-4 px-2 mb-3 mb-md-0" *ngIf="showorhide(ourVibes)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title"*ngIf="currentCompany?.frequentTags && showtags" >{{getNames(ourVibes)}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--purple" *ngIf="currentCompany.frequentTags && showtags">
                  <div class="l-chips__item" *ngFor="let tag of currentCompany.frequentTags[ourVibes]?.data">
                    <a (click)="filterTag(tag)" class="chip-item" role="button" tabindex="0"
                      [ngStyle]="{'background': hexToRgbA(currentCompany.frequentTags[ourVibes]?.color) , 'color': currentCompany.frequentTags[ourVibes]?.color}">{{tag}}</a>
                  </div>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!currentCompany.frequentTags || !currentCompany.frequentTags[ourVibes] || currentCompany.frequentTags[ourVibes]?.data.length === 0">
                  How your crew is feeling on display here.
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-3 col-md-4 px-2 mb-3 mb-md-0" *ngIf="showorhide(ourChallenges)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title" *ngIf="currentCompany?.frequentTags && showtags">{{currentCompany.frequentTags[ourChallenges]?.header_title}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--purple" *ngIf="currentCompany.frequentTags && showtags">
                  <div class="l-chips__item" *ngFor="let tag of currentCompany.frequentTags[ourChallenges]?.data">
                    <a (click)="filterTag(tag)" class="chip-item" role="button" tabindex="0"
                      [ngStyle]="{'background': hexToRgbA(currentCompany.frequentTags[ourChallenges]?.color) , 'color': currentCompany.frequentTags[ourChallenges]?.color}">{{tag}}</a>
                  </div>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!currentCompany.frequentTags || !currentCompany.frequentTags[ourChallenges] || currentCompany.frequentTags[ourChallenges]?.data.length === 0">
                  How your crew is feeling on display here.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


<ng-container *ngIf="!showIFrame">
  <div class="mobile-section col-12">
    <div class="avatar-mobile" *ngIf="!currentCompany?.showLogoHeader">
      <img class="avatar-image"
        [src]="currentCompany.author?.avatar ? removeUrl(currentCompany.author?.avatar) : 'assets/front-end-minify/img/avatar-default.png' "
        alt="">
    </div>
    <div class="avatar-mobile" *ngIf="currentCompany?.showLogoHeader">
      <img class="avatar-image"
        [src]="currentCompany?.logo ? removeUrl(currentCompany?.logo) : 'assets/front-end-minify/img/avatarstyle.png'" alt="">
    </div>
    <div class="container">
      <div class="body-mobile row">
        <div class="col-8">
          <div class="heading-tiltle" *ngIf="!currentCompany?.showLogoHeader">
            <p class="mobile-authorname">{{currentCompany.author?.displayName}} <br> 
            <span class="mobile-title">{{currentCompany.author?.title}}</span></p>
          </div>

          <div class="heading-tiltle" *ngIf="currentCompany?.showLogoHeader">
            <p class="mobile-authorname">{{currentCompany?.name}} <br>
              <span class="mobile-title" *ngIf="currentCompany?.website">{{ currentCompany?.website.includes('//') ?
              currentCompany?.website.split('//')[1] : currentCompany?.website }}</span>
            </p>
          </div>
          
          <div class="mobile-quote" [attr.aria-label]="'Company slogan'">
            {{currentCompany?.slogan}}...
          </div>

          <div class="author-detail">
            <p class="mobile-headlinetags">{{currentCompany.countStories}}</p>
            <p class="mobile-titletags">Stories</p>&nbsp;&nbsp;

            <p class="mobile-headlinetags">{{currentCompany.countAuthors}}</p>
            <p class="mobile-titletags">Author<span *ngIf="currentCompany.countAuthors != 1">s</span></p>&nbsp;&nbsp;

            <p class="mobile-headlinetags">{{currentCompany.countContributors}}</p>
            <p class="mobile-titletags">Contributor<span *ngIf="currentCompany.countContributors != 1">s</span></p>
            &nbsp;&nbsp;
          </div>
        </div>

        <div class="col-4">
          <div class="mobile-btn-main">
            <a type="button" *ngFor="let button of getButtons()" [style.background-color]="button.background"
              [style.color]="button.foreground" class="mobile-btn row" href="{{button.url}}"
              target="_blank" role="button" [attr.aria-label]="button.label">
              {{button.label}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
