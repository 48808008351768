<div class="site-notify dropdown d-none d-lg-block ml-2" *ngIf="viewmodel.count > 0">
  <button id="dropdown-notify" role="button" data-toggle="dropdown" data-flip="false" aria-haspopup="true"
          aria-expanded="false" class="btn btn-trans text-gray site-notify__btn">
    <svg role="img">
      <use xlink:href="#icon-notify"></use>
    </svg>
    <span class="gs-badge gs-badge--small" [hidden]="viewmodel.countUnread === 0">
    </span>
  </button>
  <div aria-labelledby="dropdown-notify" class="site-notify__cnt dropdown-menu" [ngClass]="{'change__position':includesCampaign}">
    <div class="m-notify">
      <div class="m-notify__header" [hidden]="viewmodel.countUnread === 0">
        <span class="m-notify__title">Notifications</span>
        <span class="gs-badge ml-1">{{viewmodel.countUnread > 99 ? '99+' : viewmodel.countUnread}}</span>
      </div>
      <div class="m-notify__body scrollbar-macosx"
           [ngStyle]="{'max-height': viewmodel.count > 0 && viewmodel.count < 5 ? (viewmodel.count * 70 ) + 'px' : '' }">
        <scrollbar *ngIf="notifications.length > 0" (onYReachEnd)="scrollReachEnd($event)"
                   (onPositionChanged)="onPositionChanged($event)">
          <div class="m-notify-list">
            <div class="m-notify-list__item" *ngFor="let notification of notifications"
                 [ngClass]="{'read': notification.readDate !== null}">
              <a class="m-notify-list__wrap" (click)="makeAsRead(notification)">
                <div class="m-notify-list__avatar">
                  <div [background-image]="notification.photo" class="avatar"></div>
                </div>
                <div class="m-notify-list__content" [innerHtml]="notification.content">
                </div>
              </a>
            </div>

            <div class="m-notify-list__item" [hidden]="!viewmodel.loading || !viewmodel.clearing">
              <div class="text-center">
                <div class="lds-ellipsis-mint">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </scrollbar>
      </div>
      <div class="m-notify__footer" *ngIf="viewmodel.countUnread > 0">
        <a (click)="clearAll()" class="btn btn-link btn-block">Clear all</a>
      </div>
    </div>
  </div>
</div>
