<img *ngIf="!isBackground" 
     [src]="imgSrc" 
     [alt]="alt || 'User  Avatar'" 
     [class]="classCss || ''" 
     role="img" 
     aria-label="User  Avatar">
<div *ngIf="isBackground" 
     [style.backgroundImage]="'url(' + imgSrc + ')'" 
     [class]="classCss" 
     role="img" 
     aria-label="User  Avatar" 
     aria-hidden="true"></div>