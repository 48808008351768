import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnChanges, Output, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'gs-area-wysiwyg',
  templateUrl: './gs-area-wysiwyg.component.html',
  styleUrls: ['./gs-area-wysiwyg.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GsAreaWysiwygComponent),
      multi: true
    }
  ]
})
export class GsAreaWysiwygComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  // Inputs
  @Input() label: string;
  @Input() labelSmall: string;
  @Input() isEditable: boolean = false;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() formControl: UntypedFormControl;
  @Input() cssClass: string;
  @Input() hideErrorMessage: boolean = false;
  @Input() rows: number;

  isBrowser:boolean;
  // Outputs
  @Output() blur: EventEmitter<string> = new EventEmitter<string>();

  // Private variables
  private inputValue: string;
  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };

  quillModule: any;
  show:boolean =false;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      // Dynamic imports for SSR compatibility
      const QuillModule = await import('quill');
      const ImageResizeModule = await import('quill-image-resize-module');
      
      const Quill = QuillModule.default;
      const ImageResize = ImageResizeModule.default;

      let Font = Quill.import('formats/font');
      Font.whitelist = ['arial', 'Roboto', 'cursive', 'fantasy', 'monospace', 'sans-serif', 'serif', 'times-new-roman'];
      Quill.register(Font, true);
      Quill.register('modules/imageResize', ImageResize);

      this.quillModule = {
        imageResize: {
          displaySize: true,
        },
        toolbar: [
          ['bold',  'italic',  'underline',  'color', { 'align': [] },'blockquote', 'link', 'image', 'video', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'font': ['arial', 'Roboto', 'cursive', 'fantasy', 'monospace', 'sans-serif', 'serif', 'times-new-roman'] }, { 'header': [1, 2, 3, false] }, 'clean']
        ]
      };
    }

    setTimeout(()=>{
      this.show = true;
    })
  }

  ngAfterViewInit(): void {}

  ngOnChanges(): void {}

  private triggerChange($event): void {
    this.fnOnChange($event);
    const event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }

  public set value(val: string) {
    if (val !== undefined) {
      this.inputValue = val;
    }
  }

  public get value() {
    return this.inputValue;
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public writePlaceholder(val: string): void {
    this.placeholder = val;
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onChange(): void {
    this.triggerChange(this.inputValue);
  }
}
