<div class="modal-header">
  <h5 class="modal-title">{{initialState.fromTag ? 'Save Tag' : 'New Story Prompt'}}</h5>
  <button type="button" (click)="close()" class="close"><span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span></button>
</div>

<div class="modal-body" [formGroup]="updateForm">
  <div class="row mb-4" >
    <div class="col-12">
      <gs-input-material label="Ask a good question!" name="Title" placeholder="Title" isTextarea="true" rows="0" cssClass="spacing-lg"
        formControlName="title" [formControl]="updateForm.controls.title"></gs-input-material>
    </div>
    <div class="warning-text" *ngIf="!initialState.fromTag && initialState.data?.story_count > 0 && showEditWarning">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <p class="px-2">Edit will update all published stories.</p>
    </div>
  </div>

  <div class="row mb-4" *ngIf="initialState.main_board">
    <label class="row mb-0 spacer ml-3">
      <div class="">
        <div class="form-group mb-4">
          <span class="switch">
            <input type="checkbox" formControlName="is_active">
            <span class="slider"></span>
          </span>
        </div>
      </div>
      <div class="">
        <div class="form-group mb-4 ml-2">Enable for Others</div>
      </div>
    </label>
  </div>
</div>


<div class="modal-footer">
  <button (click)="close()" type="button" data-dismiss="modal"
    class="btn btn-outline btn-gray-light min-width">Cancel</button>
  <button (click)="save()" [disabled]="isUpdating || IsDisabled" type="button" class="btn btn-pink min-width">
    <i *ngIf="isUpdating" class="fas fa-spinner fa-pulse"></i> Save
  </button>
</div>