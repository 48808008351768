import { Component, SimpleChanges, ChangeDetectorRef, ViewChild, ElementRef, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { PaginationModel } from '@framework/models/pagination-models';
import { CoreState } from '@app/store/core/states';
import { ScrollbarComponent } from '@framework/components';
import { storyBuilderSelector } from '@modules/story/store/selectors';
import { UtilityHelper } from '@framework/utilities';
import { StoryOutputApiModel } from '@modules/story/models/api-models/output';
import { UserViewModel, UserProfileViewModel } from '@modules/user/store/models';
import { LoadUserInputApiModel } from '@modules/user/models/input';
import { userSelectors, userProfileSelectors } from '@modules/user/store/selectors';
import { userActions, userPermissionActions, userProfileActions } from '@modules/user/store/actions';
import { campaignSelectors, companySelectors } from '@modules/company/store/selectors';
import { CampaignViewModel, CompanyViewModel } from '@modules/company/models/store-view-models';
import { Actions, ofType } from '@ngrx/effects';
import { ValidateUserOutApiModel } from '@modules/user/models/output';
import { ComponentModalBase } from '@framework/base';
import { ToastService } from '@framework/services';
import { isString } from 'ts-util-is';
import { RequestInputApiModel } from '@modules/shared/models/api-models/input';
import { campaignActions } from '@modules/company/store/actions';
import { GetCampaignInputApiModel } from '@modules/company/models/api-models/input';
import { CommentHttpService } from '@modules/shared/services';
import { Router } from '@angular/router';
import { storyAction, storyStepActions } from '@modules/story/store/actions';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { storyActions } from '@modules/company-admin/store/actions';
@Component({
  selector: 'app-invitguestuserinbound',
  templateUrl: './inviteGuestusertoInbound.component.html',
  styleUrls: ['./inviteGuestusertoInbound.component.scss'],
  animations: [
    trigger('showhide', [
      // ...
      state('show', style({
        opacity: 1,
        zIndex: 1
      })),
      state('hide', style({
        opacity: 0,
        zIndex: -1
      })),
      transition('show => hide', [
        animate('0.5s ease-in-out')
      ]),
      transition('hide => show', [
        animate('0.5s ease-in-out')
      ]),
    ]),
    trigger('showhideadthis', [
      // ...
      state('show', style({
        opacity: 1,
        zIndex: 1000
      })),
      state('hide', style({
        opacity: 0,
        zIndex: -1
      })),
      transition('show => hide', [
        animate('0.5s ease-in-out')
      ]),
      transition('hide => show', [
        animate('0.5s ease-in-out')
      ]),
    ]),
  ]
})
export class InviteGuestUsertoinbound extends ComponentModalBase<any> {
  @ViewChild("shareholic") shareholic: ElementRef;
  private getMembers$: Observable<UserViewModel[]>;
  private getNextUrl$: Observable<string>;
  private selectPlayerLoading$: Observable<boolean>;
  private selectPlayerLoaded$: Observable<boolean>;
  private selectCurrentCompany$: Observable<CompanyViewModel>;
  private selectCurrentProfile$: Observable<UserProfileViewModel>;
  public upatedCampaignList:any;

  private formChangeSubscription: Subscription;
  private inviteFormchange: Subscription;
  private getNextUrlSubscription: Subscription;
  private loadPermissionSuccessSubscription:Subscription;
  private selectPlayerLoadingSubscription: Subscription;
  private selectPlayerLoadedSubscription: Subscription;
  private getMembersSubscription: Subscription;
  private selectCurrentCompanySubscription: Subscription;
  private selectCurrentProfileSubscription: Subscription;
  private validateUserSuccessSubscription: Subscription;
  private selectProfileSuccessSubscription: Subscription;
  private selcetFormSubscription: Subscription;
  private loadQuestionsSubscription: Subscription;
  private loadColorSuccessSubscription: Subscription;
  public primary_color:any;

  private loadUserInputData: LoadUserInputApiModel = new LoadUserInputApiModel(new PaginationModel(1000));
  private isPlayerAdding: boolean = false;
  public members: any = [];
  public newMembers: any = [];
  public questionList:any[] = [];
  public searchBarVisible: boolean = false;
  public requestedMembers: any[] = [];
  public username:any;
  public loggedInUserId:any;
  public showRequestedUser:boolean = false;
  public requestedUsers: any[] = []
  public isCopyCode: boolean = false
  public readOnlyforTextfiled: boolean = false;
  public isSingleInternalRequested:boolean = false;
  public hideSendanCopylinkButton: boolean = false;
  public emailSendIngPayload:any [] = [];
  public externalUrlforCopy:string;
  public InternalStorySingleUrl:string;
  public userQuestion: any[] = [];
  public guestquestion: any[] = [];
  public isDispalyMenu: boolean = false
  public isDispalyadthis: boolean = false
  public viewmodel: any = {
    scrollbarVisible: false,
    reachStarted: false,
    reachEnded: false,
    firstLoad: true,
    lastPosition: null,
    fetch: false,
    searching: false,
    isStoryCreating: false,
    isStoryUpdating: false,
    isPlayerCreating: false,
    isPlayerLoading: false,
    isPlayerLoaded: false,
    isInboundCampaign: false,
    //loadingDataOnScroll: false
  };
  public form: UntypedFormGroup;
  public addPlayerForm: UntypedFormGroup;
  public requestForm: UntypedFormGroup;
  public loadMore: boolean = true;
  public stepActive: any;
  public selectedData: any = [];
  public internalquestionId:any;
  public externalquestionId:any;
  public storyData: StoryOutputApiModel;
  public isFormSubmited: boolean = false;
  public companyLogo: string;
  public company: CompanyViewModel;
  public isNoOneSpecific: boolean = false;
  public diableonsearching: boolean = false;
  public isShowNoOneSpecific: boolean = false;
  public loggedInDisplayname: any;
  public isHideErrorEmailInput: boolean = true;
  public isLoading: boolean = false;
  public campaigns: CampaignViewModel[] = [];
  public questionModels:any[] = [];
  private selectCampaign$: Observable<CampaignViewModel[]>;
  private selectCampaignSubscription: Subscription;
  public buttonText: string = 'Copy Link'
  private queryCampaignsData: GetCampaignInputApiModel = new GetCampaignInputApiModel(new PaginationModel(1000));

  @ViewChild('scrollbar')
  scrollbar: ScrollbarComponent;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store$: Store<CoreState>,
    private detector: ChangeDetectorRef,
    private actions$: Actions,
    private utility: UtilityHelper,
    private router: Router,
    private actions: Actions,
    private toastService: ToastService,
    private requestService: CommentHttpService
  ) {
    super();

    this.getMembers$ = this.store$.select(userSelectors.selectUsers);
    this.getNextUrl$ = this.store$.select(userSelectors.selectNextUrl);
    this.selectPlayerLoading$ = this.store$.select(userSelectors.selectLoading);
    this.selectPlayerLoaded$ = this.store$.select(userSelectors.selectLoaded);
    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectCurrentProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectCampaign$ = this.store$.select(campaignSelectors.selectAll);

    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      keyword: ['']
    });

    this.requestForm = this.formBuilder.group({
      about: [''],
      bodyCode: [{ value: '', disabled: true }],
      campaign: [-1],
      requestedUser: [''],
      note_email:[],
      userselcted:[],
      externalUserSelcted:[],
      internalmessage: ['',Validators.maxLength(600)],
      firstname:[''],
      lastname: [''],
      message: ['',Validators.maxLength(600)],
      email: [''],
      question:[-1],
      userStatus:['user'],
      addPersonalize:[false],
    });

    this.addPlayerForm = this.formBuilder.group({
      players: this.formBuilder.array([this.createAddPlayerItemForm()])
    });
  }

  private createAddPlayerItemForm() {
    return this.formBuilder.group({
      firstName: ['', Validators.required],
      email: ['', Validators.compose([this.emailValidator.bind(this), Validators.email])]
    });
  }

  protected onInit(): void {
    this.store$.dispatch(new userProfileActions.LoadCurrentProfile());

    if(this.initialState.hidetopbar){
      this.requestForm.controls.campaign.setValue(-1);
      if(this.initialState.foruser){
        this.internalquestionId = this.initialState.question_id
        this.requestForm.controls.userStatus.setValue('user')
      }
      else{
        this.externalquestionId = this.initialState.question_id
        this.requestForm.controls.userStatus.setValue('Guestuser')
      }
     
    }  
    this.subscribe();
    if(this.initialState.fromCampaign){
       this.requestForm.controls.campaign.setValue(this.initialState.campaignId)
    }

  }
  protected onDestroy(): void {
    this.unSubscribe();
  }

  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
    // $(document).on('click','#cancel-request-btn',function(event){
    //   close()
    // })
  }

  protected onAfterViewChecked(): void {

  }

  private subscribe() {
    this.inviteFormchange = this.requestForm.controls['message'].valueChanges.pipe(
      debounceTime(100)
    ).subscribe((value) => {
  
      if(this.hideSendanCopylinkButton && this.isSingleInternalRequested){
        this.hideSendanCopylinkButton  = false;
        this.isSingleInternalRequested = false;
      }
  
    })
    this.selectProfileSuccessSubscription = this.actions$.pipe(
      ofType<userPermissionActions.LoadPermissionsSuccess>(userProfileActions.ActionTypes.LOAD_CURRENT_PROFILE_SUCCESS)
    ).subscribe((action: any) => {
     if(action && action.response){
       console.log(">>",action)
       this.loggedInUserId = action.response.id;
       this.loggedInDisplayname = action.response.display_name
       if(this.requestForm.value.userStatus == 'Guestuser'  &&  !this.isSingleInternalRequested){
        this.requestForm.controls.externalUserSelcted.setValue(this.loggedInUserId)
      }

      else if( this.requestForm.value.userStatus == 'user' && !this.isCopyCode){
        this.requestForm.controls.userselcted.setValue(this.loggedInUserId)
      }
     }
    })
    this.formChangeSubscription = this.form.controls['keyword'].valueChanges.pipe(
      debounceTime(900)
    ).subscribe((value) => {
      this.viewmodel.searching = true;
      this.loadUserInputData.search = value && value.trim() || '';
      this.loadUserInputData.pagination.page = 1;
      this.loadUserInputData.pagination.sort = 'desc'

      // this.scrollbar.scrollToTop(0);
      if (this.loadUserInputData.search.length === 0) {
        this.diableonsearching = false;
        this.resetSearchForm();
      } else {
        this.diableonsearching = true;
        this.loadMembers([]);
      }
    });

    this.selectCampaignSubscription = this.selectCampaign$.subscribe((campaigns) => {
      if (campaigns && (!this.campaigns.length || this.campaigns.length === 1)) {
        this.campaigns = campaigns.filter(camp => camp.daysLeft > 0) || [];
        const nonCampaign = {
          id: -1,
          title: 'Select option',
          queryObjects: null,
          buttons: null,
          slug: null,
          headerImage: null,
          avatarImage: null,
          person: null,
          description: null,
          start_date: null,
          end_date: null,
          companySlug: null,
          isInboundStoriesEnabled: null,
          isPublic: null,
          stories: null,
          daysLeft: null,
          storyPrompt: null,
          show_story_prompt: 0
        };
        this.campaigns.unshift(nonCampaign);


         
      

      }
    });

    this.getNextUrlSubscription = this.getNextUrl$.subscribe((nextUrl) => {
      this.loadMore = nextUrl !== null;
    });

    this.selectPlayerLoadingSubscription = this.selectPlayerLoading$.subscribe((isLoading: boolean) => {
      this.viewmodel.isPlayerLoading = isLoading;
    });

    this.selectPlayerLoadedSubscription = this.selectPlayerLoaded$.subscribe((isLoaded: boolean) => {
      this.viewmodel.isPlayerLoaded = isLoaded;
    });

   this.loadPermissionSuccessSubscription =  this.actions$.pipe(
      ofType<userPermissionActions.LoadPermissionsSuccess>(userPermissionActions.ActionTypes.LOAD_PERMISSION_SUCCESS)
    ).subscribe((action: any) => {
     if(action.response && action.response.length){
      this.showRequestedUser = action.response.find(permission => permission.name === 'requested_user_details');
     }
    })

    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((company) => {
      if (company) {
        this.companyLogo = company.logo;
        this.company = company;
        this.viewmodel.inbound = false;
        this.viewmodel.companyId = company.id;
        this.queryCampaignsData.isLoadExpired = false;
        this.queryCampaignsData.pagination.sort = 'desc';
        this.queryCampaignsData.isIncludeStories = true;
        this.queryCampaignsData.public = false;
        this.queryCampaignsData.pagination.limit = 100;
        this.store$.dispatch(new storyActions.GetColor(this.company.id));
        this.store$.dispatch(new campaignActions.Load(this.company.id, this.queryCampaignsData));
        this.store$.dispatch(new storyStepActions.LoadQuestions(this.company.id));
        this.store$.dispatch(new userPermissionActions.LoadPermissions())
      }
    });

    this.getMembersSubscription = this.getMembers$.subscribe((members) => {
      this.viewmodel.searching = false;
     // this.viewmodel.loadingDataOnScroll = false;
      this.detector.detectChanges();
      this.viewmodel.isInboundCampaign = (this.viewmodel.inbound && this.company.isCampaignCompany);
      if (!this.viewmodel.isInboundCampaign) {
         this.members = members;
         this.requestedMembers = this.members.map((member)=>{
          return {
            title:member.displayName,
            id:member.id
          }
         })
         if(this.requestedMembers.length && this.loggedInUserId){
          let loggedIn ={
            id:this.loggedInUserId,
            title:this.loggedInDisplayname
          }
          this.requestedMembers.unshift(loggedIn)
       
        }
  
      }
      this.updateSelectedValues();
    });

    this.selectCurrentProfileSubscription = this.selectCurrentProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.companyLogo = userProfile.company.logo;
        this.viewmodel.inbound = false;
        this.viewmodel.companyId = userProfile.company.id;
        this.selectedData = [];
        this.playersFormArray.controls.forEach((fgItem: UntypedFormGroup) => {
          fgItem.controls.email.updateValueAndValidity();
        });
        this.store$.dispatch(new storyActions.GetColor(userProfile.company.id));
        this.store$.dispatch(new storyStepActions.LoadQuestions(userProfile.company.id))
        this.updateSelectedValues();
        this.updateSelectedNoOneSpecific();
        this.loadMembers(this.selectedData);
      }
    });

    this.validateUserSuccessSubscription = this.actions.pipe(
      ofType<userActions.ValidateUserSuccess>(userActions.ActionTypes.VALIDATE_USER_SUCCESS)
    ).subscribe((action: userActions.ValidateUserSuccess) => {
      this.validateUsersSuccess(action.response);
    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.GetColorSuccess>(storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primary_color = action.response.find(item => item.button == 11).color;
       }
      

    })
    this.loadQuestionsSubscription = this.actions$.pipe(
      ofType<storyStepActions.LoadQuestionsSuccess>(storyStepActions.ActionTypes.LOAD_QUESTIONS_SUCCESS)).
        subscribe((questions: any) => {
         if(questions && questions.data && questions.data && questions.data.length){
          this.questionList= questions.data.filter(question => question.is_active == 1);
          if(this.questionList.length){
            const nonQuestion = {
              id:-1,
              is_active:null,
              question_text: 'Select option'

            }
            this.userQuestion = this.questionList.filter(item => item.inbound == false);
            this.guestquestion = this.questionList.filter(item => item.inbound == true);
            this.userQuestion.unshift(nonQuestion);
            this.guestquestion.unshift(nonQuestion)

          }

         }
      })

    this.selcetFormSubscription = this.requestForm.controls.requestedUser.valueChanges.subscribe((data: any) => {
      this.requestForm.controls.bodyCode.setValue(data)
    })

  }

  chnageExternaladdPersonalize(data){
    // this.requestForm.controls.firstname.reset();
    // this.requestForm.controls.lastname.reset();
    // this.requestForm.controls.email.reset();
    this.externalUrlforCopy = '';
    this.isSingleInternalRequested = false;
    this.emailSendIngPayload = [];
    this.hideSendanCopylinkButton = false;
    if(this.requestForm.value.addPersonalize){
     this.requestForm.get('message').updateValueAndValidity();
     this.requestForm.get('message').setValidators([Validators.required]);
    }
    else{
      this.requestForm.get('message').updateValueAndValidity();
      this.requestForm.get('message').clearValidators();
     this.Chnageuserstatus(null)
    }
   }

  Chnageuserstatus(data){

    
    if(this.requestForm.value.userStatus == 'Guestuser' && !this.isSingleInternalRequested){
       this.requestForm.get('email').updateValueAndValidity();
       this.requestForm.get('email').setValidators([Validators.email]);
       this.requestForm.controls.externalUserSelcted.setValue(this.loggedInUserId);
       if(!this.initialState.fromCampaign){
        this.requestForm.controls.campaign.setValue(-1);
        this.requestForm.controls.question.setValue(-1);
       }
       this.isSingleInternalRequested = false;
       this.hideSendanCopylinkButton = false;
       this.emailSendIngPayload = [];

    }
    else if(this.requestForm.value.userStatus == 'Guestuser'  &&  this.isSingleInternalRequested){
      this.requestForm.controls.firstname.disable();
      this.requestForm.controls.lastname.disable();
      this.requestForm.controls.email.disable();
      this.requestForm.controls.note_email.disable();

      this.isSingleInternalRequested = true;
      this.hideSendanCopylinkButton = true;
      this.emailSendIngPayload = [];
      
   }

    else{
      if(!this.isCopyCode){
        this.requestForm.controls.internalmessage.reset();
        this.members = [];
        this.isCopyCode = false;
        this.loadUserInputData.pagination.page = 1;
        this.loadUserInputData.pagination.sort = 'desc'
        this.loadMembers([]);
        this.requestForm.controls.userStatus.setValue('user');
        if(this.initialState.fromCampaign){
          this.requestForm.controls.campaign.setValue(this.initialState.campaignId)
       }
       this.hideSendanCopylinkButton = false;
       this.emailSendIngPayload = [];
      }
      else{
        this.requestForm.controls.internalmessage.reset();
        this.requestForm.controls.userStatus.setValue('user');
        this.hideSendanCopylinkButton = true;
        if(this.initialState.fromCampaign){
          this.requestForm.controls.campaign.setValue(this.initialState.campaignId)
       }
      }

    }
  }

  showSearchBar(): void {
    this.searchBarVisible = true;
    setTimeout(() => {
      document.getElementById("searchUnsplash").focus();
    }, 100);
  }

  private unSubscribe() {
    this.formChangeSubscription.unsubscribe();
    this.getNextUrlSubscription.unsubscribe();
    this.selectPlayerLoadingSubscription.unsubscribe();
    this.selectPlayerLoadedSubscription.unsubscribe();
    this.getMembersSubscription.unsubscribe();
    this.selectCurrentCompanySubscription.unsubscribe();
    this.selectCurrentProfileSubscription.unsubscribe();
    this.validateUserSuccessSubscription.unsubscribe();
    this.selcetFormSubscription.unsubscribe();
    this.loadPermissionSuccessSubscription.unsubscribe();
    this.selectProfileSuccessSubscription.unsubscribe();
    this.loadColorSuccessSubscription.unsubscribe();
  }

  private loadMembers(selectedData: any[]) {
    this.loadUserInputData.inbound = this.viewmodel.inbound;
    this.loadUserInputData.companyId = this.viewmodel.companyId;
    this.loadUserInputData.pagination.sort = 'desc'
    selectedData = selectedData || [];
    this.loadUserInputData.selectedValues = selectedData.filter(id => this.utility.Lodash.isNumber(id) && id > 0);
    let selectedEmails: string[] = [];
    const idsAsString = selectedData.filter(id => this.utility.Lodash.isString(id));
    idsAsString.forEach((sData: string) => {
      const emailParserRes = this.utility.StringHelper.emailParser(sData);
      selectedEmails.push(emailParserRes.email);
    });
    this.loadUserInputData.selectedEmails = selectedEmails;
    this.store$.dispatch(new userActions.Load(this.loadUserInputData));
  }

  private updateSelectedValues() {
    const searchValue = this.form.controls.keyword.value;
    if (searchValue) {
      this.members = [...this.newMembers, ...this.members].filter(member => member.displayName.toLowerCase().includes(searchValue.toLowerCase()) || member.email.toLowerCase().includes(searchValue.toLowerCase()));
    } else {
      this.members = [...this.newMembers, ...this.members];
    }
    this.members.forEach((member: UserViewModel) => {
      member.checked = this.selectedData && (this.selectedData.indexOf(member.id) > -1 || this.selectedData.findIndex(f => isString(f) && f.indexOf(member.email) > -1) > -1);
    });
    if(this.members.length){
      const selectedMembers = this.members.filter(member => member.checked);
      if(selectedMembers.length){
        this.diableonsearching = false;
      }
    }
    this.playersFormArray.controls[0].get('firstName').patchValue(searchValue);
    
  }

  private updateSelectedNoOneSpecific() {
    this.isNoOneSpecific = false;
    if (this.selectedData && this.selectedData.indexOf(-1) > -1) {
      this.isNoOneSpecific = this.selectedData && this.selectedData.indexOf(-1) > -1;
    }
  }

  private resetAddPlayerForm() {
    this.addPlayerForm.reset();
    this.addPlayerForm.setControl('players', this.formBuilder.array([this.createAddPlayerItemForm()]));
    this.isFormSubmited = false;
  }

  private resetSearchForm() {
    this.form.reset({}, { emitEvent: false });
    this.loadUserInputData.search = '';
    this.loadMembers(this.selectedData);
  }

  private addPlayerSuccess() {
    this.updateSelectedValues();
    this.resetAddPlayerForm();
    this.resetSearchForm();
    this.viewmodel.isPlayerCreating = false;
    this.updateData();
  }

  private emailValidator(control: UntypedFormControl) {
    let value = control.value;
    if (!this.viewmodel.inbound && (!value || value.length === 0)) {
      return 'String empty';
    }
    return null;
  }

  private validateUsers() {
    const emails = this.playersFormArray.value.map(player => player.email);
    this.store$.dispatch(new userActions.ValidateUser(emails));
  }

  private validateUsersSuccess(vuRes: ValidateUserOutApiModel[]) {
    this.playersFormArray.controls.forEach((fgItem: UntypedFormGroup) => {
      const checkAllowAddToStory = vuRes.find(f => f.email === fgItem.controls.email.value && f.story_invite_enabled === true);
      if (!checkAllowAddToStory) {
        this.isHideErrorEmailInput = false;
        fgItem.controls.email.setErrors(
          {
            isCustom: true,
            message: 'Unavailable to add. Please try another email!'
          }
        );
      }
    });

    this.addPlayer();
  }

  private addPlayer() {
    if (!this.addPlayerForm.valid) {
      this.viewmodel.isPlayerCreating = false;
      return;
    }

    this.isPlayerAdding = true;
    this.playersFormArray.controls.forEach((fgItem: UntypedFormGroup) => {
      let createUserData = new UserViewModel(null);
      createUserData.firstname = fgItem.controls.firstName.value;
      createUserData.lastname = '';
      createUserData.email = fgItem.controls.email.value || '';
      createUserData.displayName = `${createUserData.firstname}`;
      createUserData.id = `${createUserData.firstname} <${createUserData.email}>`;
      createUserData.checked = true;
      this.newMembers.unshift(createUserData);
      this.selectedData = this.selectedData || [];
      const newUserValue = `${createUserData.firstname} <${createUserData.email}>`;
      this.selectedData.push(newUserValue);
    });
    this.addPlayerSuccess();
  }

  public onYReachEnd() {
    if (this.loadMore && this.viewmodel.scrollbarVisible && !this.isCopyCode && this.router.url != '/directory') {
      this.loadUserInputData.pagination.page += 1;
      //this.viewmodel.loadingDataOnScroll = true;
      this.loadMembers([]);
    }
  }

  public onPositionChanged() {
    this.viewmodel.scrollbarVisible = true;
    if (this.loadMore && this.viewmodel.scrollbarVisible && !this.isCopyCode && this.router.url == '/directory') {
      this.loadUserInputData.pagination.page += 1;
      this.viewmodel.loadingDataOnScroll = true;
      this.loadMembers([]);
    }
  }

  public selectItem(player: UserViewModel) {
    this.selectedData = this.selectedData && this.selectedData.filter((id: number) => (this.utility.Lodash.isNumber(id) && id > 0) || this.utility.Lodash.isString(id)) || [];
    this.isNoOneSpecific = false;
    this.diableonsearching = false;
    this.form.controls.keyword.reset()

    if (player.checked === true) {
      this.selectedData.push(player.id);
    } else {
      const index = this.selectedData.findIndex(f => f === player.id);
      this.selectedData.splice(index, 1);
    }

    this.updateData();
  }

  public checkDisabled(member: any) {
    return this.selectedData && this.selectedData.length >= 3 && !member.checked;
  }

  public memberTrackByFnc(item) {
    return item.id;
  }

  public addPlayerFormSubmit() {
    const emails = this.playersFormArray.value.map(player => player.email);
    // emails.forEach(email => {
    //   const splitEmail = email.split('@');
    //   const emailDomain = splitEmail[1];
    //   if (!this.company.domains.find(domain => domain.domain === emailDomain)) {
    //     this.playersFormArray.controls.forEach((fgItem: FormGroup) => {
    //       if (fgItem.controls.email.value === email) {
    //         this.isHideErrorEmailInput = false;
    //         fgItem.controls.email.setErrors(
    //           {
    //             isCustom: true,
    //             message: 'Invalid domain!'
    //           }
    //         );
    //       }
    //     });
    //   }
    // });
    if (this.addPlayerForm.invalid) return;
    this.isFormSubmited = true;
    this.viewmodel.isPlayerCreating = true;

    this.validateUsers();
  }

  public updateData() {
    this.loadMembers(this.selectedData);
  }

  public handleComKeyEnter() {
  }

  changeData(data){
    if(typeof data === 'string' ){
      if(data != '-1'){
        this.requestForm.controls.about.setValue(this.questionList.find(item => item.id == +data).question_text)
        this.readOnlyforTextfiled = false;
        this.requestForm.controls.campaign.reset();
        this.requestForm.controls.campaign.setValue(-1);

      }
  
 
    }
  }


  public exceptHandleComKeyEnter(event) {
    event.stopPropagation();
  }
  public exceptHandleComKeyEnterKey(event) {
    event.stopPropagation();
  }

  get playersFormArray() {
    return this.addPlayerForm.get('players') as UntypedFormArray;
  }

  public addPlayerFormItem() {
    this.playersFormArray.push(this.createAddPlayerItemForm());
  }

  public removeAddPlayerFormItem($event, index) {
    $event.stopPropagation();
    this.playersFormArray.removeAt(index);
  }

    public close() {
      this.resetAddPlayerForm();
      this.resetSearchForm(); 
  }

  cancel() {
    this.onClose.emit(false);
  }
  

  sendRequest() {
    this.isLoading = true;
    const selectedMembers = this.members.filter(member => member.checked);
    if (selectedMembers.length) {
      const payload: RequestInputApiModel = {
        author_emails: selectedMembers.map((member: UserViewModel) => { return member.email }),
        story_prompt: this.requestForm.value.about,
        campaign_id: this.requestForm.value.campaign,
        story_title: this.requestForm.value.about,
        story_requested: 1,
        question_id_campaign: this.requestForm.value.question_id_campaign
      }
      this.requestService.request(payload).subscribe((data: any) => {
        this.isLoading = false;
        this.isCopyCode = true;
        this.requestedUsers = data
        this.checkForSelectedUsers(data);
        this.requestForm.patchValue({
          requestedUser: data[0].url,
          bodyCode: data[0].url
        })
      })
    }
  }

  private checkForSelectedUsers(data: any) {
    var tempDatastorage = []
    this.hideSendanCopylinkButton = true;
    if(data.length == 1){
      let emailPaylooad 
       this.InternalStorySingleUrl  = data[0].url;
       if(this.requestForm.value.campaign != -1 &&  this.initialState.fromCampaign){
        emailPaylooad  =  {
          author_email: data[0].email,
          story_id: data[0].story?.id,
          campaign_id:this.requestForm.value.campaign,
          interview_style:this.initialState.interview_style
         }
       }
       else{
        emailPaylooad  =  {
          author_email: data[0].email,
          story_id: data[0].story?.id,
          interview_style: this.initialState.interview_style
         }
       }
       
       this.emailSendIngPayload.push(emailPaylooad)
       this.members.forEach((item, i) => {
        data.forEach((items, j) => {
          if (items.email == item.email) {
            item["url"] = items.url
            tempDatastorage.push(item)
          }
        })
      })
  this.members = [];
  this.members = tempDatastorage
    }
    else if (data.length > 1 ){
        data.forEach((author)=>{
          let multipleEmailPayload
          if(this.requestForm.value.campaign != -1 &&  this.initialState.fromCampaign){
            multipleEmailPayload = {
              author_email: author.email,
              story_id: author.story?.id,
              campaign_id:this.requestForm.value.campaign,
              interview_style:this.initialState.interview_style
             } 
          }
          else{
            multipleEmailPayload = {
              author_email: author.email,
              story_id: author.story?.id,
              interview_style:this.initialState.interview_style
             } 
          }
           
           this.emailSendIngPayload.push(multipleEmailPayload)
        })
        this.hideSendanCopylinkButton = true;
        this.members.forEach((item, i) => {
          data.forEach((items, j) => {
            if (items.email == item.email) {
              item["url"] = items.url
              tempDatastorage.push(item)
            }
          })
        })
    this.members = [];
    this.members = tempDatastorage
    }
  }

  GenearateInternalMainlLink(){
    const selectedMembers = this.members.filter(member => member.checked);
    this.isLoading = true;
    let payload = {
      author_emails: selectedMembers.map((member: UserViewModel) => { return member.email }),
      story_requested: 1,
    }


    if(this.requestForm.value.campaign == -1 &&  this.initialState.hidetopbar){
      payload['storyPrompt'] = this.initialState.question;
      payload['story_title'] = this.initialState.question;
      payload['story_question_id'] = this.internalquestionId;
    }

    if(this.requestForm.value.campaign != -1 &&  this.initialState.fromCampaign){
      payload["campaign_id"]=this.requestForm.value.campaign;
      payload['interview_style'] = this.initialState.interview_style
    }
    if(this.requestForm.value.campaign != -1 &&  this.initialState.fromCampaign){
      payload["campaign_id"]=this.requestForm.value.campaign;
      payload['interview_style'] = this.initialState.interview_style
    }
    if(this.requestForm.value.internalmessage){
      payload['message']=this.requestForm.value.internalmessage;
    }
    if(+this.requestForm.value.userselcted != +this.loggedInUserId){
      payload['requested_by_id'] = this.requestForm.value.userselcted;
    }
    this.requestService.request(payload).subscribe((data: any) => {
      this.isLoading = false;
      this.isCopyCode = true;
      this.requestedUsers = data
      this.checkForSelectedUsers(data);
      this.requestForm.patchValue({
        requestedUser: data[0].url,
        bodyCode: data[0].url
      })
    })
  }

  GenearateExternalMainlLink(){
    this.isLoading = true;
    let payload = {
      author_emails: this.requestForm.controls.email.value ?  [this.requestForm.controls.email.value] : [],
      story_requested: 1,
    }
    if(this.requestForm.controls.firstname.value){
      payload['firstname']=this.requestForm.controls.firstname.value;
    }

    if(this.requestForm.value.campaign == -1 &&  this.initialState.hidetopbar){
      payload['storyPrompt'] = this.initialState.question;
      payload['story_title'] = this.initialState.question;
      payload['story_question_id'] = this.externalquestionId;
    }
    if(this.requestForm.controls.lastname.value){
      payload['lastname']=this.requestForm.controls.lastname.value;
    }
    if(this.requestForm.controls.note_email.value){
      payload['note_email']=this.requestForm.controls.note_email.value;
    }
    if(this.requestForm.value.campaign != -1  && this.initialState.fromCampaign){
      payload["campaign_id"]=this.requestForm.value.campaign;
      payload['interview_style'] = this.initialState.interview_style
    }
    if(this.requestForm.value.message){
      payload['message']=this.requestForm.value.message;
    }
    if(+this.requestForm.value.externalUserSelcted != +this.loggedInUserId){
      payload['requested_by_id'] = this.requestForm.value.externalUserSelcted;
    }

    this.requestService.request(payload).subscribe((data: any) => {
      this.isLoading = false;
      this.requestedUsers = data
      this.checkForExternalSelectedUsers(data);
      this.requestForm.patchValue({
        requestedUser: data[0].url,
        bodyCode: data[0].url
      })
    })
  }


  private checkForExternalSelectedUsers(data){
    var tempDatastorage = []
    this.requestForm.controls.firstname.disable();
    this.requestForm.controls.lastname.disable();
    this.requestForm.controls.email.disable();
    if(data.length == 1){
      this.isSingleInternalRequested = true;
      this.hideSendanCopylinkButton = true;
       this.externalUrlforCopy  = data[0].url;
        if(data && data[0].story && data[0].story?.storyid){
          let emailPaylooad = {}
          let emails =  {
            author_email: data[0].email,
            story_id: data[0].story?.id
           }
           this.emailSendIngPayload.push(emails)
        }
        else{
          let emailPayload  =  {
            author_email: this.requestForm.value.email,
            // firstname:this.requestForm.value.firstname,
            // lastname:this.requestForm.value.lastname,
            // campaign_id: this.requestForm.value.campaign,
            // story_requested: 1,
           }
          //  if(this.requestForm.value.about){
          //   emailPayload['story_title'] = this.requestForm.value.about
          //  }
          this.emailSendIngPayload.push(emailPayload)
        }
       
    }


  }

  sendIntenalMainEmail(){
    let emails= {
      author_emails: this.emailSendIngPayload
    }
    this.store$.dispatch(new storyAction.SendEamilInternalExternal(emails));
    this.requestForm.controls.internalmessage.reset();
    this.members = [];
    this.selectedData = [];
    this.emailSendIngPayload = [];
    this.isCopyCode = false;
    this.hideSendanCopylinkButton = false;
    this.requestForm.controls.userStatus.setValue('user');
    this.loadUserInputData.pagination.page = 1;
    this.loadUserInputData.pagination.sort = 'desc'
    if(!this.initialState.fromCampaign){
    this.requestForm.controls.campaign.setValue(-1);
    this.requestForm.controls.question.setValue(-1);
    }
    this.toastService.success('Sent')
    this.loadMembers([]);
  }


  sendExternalMainEmail(){
    let emails= {
      author_emails: this.emailSendIngPayload
    }
    this.store$.dispatch(new storyAction.SendEamilInternalExternal(emails));
    this.requestForm.controls.firstname.reset();
   this.requestForm.controls.lastname.reset();
   this.requestForm.controls.email.reset();
   this.externalUrlforCopy = '';
   this.emailSendIngPayload = [];
   this.isSingleInternalRequested = false;
   this.hideSendanCopylinkButton = false;
  }

  

  Changecampaigns(data){
    if(typeof data === 'string' && data != '-1'){
       console.log(data);
       this.readOnlyforTextfiled = true;
       this.requestForm.controls.question.reset();
       this.requestForm.controls.question.setValue(-1);
       this.requestForm.controls.about.setValue('');
    }
  }

  copyMessage(player, data: any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = player.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    $(data).attr('title', 'Copied');

    player.linkCopied = true;
    setTimeout(() => {
      $(data).attr('title', 'Click to copy');
      player.linkCopied = false;
    }, 1000)
  }

  copyExternalUrl() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.externalUrlforCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.buttonText = 'Copied'
    this.toastService.success("Copied");
    setTimeout(() => {
      this.buttonText = 'Copy Link'
    }, 1000)
  }
  shareadthis(){
    this.isDispalyadthis = true;
    setTimeout(()=>{
      if (typeof (<any>window).shareholic !== 'undefined') { (<any>window).shareholic.layers.refresh(); }
      this.shareholic.nativeElement.setAttribute("data-link", this.externalUrlforCopy);
    },1000)
  }
  closeshare(){
    this.isDispalyadthis = false;
  }
}

