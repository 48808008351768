import { Component, Inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ComponentBase } from '@framework/base/component/component.base';
import { CampaignViewModel, CompanyViewModel, SearchBoxStateViewModel } from '@modules/company/models/store-view-models';
import { CoreState } from '@app/store/core/states';
import { campaignSelectors, companySelectors, searchBoxSelectors } from '@modules/company/store/selectors';
import { GsMasonryComponent } from '@modules/shared/components/layout/gs-masonry';
import { ShareModalContainerComponent } from '@modules/shared/components/commons/share-modal-container';

import { StoryViewModel } from '@modules/story/models/view-models';
import { LoadStatusModel } from '@modules/shared/models';
import { ImageCloudinaryPipe } from '@modules/shared/pipes';
import { storySelector } from '@modules/story/store/selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { campaignActions, companyActions, searchBoxActions } from '@modules/company/store/actions';
import { ModalService } from '@framework/services';
import { storyActions,storyStepActions } from '@modules/story/store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { SearchStoryInputApiModel } from '@modules/story/models/api-models/input';
import * as adminStoryActions from '@modules/company-admin/store/actions';

@Component({
  selector: 'co-pb-cp-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent extends ComponentBase {
  @ViewChild(GsMasonryComponent)
  gsMasonryComponent: GsMasonryComponent;

  @Input() showSubmitStory:boolean = true;

  @Input() txtsubmit: any = '';
  @Input() btnsubmit:any = '';
  // Private variables
  public showIndicator: boolean = false;
  public disabled: boolean = false;
  public userLables:any;
  public GuestLables:any;
  public loadColorSuccessSubscription: Subscription;
  private selectStories$: Observable<StoryViewModel[]>;
  private selectLoadStoryStatus$: Observable<LoadStatusModel>;
  private selectCampaignPageDetail$: Observable<CampaignViewModel>;

  private selectCampaignPageDetailSubscription: Subscription;
  private selectSearchBoxStatusSubscription: Subscription;
  private selectStoriesSubscription: Subscription;
  private selectLoadStoryStatusSubscription: Subscription;
  public queryStoriesData: SearchStoryInputApiModel = new SearchStoryInputApiModel();
  private selectSearchBoxStatus$: Observable<SearchBoxStateViewModel>;
  // Public variables
  public stories: StoryViewModel[] = [];
  public isViewList: boolean = false;
  public loadStoryStatus: LoadStatusModel;
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  public campaignPage: CampaignViewModel;
  private campaignSlug: string = '';
  private selectCompany$: Observable<CompanyViewModel>;
  private selectCompanySubscription: Subscription;
  public selectedCompany: CompanyViewModel;
  private loadStepSuccessSubscription: Subscription;
  public searchBoxStatus: SearchBoxStateViewModel;
  public stepsData: any;
  public isFinishedBoolean: boolean = false;

  constructor(private actions$: Actions, private modalService: ModalService, private activatedRoute: ActivatedRoute, private store$: Store<CoreState>, public imageCloudinaryPipe: ImageCloudinaryPipe,
  private router: Router) {
    super();
    this.queryStoriesData.pagination.sort = 'desc';
    this.selectStories$ = this.store$.select(storySelector.selectAll);
    this.selectLoadStoryStatus$ = this.store$.select(storySelector.selectLoadStatus);
    this.selectCampaignPageDetail$ = this.store$.select(campaignSelectors.selectCampaign);
    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectSearchBoxStatus$ = this.store$.select(searchBoxSelectors.selectSearchBoxStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.captureParams();
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectCompanySubscription = this.selectCompany$.subscribe((company) => {
      if (company) {
        this.selectedCompany = company;
        this.store$.dispatch(new storyStepActions.Load(this.selectedCompany.id));
        this.store$.dispatch(new adminStoryActions.storyActions.GetColor(this.selectedCompany?.id));
        this.loadDetail();
      }
    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.storyActions.GetColorSuccess>(adminStoryActions.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.userLables = action.response.find(item => item.button == 1).color;
        this.GuestLables = action.response.find(item => item.button == 2).color;
        // this.boxshadowcolr = '0 -0.1875rem 0'+action.response.find(item => item.button == 6).color+'inset;'
       }
      

    })

    this.loadStepSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.LoadSuccess>(storyStepActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((result) => {
      if (result.data) {
        this.stepsData = result.data;
      }
    });

    this.selectStoriesSubscription = this.selectStories$.subscribe((stories) => {
      this.stories = stories || [];
      this.showIndicator = false;
      setTimeout(() => {
        this.reloadMansonry();
      }, 1000);
    });

    this.selectLoadStoryStatusSubscription = this.selectLoadStoryStatus$.subscribe((loadStoryStatus) => {
      this.loadStoryStatus = loadStoryStatus;
      if (loadStoryStatus) {
        if (loadStoryStatus.isLoaded && loadStoryStatus.isCanLoadMore) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      }
    });

    this.selectCampaignPageDetailSubscription = this.selectCampaignPageDetail$.subscribe((campaignPage) => {
      if (campaignPage) {
        var  currentDate = new Date()
        this.campaignPage = campaignPage;
        this.isFinishedBoolean =  currentDate <= new Date(campaignPage.end_date);
      }
    });
    this.selectSearchBoxStatusSubscription = this.selectSearchBoxStatus$.subscribe((state: SearchBoxStateViewModel) => {
      this.searchBoxStatus = state;
    });

  }

  private unsubscribe() {
    this.selectCompanySubscription.unsubscribe();
    this.selectStoriesSubscription.unsubscribe();
    this.selectLoadStoryStatusSubscription.unsubscribe();
    this.selectCampaignPageDetailSubscription.unsubscribe();
    this.loadStepSuccessSubscription.unsubscribe();
    this.store$.dispatch(new storyActions.ResetState());
  }

  private captureParams() {
    this.campaignSlug = this.activatedRoute.snapshot.params['pageSlug'];
    const companySlug = this.activatedRoute.snapshot.params['slug'];
    this.store$.dispatch(new companyActions.LoadPublicInfo(companySlug));
  }

  private loadDetail() {
    this.store$.dispatch(new campaignActions.LoadDetail(this.selectedCompany.id, this.campaignSlug));
  }

  private reloadMansonry() {
    this.gsMasonryComponent.reloadLayout();
  }
  // Public functions
  public trackByFunc(story: StoryViewModel) {
    return story.id;
  }

  get storyPrompt() {
    if (this.campaignPage && this.campaignPage.storyPrompt) {
      // return btoa(unescape(encodeURIComponent(this.campaignPage.storyPrompt)));
    } else {
      return null;
    }
  }

  public share() {
    this.modalService.showModal(ShareModalContainerComponent, window.location.href).then(() => { });
  }

  public closeSearchBox() {
    this.store$.dispatch(new searchBoxActions.Hide());
  }

  public showSearchBox() {
    this.store$.dispatch(new searchBoxActions.Show());
  }

  public changeSortType() {
    if (this.queryStoriesData.pagination.sort === 'desc') {
      this.sort('asc');
    } else {
      this.sort('desc');
    }
  }

  private sort(sortOrder: string) {
    this.queryStoriesData.pagination.sort = sortOrder;
    this.queryStoriesData.pagination.page = 1;
    this.store$.dispatch(new searchBoxActions.Search(
      {
        pagination: this.queryStoriesData.pagination
      }
    ));
  }

  public loadMoreStories() {
    if (this.loadStoryStatus.isLoaded && this.loadStoryStatus.isCanLoadMore) {
      this.showIndicator = true;
      this.queryStoriesData.pagination.page += 1;
      this.store$.dispatch(new searchBoxActions.Search(
        {
          pagination: this.queryStoriesData.pagination
        }
      ));
    }
  }

  navgateToInterviewStyle(){
    let url  = 'interview/inbound/campaign/create/' + this.campaignPage.companySlug + '/' + this.campaignPage.slug
    this.router.navigate([url])
   }


  public changeViewType() {
    this.isViewList = !this.isViewList;
    this.reloadMansonry();
  }

}

