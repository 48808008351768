import { Action } from '@ngrx/store';


export enum ActionTypes {
    GET_APPROVAL_LIST_USER = '[Approve] Get Approval for user',
    GET_APPROVAL_LIST_USER_SUCCESS = '[Approve] Get Approval for user Success',
    GET_APPROVAL_LIST_USER_FAIL = '[Approve] Get Approval for user Fail',

    GET_IS_APPROVAL_LIST = '[Approve] Get is Approval for user',
    GET_IS_APPROVAL_LIST_SUCCESS = '[Approve] Get is Approval for user Success',
    GET_IS_APPROVAL_LIST_FAIL = '[Approve] Get is Approval for user Fail',

    GET_APPROVAL_STORY_USER_LIST = '[Approve] Get Approval for each story',
    GET_APPROVAL_STORY_USER_LIST_SUCCESS = '[Approve] Get Approval for each story success',
    GET_APPROVAL_STORY_USER_LIST_FAIL = '[Approve] Get Approval for each story fail',

    APPROVE_REJECT_STEP = '[Approve] Approve Step',
    APPROVE_REJECT_STEP_SUCCESS = '[Approve] Approve Step Success',
    APPROVE_REJECT_STEP_FAIL = '[Approve] Approve Step Fail',

    CREATE_STEP = '[Approve] Create Step',
    CREATE_STEP_SUCCESS = '[Approve] Create Step Success',
    CREATE_STEP_FAIL = '[Approve] Create Step Fail',

    DELETE_STEP = '[Approve] Delete Step',
    DELETE_STEP_SUCCESS = '[Approve] Delete Step Success',
    DELETE_STEP_FAIL = '[Approve] Delete Step Fail',

    GET_STEP = '[Approve] Get Step',
    GET_STEP_SUCCESS = '[Approve] Get Step Success',
    GET_STEP_FAIL = '[Approve] Get Step Fail',

    REJECT_APPROVED_STORY = '[Approve] Reject Approved Story',
    REJECT_APPROVED_STORY_SUCCESS = '[Approve] Reject Approved Story Success',
    REJECT_APPROVED_STORY_FAIL = '[Approve] Reject Approved Story Fail',

    USER_LIST = '[Approve] Get User List',
    USER_LIST_SUCCESS = '[Approve] Get User List Success',
    USER_LIST_FAIL = '[Approve] Get User List Fail',

    CREATE_COMMENT = '[Approve] Create Comment',
    CREATE_COMMENT_SUCCESS = '[Approve] Create Comment Success',
    CREATE_COMMENT_FAIL = '[Approve] Create Comment Fail',

    UPDATE_COMMENT = '[Approve] Update Comment',
    UPDATE_COMMENT_SUCCESS = '[Approve] Update Comment Success',
    UPDATE_COMMENT_FAIL = '[Approve] Update Comment Fail',

    DELETE_COMMENT = '[Approve] Delete Comment',
    DELETE_COMMENT_SUCCESS = '[Approve] Delete Comment Success',
    DELETE_COMMENT_FAIL = '[Approve] Delete Comment Fail',

    GET_COMMENT = '[Approve]  Get Comment',
    GET_COMMENT_SUCCESS = '[Approve]  Get Comment Succcess',
    GET_COMMENT_FAIL = '[Approve]  Get Comment Fail'
}



export class GetListApproval implements Action {
    readonly type = ActionTypes.GET_APPROVAL_LIST_USER;

    constructor(public companyId: number, public query:any ) { }
}

export class GetListApprovalSuccess implements Action {
    readonly type = ActionTypes.GET_APPROVAL_LIST_USER_SUCCESS;

    constructor(public response: any ) { }
}

export class GetListApprovalFail implements Action {
    readonly type = ActionTypes.GET_APPROVAL_LIST_USER_FAIL;
}


export class GetIsApproval implements Action {
    readonly type = ActionTypes.GET_IS_APPROVAL_LIST;

    constructor(public companyId: number, public query:any ) { }
}

export class GetIsApprovalSuccess implements Action {
    readonly type = ActionTypes.GET_IS_APPROVAL_LIST_SUCCESS;

    constructor(public response: any ) { }
}

export class GetIsApprovalFail implements Action {
    readonly type = ActionTypes.GET_IS_APPROVAL_LIST_FAIL;
}

export class GetListApprovalStory implements Action {
    readonly type = ActionTypes.GET_APPROVAL_STORY_USER_LIST;

    constructor(public companyId: number, public storyID:any  ) { }
}

export class GetListApprovalStorySuccess implements Action {
    readonly type = ActionTypes.GET_APPROVAL_STORY_USER_LIST_SUCCESS;

    constructor(public response: any ) { }
}

export class GetListApprovalStoryFail implements Action {
    readonly type = ActionTypes.GET_APPROVAL_STORY_USER_LIST_FAIL;
}

export class ApproveRejectStep implements Action {
    readonly type = ActionTypes.APPROVE_REJECT_STEP;

    constructor(public companyId: number, public responseId:any, public payload:any ) { }
}

export class ApproveRejectStepSuccess implements Action {
    readonly type = ActionTypes.APPROVE_REJECT_STEP_SUCCESS;

}

export class ApproveRejectStepFail implements Action {
    readonly type = ActionTypes.APPROVE_REJECT_STEP_FAIL;

}



export class GetUserList implements Action {
    readonly type = ActionTypes.USER_LIST;
    constructor(public companyId: number) { }
}

export class GetUserListSuccess implements Action {
    readonly type = ActionTypes.USER_LIST_SUCCESS;
    constructor(public response:any) { }
}

export class GetUserListFail implements Action {
    readonly type = ActionTypes.USER_LIST_FAIL;
}


export class CreateComment implements Action {
    readonly type = ActionTypes.CREATE_COMMENT;
    constructor(public companyId: number, public response_Id:any, public payload: any) { }
}

export class CreateCommentSuccess implements Action {
    readonly type = ActionTypes.CREATE_COMMENT_SUCCESS;
}

export class CreateCommentFail implements Action {
    readonly type = ActionTypes.CREATE_COMMENT_FAIL;
}

export class UpdateComment implements Action {
    readonly type = ActionTypes.UPDATE_COMMENT;
    constructor(public companyId: number, public response_Id:any, public coment_Id:any, public payload: any) { }
}

export class UpdateCommentSuccess implements Action {
    readonly type = ActionTypes.UPDATE_COMMENT_SUCCESS;
}

export class UpdateCommentFail implements Action {
    readonly type = ActionTypes.UPDATE_COMMENT_FAIL;;
}

export class DeleteComment implements Action {
    readonly type = ActionTypes.DELETE_COMMENT;
    constructor(public compnay_Id:any, public response_Id:any, public comment_Id:any) { }
}

export class DeleteCommentSuccess implements Action {
    readonly type = ActionTypes.DELETE_COMMENT_SUCCESS;
}

export class DeleteCommentFail implements Action {
    readonly type = ActionTypes.DELETE_COMMENT_FAIL;
}

export class GetComment implements Action {
    readonly type = ActionTypes.GET_COMMENT;
    constructor(public compnay_Id:any, public response_Id:any) { }
}


export class GetCommentSuccess implements Action {
    readonly type = ActionTypes.GET_COMMENT_SUCCESS;
    constructor(public response:any) { }
}

export class GetCommentFail implements Action {
    readonly type = ActionTypes.GET_COMMENT_FAIL;
}

export class CreateStep implements Action {
    readonly type = ActionTypes.CREATE_STEP;
    constructor(public compnay_Id:any, public campaign_id:any, public payload:any) { }

}

export class CreateStepSucces implements Action {
    readonly type = ActionTypes.CREATE_STEP_SUCCESS;
}

export class CreateStepFail implements Action {
    readonly type = ActionTypes.CREATE_STEP_FAIL;
}


export class DeleteStep implements Action {
    readonly type = ActionTypes.DELETE_STEP;
    constructor(public compnay_Id:any, public campaign_id:any, public steps_id) { }

}

export class DeleteStepSuccess implements Action {
    readonly type = ActionTypes.DELETE_STEP_SUCCESS;


}

export class DeleteStepFail implements Action {
    readonly type = ActionTypes.DELETE_STEP_FAIL;

}


export class Getstep implements Action {
    readonly type = ActionTypes.GET_STEP;
    constructor(public compnay_Id:any, public campaign_id:any) { }

}

export class GetstepSuccess implements Action {
    readonly type = ActionTypes.GET_STEP_SUCCESS;
    constructor(public response:any) { }


}

export class GetStepFail implements Action {
    readonly type = ActionTypes.GET_STEP_FAIL;

}


export class RejectApprovedStory implements Action {
    readonly type = ActionTypes.REJECT_APPROVED_STORY;
    constructor(public compnay_Id:any, public storyId:any) { }

}

export class RejectApprovedStorySuccess implements Action {
    readonly type = ActionTypes.REJECT_APPROVED_STORY_SUCCESS;
}

export class RejectApprovedStoryFail implements Action {
    readonly type = ActionTypes.REJECT_APPROVED_STORY_FAIL;

}




export type Actions =
GetListApproval | GetListApprovalSuccess | GetListApprovalFail
| CreateStep | CreateStepSucces | CreateStepFail
| GetComment | GetCommentSuccess | GetCommentFail
| GetStepFail | Getstep | GetstepSuccess
| DeleteStep | DeleteStepSuccess | DeleteStepFail
| DeleteComment | DeleteCommentSuccess | DeleteCommentFail
| UpdateComment | UpdateCommentSuccess | UpdateCommentFail
| CreateComment | CreateCommentSuccess | CreateCommentFail
| ApproveRejectStep | ApproveRejectStepSuccess | ApproveRejectStepFail
| GetUserList | GetUserListSuccess | GetUserListFail 
| GetListApprovalStory | GetListApprovalStorySuccess | GetListApprovalStoryFail
| GetIsApproval | GetIsApprovalSuccess | GetIsApprovalFail
| RejectApprovedStory | RejectApprovedStorySuccess | RejectApprovedStoryFail