<!-- Author Avatar -->
<gs-avatar
  [hidden]="statusDetail.isLoading"
  [src]="removeUrl(storyDetail?.author?.avatar)"
  classCss="avatar"
  aria-hidden="true"
></gs-avatar>

<!-- Author Name and Information -->
<div class="name">
  <span
    [innerHtml]="authorInfo"
    [hidden]="statusDetail.isLoading"
    tabindex="0"
    aria-label="Author Information"
  ></span>
  <br />
  <span *ngIf="storyDetail?.publishedAt">
    <span
      style="color: #c1c1c1; font-weight: 100; font-size: 0.875rem;"
      [innerHtml]="storyDetail.publishedAt | prettyDate"
      [attr.aria-label]="'Published Date:' + (storyDetail.publishedAt | prettyDate)"
    ></span>
  </span>
</div>

<!-- Loading Skeleton -->
<skeleton-pb-author-informations
  [hidden]="statusDetail.isLoaded"
  role="status"
  aria-live="polite"
  tabindex="0"
  aria-label="Loading author information"
></skeleton-pb-author-informations>
