import { Injectable, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { MetaTagConfig } from '../models/common/seo.model';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SeoService {

    constructor(
        @Inject(DOCUMENT) private dom,
        private meta: Meta,
        private title: Title
    ) { }

    setCanonicalURL(url?: string) {
        const canURL = url == undefined ? this.dom.URL : url;
        const link: HTMLLinkElement = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
        link.setAttribute('href', canURL);
    }

    /**
     * This method is to update meta tags
     * @param config - contains meta tags information
     */
    updateMetaTags(config: MetaTagConfig) {
        if (config) {
            const title = config.title;
            const url = config.url;
            const description = this.stripHtmlTags(config.description);
            const image = config.image;
            const keywords = config.keywords;
            const altImage = config.alt
            this.updateData(title, url, description, image, keywords,altImage);
        }
    }

    clearMetaTags() {
        this.updateData('', '', '', '', '','');
    }

    updateData(title: string, url: string, description: string, image: string, keywords: string,altImage: any) {
        this.setCanonicalURL(url);
        this.meta.updateTag({ name: "title", content: title });
        if(description){
            this.meta.updateTag({ name: "description", content: description.trim() });
        }
      
        if (keywords) {
            this.meta.updateTag({ name: "keywords", content: keywords });
        }
        let urlArr = [];
        if (image)
            urlArr = image.split('.');
        if (urlArr.length) {
            const videoExt = ['mp4', 'mpg', 'mov', '3gp', 'avi', 'flv', 'm4v', 'mkv', 'mpeg', 'mts', 'ogv', 'vob', 'webm'];
            if (videoExt.includes(urlArr[urlArr.length - 1])) {
                this.meta.updateTag({ property: "og:video", content: image });
                this.meta.updateTag({ property: "og:video:alt", content: altImage });
                this.meta.updateTag({ property: "og:video:width", content: '1200' });
                this.meta.updateTag({ property: "og:video:height", content: '630' });
            } else {
                this.meta.updateTag({ property: "og:image", content: image });
                this.meta.updateTag({ property: "og:image:alt", content: altImage });
                this.meta.updateTag({ property: "og:image:width", content: '1200' });
                this.meta.updateTag({ property: "og:image:height", content: '630' });
                this.meta.updateTag({ name: "twitter:image", content: image });
                this.meta.updateTag({ name: "twitter:image:alt", content: altImage });
            }
        }

        this.meta.updateTag({ property: "og:url", content: url });
        this.meta.updateTag({ property: "og:title", content: title });
        this.meta.updateTag({ property: "og:description", content: description });
        this.meta.updateTag({ name: "twitter:card", content: "summary_large_image" });
        this.meta.updateTag({ name: "twitter:url", content: url });
        this.meta.updateTag({ name: "twitter:title", content: title });
        this.meta.updateTag({ name: "twitter:description", content: description });
    }

    /**
     * In some cases we get html tags in description string
     * This method is to remove html tags from the string
     * @param str - It contains the string that need to be formatted
     */

    private stripHtmlTags(str: string): string {
        if ((str === null) || (str === undefined) || (str === '')) {
            return;
        }
        return str.replace(/<[^>]*>/g, '');
    }

    private getImageUrl(platform: string, url: string): string {
        if ('twitter' === platform) {
            return `${url.split("?")[0]}`
        } else if ('otherSocial' === platform) {
            return `${url.split("?")[0]}`
        } else {
            return url
        }
    }
}
