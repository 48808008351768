import * as _ from 'lodash';

import { StoryOutputApiModel, StepDataTagApiModel } from '../../models/api-models/output';
import { Constants } from '@modules/shared/constants';

export interface StoriesAuthorEntity {
    authorId: number;
    avatar: string;
    displayName: string;
    title: string;
    relationship: string;
    display_title?:any;
    companyName: string;
    slug: string;
    companySlug: string;
    companyWebsite: string;
}

export interface StoryMediaEntity {
    fullsize: string;
    id: number;
    preview: string;
    type: string;
    saveIn: number
    source?:any;
    thumbnail_url?:any
    asset_id?:any;
    aspect_ratio?:any
    status?:any
}

export interface StoryCtaEntity {
    text: string;
    button: string;
    donate_button_content?:any
    donate_button_script?: any
    donate_button_text?: any;
    show_author_information?: boolean;
    show_jobs?:boolean;
    form_title?:any;
    config: any;
    link: string;
    type: string
    cta_id_company_cta?: any;
}

export interface StoryComment {
    commentId: number;
    content: string;
    playerId: number;
}

export interface StoryCompany {
    companyId: number;
    name: string;
    website: string;
    slug: string;
    logo: string;
}

export interface StoryTagEntity {
    label: string;
    color: string;
}

export interface ContributionDataEntity {
    message: string;
    tags: StoryTagEntity[];
}

export interface StoryContributionEntity {
    id: number;
    avatar: string;
    displayName: string;
    playerId: number;
    data: ContributionDataEntity;
    likeId: number;
    slug: string;
}

export interface HighlightTagsEntity {
    text: string;
    color: string;
    sort: number;
}

export interface StoryListContributorEntity {
    playerId: number;
    displayName: string;
    avatar: string;
}

export interface DataItemApiModel {
    idOrSlug: number | string;
    label: string;
}

export interface StepDataItemEnity {
    title: string;
    type: string;
    color: string;
    sort: number;
    data: DataItemApiModel[];
}

export interface StepDataEntity {
    [key: number]: StepDataItemEnity;
}

export interface StoryEntity {
    isNamePromotable: number;
    total_count: number;
    id: number;
    title: string;
    slug: string;
    content: string;
    contentRaw: string;
    campaignIdSlug: number | string;
    media: StoryMediaEntity[];
    companyIdSlug: number | string;
    public: boolean;
    internal?: boolean;
    approved: boolean;
    completed: boolean;
    createdAt: Date;
    createdBy: number;
    publishedAt: Date;
    publishedBy: number;
    stepsData: StepDataEntity;
    steps: any;
    cta: StoryCtaEntity;
    author: StoriesAuthorEntity;
    authorInboundType: string;
    likesCount: number;
    commentsCount: number;
    likeId: number;
    followId: number;
    viewsCount:number;

    comments: StoryComment[];
    company: StoryCompany;
    status: number;
    type: string;
    contributions: StoryContributionEntity[];
    liked: boolean;
    highlightTags: HighlightTagsEntity[];
    contributors: StoryListContributorEntity[];
    ctaFormConfigs: CtaFormConfigEntity[];

    publicRequested: boolean;
    isNoPermission: boolean;
    prev_story_slug:string;
    next_story_slug:string;

    uniqueId: string;
    internalProfilePublic: boolean;
    externalProfilePublic: boolean;
    externalAnonymize: boolean;
    ctaInteractionsCount: number;
    campaign_id: string | number;
    id_campaign: string | number;
    tagsCountData: TagsCountData[];

    archived: boolean;
    requested_by:any;
    interview_style?:boolean;
    deleted?:any;
    story_prompt?: any;
    published_to_draft?: any;
    question_id_campaign?:any
    is_footnote_included?:boolean
    interview_content?:any;
    thumbnail_url?:any
    default_prompt?:any
    show_prompt?:boolean;
    show_relationship_public?:boolean;
    show_company_public?: boolean;
    show_name_public?: boolean;
    show_title_public?: boolean;
    consent_details?:any;
    approval_flow?:boolean;
    final_approved?:boolean;
}

export interface TagsCountData {
    count: number;
    tagId: number;
}
export interface CtaFormConfigEntity {
    title: string;
    link: string;
    text: string;
    isRedirection: boolean;
    isRequiredInfo: boolean;
    thankYouText: string;
    is_enabled?:boolean;
}


export class StoryEntity {
    constructor(data?: StoryOutputApiModel,count?:any) {
        if (data)
            this.convertFromApi(data,count);
    }

    private convertFromApi(data: StoryOutputApiModel,count?: any) {
        let campaign_Ids= [1841,1968,1963, 1962,1961,1960,1891,1842,1833,1833,1769]
        this.isNamePromotable = data.is_name_promotable;
        this.total_count = count
        this.deleted = data.deleted
        this.is_footnote_included = data.is_footnote_included
        this.id = data.id;
        this.requested_by = data?.requested_by ? data?.requested_by : null;
        this.interview_style = data?.interview_style
        this.default_prompt = data?.default_prompt
        this.thumbnail_url = data?.thumbnail_url
        this.show_prompt = data?.show_prompt == 1 ? true : false
        this.title = data.title;
        this.slug = data.slug;
        this.content = data.content;
        this.prev_story_slug = data.prev_story_slug
        this.next_story_slug = data.next_story_slug
        this.interview_content = data?.interview_content
        this.contentRaw = data.content_raw;
        this.viewsCount = data?.views_count
        this.campaignIdSlug = data.campaign_id_slug;
        this.story_prompt = data?.story_prompt;
        this.question_id_campaign = data?.question_id_campaign;
        this.show_company_public = data?.show_company_public;
        this.show_relationship_public = data?.show_relationship_public;
        this.show_name_public = data?.show_name_public;
        this.show_title_public = data?.show_title_public
        this.consent_details = data?.consent_details
        this.approval_flow = data?.approval_flow
        this.final_approved = data?.final_approved
        this.tagsCountData = data.tags_count_data ? data.tags_count_data.map(data => { return { tagId: data.tag_id, count: data.count } }) : []
        if (data.media && data.media.length  && data.media[0] != null) {
           this.media = data.media.map((item)=>{
            return {
                id: item.id,
                fullsize: item.fullsize,
                preview: item.preview,
                saveIn: item.save_in,
                type: item.type,
                source: item.source,
                thumbnail_url:item.thumbnail_url,
                aspect_ratio:item.aspect_ratio,
                asset_id:item.asset_id,
                status:item.status
            }


           })
        }
        else
        {
            this.media = [];
        }
        if(data?.published_to_draft){
            this.published_to_draft = data?.published_to_draft
        }
        this.companyIdSlug = data.company_id_slug;
        this.public = data.public;
        this.internal = data.internal;
        this.approved = data.approved;
        this.completed = data.completed;
        this.createdAt = data.time_created;
        this.publishedAt = data.published_at;
        this.createdBy = data.created_by;
        this.publishedBy = data.published_by;
        if (data.steps_data) {
            this.stepsData = {};
            _.forEach(data.steps_data, (value, prop) => {
                if (value.type === Constants.stepType.tag) {
                    this.stepsData[prop] = {
                        title: value.title,
                        type: value.type,
                        color: value.color,
                        sort: value.sort
                    }
                    this.stepsData[prop].data = value.data.map((item: StepDataTagApiModel) => {
                        return { idOrSlug: item.id, label: item.name };
                    });
                }
            })
        }
        this.cta = data.cta;
        if(this.cta && this.cta?.donate_button_content){
            this.cta.donate_button_content = data.cta.donate_button_content;
            this.cta.donate_button_text = data.cta.donate_button_text;
            this.cta.show_author_information = data.cta?.show_author_information;
            this.cta.donate_button_script = data.cta?.donate_button_script;
        }

        if(this.cta && this.cta?.type == 'simple'){
            this.cta.show_author_information = data.cta?.show_author_information;
        }

        // this.cta.show_jobs = data.cta?.show_jobs
        this.likesCount = data.likes_count;
        this.commentsCount = data.comments_count;
        this.liked = data.liked;
        if (data.comments) {
            this.comments = data.comments.map(item => {
                let newStoryComment: StoryComment = {
                    commentId: item.comment_id,
                    content: item.content,
                    playerId: item.player_id
                };
                return newStoryComment;
            });
        }
        if (data.company) {
            this.company = {
                companyId: data.company.company_id,
                name: data.company.name,
                website: data.company.website,
                slug: _.isString(data.company_id_slug) ? data.company_id_slug : '',
                logo: data.company.logo
            }
        }
        if (data.status) this.status = data.status;
        if (!data.status) this.status = data.status
        if (data.type) this.type = data.type;
        if (data.author) {
            this.author = {
                authorId: data.author.author_id,
                avatar: data.author.avatar,
                displayName: data.author.display_name,
                title: data.author.title,
                relationship: data.author.relationship,
                companyName: data.author.company_name,
                slug: data.author.slug,
                companySlug: data.author.company_slug,
                companyWebsite: data.author.company_website,
                display_title: data.author.display_title ? data.author.display_title : ''
            };
        }
        if ( data.campaign_id && campaign_Ids.includes(+data.campaign_id)) {
            if(data.author_inbound_type){
                this.authorInboundType = '';
            }
            else{
                this.authorInboundType = '';
            }  
        }
        else{
            if(data.author_inbound_type){
                this.authorInboundType = data.author_inbound_type;
            }
            else{
                this.authorInboundType = '';
            } 
        }
        
        if (data.contributions) {
            this.contributions = data.contributions.map(item => {
                let newContributor: StoryContributionEntity = {
                    id: item.id,
                    avatar: item.avatar,
                    displayName: item.display_name,
                    playerId: item.player_id,
                    data: null,
                    likeId: item.like_id,
                    slug: item.slug
                };

                if (item.data) {
                    let tags: StoryTagEntity[] = [];
                    _.forEach(item.data, (value, key) => {
                        if (value.type === Constants.stepType.tag) {
                            value.data.forEach((tagItem) => {
                                tags.push(
                                    {
                                        label: tagItem.name,
                                        color: value.color
                                    }
                                )
                            });
                        }
                    });
                    newContributor.data = {
                        message: item.data[Constants.storyStep.text] && item.data[Constants.storyStep.text].data,
                        tags: tags
                    }
                }

                return newContributor;
            })
        }
        this.highlightTags = data.highlight_tags;
        if (data.contributors) {
            this.contributors = data.contributors.map(contr => {
                return {
                    playerId: contr.player_id,
                    displayName: contr.display_name,
                    avatar: contr.avatar
                };
            });
        }
        this.likeId = data.like_id;
        this.followId = data.follow_id;
        if (data.cta_form_configs) {
            this.ctaFormConfigs = data.cta_form_configs.map((item) => {
                return {
                    title: item.title,
                    link: item.link,
                    text: item.text,
                    isRedirection: item.is_redirection,
                    isRequiredInfo: item.is_required_info,
                    thankYouText: item.thank_you_text,
                    is_enabled: item?.is_enabled
                };
            });
        }
        this.steps = data.steps;
        this.isNoPermission = data.permission === false;
        this.publicRequested = data.public_requested;

        this.uniqueId = data.unique_id;
        this.internalProfilePublic = data.internal_profile_public;
        this.externalProfilePublic = data.external_profile_public;
        this.externalAnonymize = data.external_anonymize;
        this.ctaInteractionsCount = data.cta_interactions_count;
        this.campaign_id = data.campaign_id;
        this.id_campaign = data.id_campaign;

        this.archived = data.archived;
    }
}
