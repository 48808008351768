import {Component, ElementRef, Inject, Input, SimpleChanges, ViewChild} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { StoryViewModel } from '@modules/story/models/view-models';
import { companySelectors } from '@modules/company/store/selectors';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { searchBoxActions } from '@modules/company/store/actions';
import { ShareModalContainerComponent } from '@modules/shared/components/commons/share-modal-container';
import {ModalService, SocialSharingService} from '@framework/services';
import {EmbedIframeContainerComponent} from "@modules/story/pages/private/components";
import { map, filter } from 'rxjs/operators';
import { storySelector } from "@modules/story/store/selectors";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {CompanyHttpService} from "@modules/company/services";
import { SearchStoryInputApiModel } from '@modules/story/models/api-models/input';

@Component({
  selector: 'co-pub-landing-toolbar',
  templateUrl: './landing-toolbar.component.html',
  styleUrls: ['./landing-toolbar.component.scss']
})
export class LandingToolbarComponent extends ComponentBase {
  @ViewChild("addthis") addthis: ElementRef;
  // Private variables
  @Input() landingPage:any
  private selectSelectedCompany$: Observable<CompanyViewModel>;

  private selectSelectedCompanySubscription: Subscription;
  private _selectQueryDataSubscription: Subscription;
  
  public queryData: any;
  // Public variables
  public storyDetail: StoryViewModel;
  public isLoading: boolean = true;
  public selectedCompany: CompanyViewModel;
  public shareForm: UntypedFormGroup;
  private shareUrl: string;

  constructor(
    private store$: Store<CoreState>,
    private modalService: ModalService,
    private socialSharingService: SocialSharingService,
    private formBuilder: UntypedFormBuilder
  ) {
    super();

    this.selectSelectedCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.initForm();
  }
  private initForm() {
    this.shareForm = this.formBuilder.group({
      link: [this.shareUrl]
    });
  }
  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {
    if(typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectSelectedCompanySubscription = this.selectSelectedCompany$.subscribe((selectedCompany) => {
      if (selectedCompany) {
        this.selectedCompany = selectedCompany;
        this.isLoading = false;
      }
      // this.addthis.nativeElement.setAttribute("data-url", this.shareUrl);
      if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
      this.shareForm.controls.link.setValue(this.shareUrl);
    });

    this._selectQueryDataSubscription = this.store$
      .pipe(
        map(state => storySelector.selectQueryData(state)),
        filter(val => val !== null)
      )
      .subscribe(resQueryData => {
        this.queryData = resQueryData;
      });
  }

  private unsubscribe() {
    this.selectSelectedCompanySubscription.unsubscribe();
    this._selectQueryDataSubscription.unsubscribe();
  }

  // Public functions
  public share() {
    let query = this.getQuery();
    let publicUrl  = `${window.location.origin}/api/v3/companies/${this.selectedCompany?.id}/stories/?${query}&public=1&rss=1`
    localStorage.setItem('feed_rss',publicUrl)
    this.modalService
        .showModal(ShareModalContainerComponent, {})
        .then(() => {
          localStorage.removeItem('feed_rss')
        });
  }


    getQuery(){
      let query;
      var landingPageQueryObjects:SearchStoryInputApiModel;
      landingPageQueryObjects = Object.assign(new SearchStoryInputApiModel(), this.landingPage?.queryObjects?.queryStories);
      landingPageQueryObjects.pagination = this.queryData.pagination;
      var queryStoriesData = Object.assign(new SearchStoryInputApiModel(), landingPageQueryObjects);
      query = queryStoriesData.query;
      return query;
    
    }
}
