import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UploaderConfig, Callback } from '@framework/models';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime, retry } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { ToastService, UploaderService } from '@framework/services';
import { ofType, Actions } from '@ngrx/effects';
import { storyActions } from '@modules/story/store/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { AuthService } from '@framework/services';
import * as adminStoryActions from '@modules/company-admin/store/actions';
import { CreateMediaInputApiModel } from '@app/store/http/models/input';
import * as filestack from 'filestack-js';
import { UtilityHelper } from '@framework/utilities';
import { UpdateTestimonialAudienceSettingInputApiModel } from '@modules/company-admin/store/models/api-models/input';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'edit-guest-labels',
  templateUrl: './edit-guest-operation.component.html',
  styleUrls: ['./edit-guest-operation.component.scss'],
})

export class EditGuestLabelsComponent extends ComponentModalBase<any> {

  public copyForm: UntypedFormGroup;
  public author_id:any;
  public story_details?:any;
  public show_toggle:boolean = false;
  public testimonoialData:any = [];
  private addSuccessSubscription: Subscription;
  private updateSuccessSubscription: Subscription;
  private addFailureSubscription: Subscription;
  private AddSucessSubscription: Subscription;
  public primary_color:any;
  public toggleColor:any;
  public hideVisiblity:boolean = true;
  public lableAddDetails:any;
  private updateFailureSubscription: Subscription;
  private loadDetailSuccess: Subscription;
  private LoadAudienceAction: Subscription;
  public disabled:boolean = false
  public authorDataRelationShip:any;
  private loadColorSuccessSubscription: Subscription;
  public showError:any;
  public consent_details:any;
  public template:any;
  public signatureImg:any;
  public nameOfData:any;
  private uploader: any;
  public primaryColor:any;
  public showLoader:boolean = false;
  public relationshipStatus:any;
  public isUpdating: boolean = false;
  public uploadedUrl: any;
  public uploaderViewModel: any = {
    errorMsg: 'An error occurred while uploading.',
    isError: false,
    progress: 0,
    progressCss: '0%',
    isUploading: false
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private actions$: Actions,
    private utility: UtilityHelper,
    private store$: Store<CoreState>,
    private http: HttpClient,
    private uploaderService: UploaderService,
    private toastService: ToastService,
    private authService: AuthService,
    private rssFeedHttpService: RssFeedHttpService
  ) {
    super();
    this.initForm();

  }

  // Life cycle hook
  protected onInit(): void {

    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })

    // this.store$.dispatch(new adminStoryActions.testimonialAudienceSettingActions.Load(this.initialState.companyId));
    setTimeout(()=>{
this.initUploader();
    },1000)
    this.store$.dispatch(new storyActions.LoadDetail(this.initialState.storyId));
    this.store$.dispatch(new adminStoryActions.storyActions.GetColor(this.initialState?.companyId));

    this.addSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.testimonialAudienceSettingActions.AddSuccess>(adminStoryActions.testimonialAudienceSettingActions.ActionTypes.ADD_SUCCESS)
    ).subscribe((action) => {
      if(action.response?.id){
        console.log(action.response )
        let obj = {id:action.response?.id,name:action.response?.name}
      this.testimonoialData.push(obj)
      this.copyForm.controls.selctedId.setValue(action.response?.id);

      }

    });

    this.addFailureSubscription = this.actions$.pipe(
      ofType<adminStoryActions.testimonialAudienceSettingActions.AddFailure>(adminStoryActions.testimonialAudienceSettingActions.ActionTypes.ADD_FAIL)
    ).subscribe(() => {
      this.isUpdating = false;
    });



    this.updateSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.testimonialAudienceSettingActions.UpdateSuccess>(adminStoryActions.testimonialAudienceSettingActions.ActionTypes.UPDATE_SUCCESS)
    ).subscribe(() => {
      this.store$.dispatch(new adminStoryActions.testimonialAudienceSettingActions.Load(this.initialState.companyId));
      this.isUpdating = false;
      this.onClose.emit(true);
    });

    this.updateFailureSubscription = this.actions$.pipe(
      ofType<adminStoryActions.testimonialAudienceSettingActions.UpdateFailure>(adminStoryActions.testimonialAudienceSettingActions.ActionTypes.UPDATE_FAIL)
    ).subscribe(() => {
      this.isUpdating = false;
    });
    this.loadDetailSuccess = this.actions$.pipe(
      ofType<storyActions.LoadDetailSuccess>(storyActions.ActionTypes.LOAD_DETAIL_SUCCESS)
    ).subscribe(action => {
     
      if(action && action?.data){
        this.story_details = action?.data;
        console.log(">>Cosnole",this.story_details)
        this.consent_details = action?.data?.consent_details?.esignature ? action?.data?.consent_details : null;
       this.author_id = action?.data?.author?.author_id
       this
        this.copyForm.patchValue({
          companyname:action?.data?.author?.company_name,
          title:action?.data?.author?.title,
          name:action?.data?.author?.display_name,
          show_prompt:action?.data?.show_prompt,
        })

        if(this.initialState.inbound){
          this.copyForm.patchValue({
            allowrelationsshipPublic:action?.data?.is_relationship_public,
            allowShowTitle:action?.data?.is_title_public,
            allowShowName:action?.data?.is_name_public,
            allowShowComapnyName:action?.data?.is_company_public,
            show_relationship_public:action?.data?.show_relationship_public,
            show_company_public:action?.data?.show_company_public,
            show_name_public:action?.data?.show_name_public,
            show_title_public:action?.data?.show_title_public
          })
      
              this.changeActionEvent(null);

        }
        else{
          this.copyForm.controls.Label.enable();
        }



        if(!this.initialState.campaignId || this.initialState.campaignId == null ||  this.initialState.campaignId.toString() == '-1' ){
          this.show_toggle = true;
        }
        else{
          this.checkCampaign();
        }

        if(action?.data?.author?.avatar){
          this.copyForm.controls.avatarImage.setValue(action?.data?.author?.avatar);
        }
        this.authorDataRelationShip = action?.data?.author?.relationship ? action?.data?.author?.relationship.toLowerCase() : null
        this.store$.dispatch(new adminStoryActions.testimonialAudienceSettingActions.Load(this.initialState.companyId));
                // this.showLoader = false;
      }
      else{
        // this.showLoader = false;
        this.store$.dispatch(new adminStoryActions.testimonialAudienceSettingActions.Load(this.initialState.companyId));
      }


    })

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.storyActions.GetColorSuccess>(adminStoryActions.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primary_color = action.response.find(item => item.button == 11).color;
        this.toggleColor = action.response.find(item => item.button == 4).color
        // this.boxshadowcolr = '0 -0.1875rem 0'+action.response.find(item => item.button == 6).color+'inset;'
       }
      

    })

 


    this.LoadAudienceAction = this.actions$.pipe(
      ofType<adminStoryActions.testimonialAudienceSettingActions.LoadSuccess>(adminStoryActions.testimonialAudienceSettingActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((action:any) => {
if(action){
  if(action.response){
  this.testimonoialData = action.response;
  this.nameOfData = action.response.map(item => item.name.toLowerCase())
  // this.store$.dispatch(new storyActions.LoadDetail(this.initialState.storyId));
  if(this.authorDataRelationShip){
    let data = this.testimonoialData.find(item => item.name.toLowerCase() == this.authorDataRelationShip)
    if(data){
        this.copyForm.controls.selctedId.setValue(data?.id)
    }
  }
  }


 
}
    });

  }
  protected onDestroy(): void {
    this.addSuccessSubscription.unsubscribe();

    this.updateSuccessSubscription.unsubscribe();
    this.addFailureSubscription.unsubscribe();
    this.updateFailureSubscription.unsubscribe()
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();
  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {

    this.copyForm = this.formBuilder.group({
      selctedId:[''],
      companyname:[{ value: '', disabled: true }],
      show_prompt:[true],
      showname:[false],
      show_relationship_public:[false],
      show_company_public:[false],
      show_name_public:[false],
      show_title_public:[false],
      title:[{ value: '', disabled: true }],
      name:[{ value: '', disabled: true}],
      avatarMedia: [],
      avatarImage: [],
      allowShowTitle:[false],
      allowShowName:[false],
      allowShowComapnyName:[false],
      allowrelationsshipPublic:[false],
      Label:[{value: '', disabled: true }]

    })
  }

  public checkCampaign(){
    let serviceUrl = `${environment.endpointUrl}${'/companies'}`;
    this.http.get(`${serviceUrl}/${this.initialState.companyId}/campaigns/${this.initialState.campaignId}/`, {
      headers: { 'Cache-Control': 'no-cache, no-store', 'Pragma': 'no-cache' },
      params: { timestamp: Date.now().toString() }
    }).subscribe((campaign:any) => {
      if(campaign){
        if(campaign && campaign?.show_story_prompt ){
           this.show_toggle = true; 
        }
        else{
          this.show_toggle = false; 
        }
      }

    })
  }
  private initUploader() {
    let config = new UploaderConfig();
    // config.button = this.browseFileButton.toArray()[0].nativeElement;
    config.companyId = this.initialState.companyId;
    config.isAnonymous = this.initialState.inbound;
    config.validate = {
      allowedExtensions: ['gif', 'jpeg', 'jpg',  'webp','heic']
    };
    config.messages = {
      imageDimensionError: 'Use a larger image - 640 x 480px minimum.',
      typeError: 'Acceptable file formats are gif, jpg, jpeg, png, mp4, mpg and mov.'
    };

    let callbacks = new Callback();
    callbacks.onSubmit = this.onUploaderSubmit.bind(this);
    callbacks.onComplete = this.onUploaderComplete.bind(this);
    callbacks.onError = this.onUploaderError.bind(this);
    callbacks.onProgress = this.onProgress.bind(this);
    // callbacks.onAllComplete = this.onUploaderAllComplete.bind(this);

    this.uploader = this.uploaderService.s3Uploader(config, callbacks);
  }

  private onUploaderProgress(id: any, name: any, loaded: any, total: any) {
    const percent = Math.round((loaded / total) * 100);
  }

  

    private onProgress(id, name, loaded, total) {
    const percent = (loaded / total) * 100;
    this.uploaderViewModel.progress = percent;
    this.uploaderViewModel.progressCss = `${percent}%`;
  }

  private onUploaderSubmit(id, fileName) {
    this.uploaderViewModel.progress = 0;
    this.uploaderViewModel.progressCss = '0%';
    this.uploaderViewModel.isUploading = true;
    this.uploaderViewModel.isError = false;
  }

  private onUploaderComplete(id, name, responseJSON, maybeXhr) {
    this.uploaderViewModel.isUploading = false;

    if (responseJSON.success) {
      let createMediaInputData = new CreateMediaInputApiModel();
      createMediaInputData.origname = name;
      createMediaInputData.size = this.uploader.getSize(id);
      const fileInfo = this.uploaderService.getUploadedFile(id);
      createMediaInputData.path = fileInfo.path;
      createMediaInputData.source = fileInfo.name;
      createMediaInputData.filename = fileInfo.name;
      createMediaInputData.type = fileInfo.type;

       this.copyForm.controls.avatarMedia.setValue(createMediaInputData)
       this.copyForm.controls.avatarImage.setValue('https://images.goodseeker.com/goodseeker'+fileInfo.path+'/'+fileInfo.name+'?tr=n-stories_list')

    }
  }

  private onUploaderError(id, name, reason, maybeXhrOrXdr) {
    this.uploaderViewModel.isError = true;
    this.uploaderViewModel.errorMsg = reason;
  }

  addTracking(){

  }

  changeData(data){

  }


  close(){
    this.onClose.emit(false)
  }

  save(){
   let obj:any = {}
   if(this.initialState.inbound){
    if(this.copyForm.controls.avatarMedia.value){
      obj.created_by={firstname:this.copyForm.controls.name.value ,
        id_user: this.author_id, 'company_name': this.copyForm.controls.companyname.value, title: this.copyForm.controls.title.value,
        logo:this.copyForm.controls.avatarMedia.value,show_relationship_public: this.copyForm.controls.show_relationship_public.value,
        show_company_public:this.copyForm.controls.show_company_public.value,
        show_name_public:this.copyForm.controls.show_name_public.value,
        show_title_public:this.copyForm.controls.show_title_public.value }
    }
    if(!this.copyForm.controls.avatarMedia.value){
      obj.created_by={firstname:this.copyForm.controls.name.value ,
        id_user: this.author_id, 'company_name': this.copyForm.controls.companyname.value, title: this.copyForm.controls.title.value ,show_relationship_public: this.copyForm.controls.show_relationship_public.value,
        show_company_public:this.copyForm.controls.show_company_public.value,
        show_name_public:this.copyForm.controls.show_name_public.value,
        show_title_public:this.copyForm.controls.show_title_public.value }
    }


    if(this.copyForm.controls.selctedId.value){
     obj.questionnaire = {0: this.copyForm.controls.selctedId.value.toString()}
    }
    obj.show_prompt = this.copyForm.controls.show_prompt.value ? 1 : 0
    obj.company_id = this.initialState.companyId
        // obj.show_relationship_public = this.copyForm.controls.show_relationship_public.value,
        // obj.show_company_public = this.copyForm.controls.show_company_public.value,
        // obj.show_name_public = this.copyForm.controls.show_name_public.value,
        // obj.show_title_public = this.copyForm.controls.show_title_public.value
   }
   else{
    if(this.copyForm.controls.selctedId.value){
     obj.questionnaire = {0: this.copyForm.controls.selctedId.value.toString()}
    }
    obj.show_prompt = this.copyForm.controls.show_prompt.value ? 1 : 0
    obj.company_id = this.initialState.companyId
   }


   
   this.store$.dispatch(new storyActions.Update(this.initialState?.storyId, obj))

   this.onClose.emit(true)
  }


  getAuthorRelationShip(){
   if((this.authorDataRelationShip && (this.authorDataRelationShip.toLowerCase() == this.copyForm.controls.Label.value.toLowerCase()))){
    this.showError = ''
    return true
   }

   if(this.nameOfData && this.nameOfData.length && this.copyForm.controls.Label.value && this.nameOfData.includes(this.copyForm.controls.Label.value.toLowerCase())){
    this.showError = 'Prompt already exist please select from drop down'
    return true;
   }

   return false;

    
  }

  savelabel(){
    let request: UpdateTestimonialAudienceSettingInputApiModel;
    request = {
      name: this.copyForm.value.Label
    }
    this.store$.dispatch(new adminStoryActions.testimonialAudienceSettingActions.Add(this.initialState.companyId, request));
  }


  uploadImage() {

    const setUpFilepicker = {
      onUploadDone: (file) => (this.uploadedUrl = file.filesUploaded[0].url, this.getBase64ImageFromUrl(this.uploadedUrl).then((res: any) => {
        const imageEditedBlob = this.utility.File.b64ToBlob(res.replace(/^data:(image|binary)\/(png|jpeg|jpg|octet-stream|webp);base64,/, ''), "image/png");
        imageEditedBlob['path'] = 'story-image';
        this.uploader.addBlobs(imageEditedBlob);
      })),
      // transformations: {
      //   crop: true,
      //   circle: true,
      //   rotate: true
      // },
      fromSources: ["local_file_system", "instagram", "facebook", "dropbox", "url", "googlephotos", "googledrive"],
      accept: ["image/*","image/heic"],
      maxFiles: 1,

    };
    const options = {
      sessionCache: true
    }

    filestack.init('AYEpOgIEDRdm9OZfP2UHzz', options).picker(setUpFilepicker).open();
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  changeRelationPublic(data){
    if(!this.copyForm.controls.show_relationship_public.value){
      this.copyForm.controls.Label.disable();
    }else{
      this.copyForm.controls.Label.enable();
    }
  }

  changeActionEvent(data){
    if(!this.copyForm.controls.show_name_public.value){
      this.copyForm.controls.name.disable()
    }
    else{
      this.copyForm.controls.name.enable()
    }
    if(!this.copyForm.controls.show_company_public.value){
      this.copyForm.controls.companyname.disable()
    }
    else{
      this.copyForm.controls.companyname.enable()
    }

    if(!this.copyForm.controls.show_title_public.value){
      this.copyForm.controls.title.disable()
    }
    else{
      this.copyForm.controls.title.enable()
    }
    if(!this.copyForm.controls.show_relationship_public.value){
      this.copyForm.controls.Label.disable();
    }else{
      this.copyForm.controls.Label.enable();
    }

    this.hideVisiblity = false;

  }






}
