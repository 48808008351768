
<ng-container>
    <div class="site-container">
        <!-- START HEADER-->
    
        <!-- CLOSE HEADER-->
        <ng-container>
            <!-- START CONTENT-->
            <div class="b-story spacer">
                <div class="b-story-head__author mr-auto">
                    <!-- <div class="b-story-head">
                        <gs-avatar  [src]="storyDetail?.company?.logo" classCss="avatar"></gs-avatar>
                        <div class="name">
                            <span>{{storyDetail?.company?.name}}</span><br />
                            
                        </div>
                    </div> -->
                </div>

            </div>
            <div class="b-story-body">
                <div class="m-story-main">
                    <div class="m-story-content" style="max-width: unset !important;">
                        <div class="m-story-content__inner">
                            <div class="m-story-content__banner">
                   
                          
                                    <ng-container>
                                      <!-- <video-control [src]="storyDetail.media.fullsize" [type]="storyDetail.media.saveIn"></video-control> -->
                                      <mux-player  stream-type="on-demand"
                                      default-hidden-captions
                                      attr.playback-id='{{idOrSlug}}'>
                                    </mux-player>
                                    </ng-container>
                        
                        
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CLOSE CONTENT-->
        </ng-container>
    </div>
</ng-container>

