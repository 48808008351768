<div role="tab" *ngIf="!insideIframe" tabindex="0" role="tab">
  <!-- START HEADER-->
  <!-- User logged in -->
  <div class="site-header" *ngIf="isLoggedIn | async">
    <nav class="navbar navbar-expand-lg bg-white fixed-top c3" role="tablist">
      <div class="d-lg-none">
        <button data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation" class="btn btn-trans text-gray-dark-extra">
          <svg role="img">
            <use xlink:href="#icon-menu"></use>
          </svg>
        </button>
      </div>
      <ng-container *ngIf="hasCompany">
        <a [routerLink]="['/company']" aria-label="Company Navigation Page" tabindex="0" role="tab" class="navbar-brand ml-2 ml-lg-0">
          <img [src]="removeUrl(currentUserProfile?.company.logo)" [alt]="currentUserProfile?.company.name"
            *ngIf="currentUserProfile?.company.logo" />
          <img src="/assets/front-end-minify/img/logo-short.png" [alt]="currentUserProfile?.company.name"
            *ngIf="!currentUserProfile?.company.logo" />
        </a>
      </ng-container>
      <ng-container *ngIf="!hasCompany">
        <a [routerLink]="['/profile']" aria-label="Profile Page" tabindex="0" role="tab" class="navbar-brand ml-2 ml-lg-0">
          <img src="/assets/front-end-minify/img/logo-short.png" alt="GoodSeeker" />
        </a>
      </ng-container>
      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <div class="d-lg-none mb-2 mg-lg-2">
          <button data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="true" aria-label="Toggle navigation" tabindex="0" role="tab" class="btn btn-trans text-gray-dark-extra">
            <svg role="img">
              <use xlink:href="#icon-close"></use>
            </svg>
          </button>
        </div>
        <ul class="navbar-nav" role="tablist">
          <ng-container *ngIf="hasCompany">
            <li>
              <a aria-label="Company Private Page" tabindex="0" role="tab" role="link" [ngClass]="{'pv-company-title mr-1 mr-lg-none': true,'active': currentUrl === '/company'}"
                [routerLink]="['/company']" (click)="refreshApi()">{{currentUserProfile?.company.name}}</a>
              <a aria-label="Company Public Page" tabindex="0" role="tab" role="link" *ngIf="isAllowView && currentUrl === '/company'"
                [routerLink]="['/c/' + currentUserProfile?.company.slug ]"
                class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller public"
                title="View Public Page" style="display: inline-block;">Public Page</a>
            </li>

            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl === '/company/campaigns' || currentUrl.indexOf('/company/campaigns') > -1">
              <span class="gt">-</span>
            </li>
            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl === '/company/campaigns' || currentUrl.indexOf('/company/campaigns') > -1">
              <a aria-label="Campaign Private Page" tabindex="0" role="tab" role="link" [ngClass]="{'active': currentUrl === '/company/campaigns'}"
                [routerLink]="['/company/campaigns']">Campaigns</a>
              <a aria-label="Campaign Public Page" tabindex="0" role="tab" role="link" *ngIf="isAllowView && currentUrl === '/company/campaigns'"
                [routerLink]="['/c/' + currentUserProfile?.company.slug + '/campaigns']"
                class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller public ml-2 mr-1"
                title="View Public Page" style="display: inline-block;">Public Page</a>
            </li>

            <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/company/campaigns/') > -1">
              <span class="gt">-</span>
            </li>
            <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/company/campaigns/') > -1">
              <a [attr.aria-label]="campaign?.title + ' Private Detail  Page'" tabindex="0" role="tab" role="link" [ngClass]="{'pv-page-title': true,'active': currentUrl.indexOf('company/campaigns/') > -1}"
                [routerLink]="['/company/campaigns/' + campaign?.slug]">{{campaign?.title}}</a>
              <a *ngIf="isAllowCampaignView"
              [attr.aria-label]="campaign?.title + ' Private Public  Page'" tabindex="0" role="tab" role="link" 
                [routerLink]="['/c/' + currentUserProfile?.company.slug + '/campaigns/' + campaign?.slug]"
                class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller public ml-2 mr-1"
                title="View Public Page" style="display: inline-block;">Public Page</a>
            </li>

            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl === '/company/featured' || currentUrl.indexOf('/company/featured') > -1">
              <span class="gt">-</span>
            </li>

            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl === '/company/myspotlight' || currentUrl.indexOf('/company/myspotlight') > -1">
              <span class="gt">-</span>
            </li>
            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl === '/company/featured' || currentUrl.indexOf('/company/featured') > -1">
              <a [attr.aria-label]="'Spotlight Private Page'" tabindex="0" role="tab" role="link"  [ngClass]="{'active': currentUrl === '/company/featured'}"
                [routerLink]="['/company/featured']">Spotlights</a>
              <a *ngIf="isAllowView && currentUrl === '/company/featured'"
              [attr.aria-label]="'Spotlight Public Page'" tabindex="0" role="tab" role="link"
                [routerLink]="['/c/' + currentUserProfile?.company.slug + '/featured' ]"
                class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller public ml-2 mr-1"
                title="View Public Page" style="display: inline-block;">Public Page</a>
            </li>

            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl === '/company/myspotlight' || currentUrl.indexOf('/company/myspotlight') > -1">
              <a [ngClass]="{'active': currentUrl === '/company/myspotlight'}"
              [attr.aria-label]="'My Spotlight Page'" tabindex="0" role="tab" role="link"
                [routerLink]="['/company/myspotlight']">MySpotlights</a>
            </li>

            <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/company/featured/') > -1">
              <span class="gt">-</span>
            </li>

            <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/company/myspotlight/') > -1">
              <span class="gt">-</span>
            </li>



            <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/company/featured/') > -1">
              <a [ngClass]="{'pv-page-title': true,'active': currentUrl.indexOf('company/featured/') > -1}"
                [attr.aria-label]="featuredPage?.title + 'Spotlight Private Page'" tabindex="0" role="tab" role="link"
                [routerLink]="['/company/featured/' + featuredPage?.slug]">{{featuredPage?.title}}</a>
              <a *ngIf="isAllowFeaturedPageView"
              [attr.aria-label]="featuredPage?.title + 'Spotlight Public Page'" tabindex="0" role="tab" role="link"
                [routerLink]="['/c/' + currentUserProfile?.company.slug + '/featured/' + featuredPage?.slug ]"
                class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller public ml-2 mr-1"
                title="View Public Page" style="display: inline-block;">Public Page</a>
            </li>

            <li class="d-none d-lg-inline sm" *ngIf="currentUrl.indexOf('/company/myspotlight/') > -1">
              <a [ngClass]="{'pv-page-title': true,'active': currentUrl.indexOf('company/myspotlight/') > -1}"
                [attr.aria-label]="featuredPage?.title + 'Spotlight Private Page'" tabindex="0" role="tab" role="link"
                [routerLink]="['/company/myspotlight/' + featuredPage?.slug]">{{featuredPage?.title}}</a>
              <a *ngIf="isAllowFeaturedPageView"
              [attr.aria-label]="featuredPage?.title + 'Spotlight Public Page'" tabindex="0" role="tab" role="link"
                [routerLink]="['/c/' + currentUserProfile?.company.slug + '/myspotlight/' + featuredPage?.slug ]"
                class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller public ml-2 mr-1"
                title="View Public Page" style="display: inline-block;">Public Page</a>
            </li>


            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl.indexOf('/company/') > -1 && !(currentUrl.indexOf('/company/spotlight-builder') > -1) && !(currentUrl.indexOf('/company/settings') > -1) && !(currentUrl.indexOf('/company/campaigns') > -1) && !(currentUrl.indexOf('/company/featured') > -1) && !(currentUrl.indexOf('/company/welcome') > -1) && !(currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(currentUrl.indexOf('/company/first-schedule') > -1) && !(currentUrl.indexOf('company/myspotlight/') > -1)">
              <span class="gt">-</span>
            </li>
            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl.indexOf('/company/') > -1 && !(currentUrl.indexOf('/company/spotlight-builder') > -1) && !(currentUrl.indexOf('/company/settings') > -1) && !(currentUrl.indexOf('/company/campaigns') > -1) && !(currentUrl.indexOf('/company/featured') > -1) && !(currentUrl.indexOf('/company/welcome') > -1) && !(currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(currentUrl.indexOf('/company/first-schedule') > -1)">
              <a [ngClass]="{'pv-story-title': true,'active': currentUrl === '/company/' + storyDetail?.slug}"
              [attr.aria-label]="storyDetail?.title + 'Detail Private Page'" tabindex="0" role="tab" role="link"
                [routerLink]="['/company/' + storyDetail?.slug]">{{storyDetail?.title}}</a>
              <a *ngIf="storyDetail?.public"
                [routerLink]="['/c/' + currentUserProfile?.company.slug + '/' + storyDetail?.slug]"
                [attr.aria-label]="storyDetail?.title + 'Detail Public Page'" tabindex="0" role="tab" role="link"
                [queryParams]="queryParamsDeatil()"
                class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller public ml-2 mr-1"
                title="View Public Page" style="display: inline-block;">Public Page</a>
            </li>


            <li class="d-none d-lg-inline spacer"
              *ngIf="currentUrl.indexOf('/company/') > -1 && !(currentUrl.indexOf('/company/spotlight-builder') > -1) && !(currentUrl.indexOf('/company/settings') > -1) && !(currentUrl.indexOf('/company/welcome') > -1) && !(currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(currentUrl.indexOf('/company/first-schedule') > -1)">
            </li>

            <li *ngIf="currentUrl.indexOf('/directory') > -1">
              <a [attr.aria-label]="'Directory Page'" tabindex="0" role="tab" role="link"  routerLinkActive="active" [routerLink]="['/directory']">Directory</a>
            </li>
            <li *ngIf="(isAllowView && currentUrl === '/company/featured') || (isAllowView && currentUrl === '/company') || (isAllowView && currentUrl === '/company/campaigns') || (currentUrl.indexOf('/company/campaigns/') > -1 && isAllowCampaignView) || (currentUrl.indexOf('/company/featured/') > -1 && isAllowFeaturedPageView) || (isAllowView && currentUrl === '/company') || (isAllowProfileView && currentUrl === '/profile') ||  ((currentUrl.indexOf('/company/') > -1 && !(currentUrl.indexOf('/company/spotlight-builder') > -1) && !(currentUrl.indexOf('/company/settings') > -1) && !(currentUrl.indexOf('/company/campaigns') > -1) && !(currentUrl.indexOf('/company/featured') > -1) && !(currentUrl.indexOf('/company/welcome') > -1) && !(currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(currentUrl.indexOf('/company/first-schedule') > -1) && storyDetail?.public))">
              <a [attr.aria-label]="'Copy Page link'" tabindex="0" role="tab" role="link"  class="headerCopyRequest mr-3 ml-3" style="display: inline-block; margin-top: 1rem; zoom: 1.02;" routerLinkActive="active" (click)="copyLink()">Copy &nbsp;<i class="fa-regular fa-link"></i></a>
            </li>

            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl.indexOf('/p/') > -1 && currentUrl != ('/p/' + currentUserProfile?.slug)">
              <span class="gt">-</span>
            </li>
            <li class="d-none d-lg-inline sm"
              *ngIf="currentUrl.indexOf('/p/') > -1 && currentUrl != ('/p/' + currentUserProfile?.slug)">
              <a [ngClass]="{'active': currentUrl.indexOf('/p/') > -1}"
                [routerLink]="['/p/' + profile?.slug ]">{{profile?.displayName}}</a>
            </li>

            <li class="d-none d-lg-inline spacer"
              *ngIf="currentUrl.indexOf('/p/') > -1 && currentUrl != ('/p/' + currentUserProfile?.slug)"></li>
          </ng-container>

          <!--<li>
            <a class="mr-2" routerLinkActive="active" [routerLink]="['/profile']">My Profile</a>
            <a *ngIf="isAllowProfileView && currentUrl === '/profile'"
              [routerLink]="['/p/' + currentUserProfile?.slug ]"
              class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller public"><span>View
              </span>Public</a>
          </li>-->

          <ng-container *ngIf="!hasCompany">
            <!--<a [routerLink]="['/company/search']" class="btn btn-mint mx-0 mb-2 ml-lg-2" style="margin-top:12px">Find Company</a>-->
          </ng-container>

          <li class="d-none d-lg-inline sm" *ngIf="currentUrl === ('/p/' + currentUserProfile?.slug)">
            <span class="gt">-</span>
          </li>
          <li class="d-none d-lg-inline sm" *ngIf="currentUrl === ('/p/' + currentUserProfile?.slug)">
            <a [ngClass]="{'active': currentUrl.indexOf('/p/') > -1}"
              [routerLink]="['/p/' + currentUserProfile?.slug ]">{{currentUserProfile?.displayName}}</a>
          </li>
        </ul>
        <div class="mr-3 d-lg-none">


          <div class="gs-divider"></div>
          <ul class="navbar-nav">
            <li>
              <a [attr.aria-label]="'Personal Settings'" tabindex="0" role="tab" role="link"  routerLinkActive="active" [routerLink]="['/player/settings']">Personal Settings</a>
            </li>
          </ul>
          <div class="d-none d-md-block" *ngIf="currentUserProfile?.isCompanyAdmin">
            <div class="gs-divider"></div>
            <ul class="navbar-nav">
              <li>
                <a [attr.aria-label]="'Company Settings'" tabindex="0" role="tab" role="link" routerLinkActive="active" (click)="navigateToAdminPage()">Company Settings</a>
              </li>
            </ul>
          </div>
          <div class="gs-divider"></div>
          <ul class="navbar-nav">
            <li>
              <a [attr.aria-label]="'Personal Settings'" tabindex="0" role="tab" role="link" [routerLink]="['/directory']" routerLinkActive="active">Directory</a>
            </li>
          </ul>
          <div class="gs-divider"></div>
          <ul class="navbar-nav">
            <li>
              <a [attr.aria-label]="'Help & Support'" tabindex="0" role="tab" role="link" href="https://help.goodseeker.com/" routerLinkActive="active">Help &amp; Support</a>
            </li>
          </ul>
          <div class="gs-divider"></div>
          <ul class="navbar-nav">
            <li>
              <a href="#" [attr.aria-label]="'Logout'" tabindex="0" role="tab" role="link" (click)="logout($event)">Logout</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="navbar-center-settings">
        <div class="d-none d-lg-block ml-1" *ngIf="currentUserProfile?.isCompanyAdmin && hasCompany" id="approval_button">
          <a id="approval_highlight" [attr.aria-label]="'Approval Page'" tabindex="-1"  routerLinkActive="active" (click)="showAdminApprovalPage()"
            class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra admin___btn">Approve</a>
        </div>
        <div class="d-none d-lg-block ml-1" *ngIf="currentUserProfile?.isCompanyAdmin && hasCompany">
         <a [attr.aria-label]="'Settings'" tabindex="-1" role="link" routerLinkActive="active" (click)="navigateToAdminPage()"
            class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra admin___btn">Publish</a>
        </div>
        <div class="d-none d-lg-block ml-1" *ngIf="currentUserProfile?.isCompanyAdmin && hasCompany">
          <a [attr.aria-label]="'All Prompts'" tabindex="-1" role="link" routerLinkActive="active" [routerLink]="['/company/settings/prompt/config/campaignprompts']"
            class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra admin___btn">Prompts</a>
        </div>
      </div>
      <div class="spacer"></div>
      <!--<div class="d-none d-lg-block ml-1"   *ngIf="currentUserProfile?.isCompanyAdmin && hasCompany">
      <a routerLinkActive="active" (click)="navigateToAdminPage()"  class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra text-smaller admin___btn">
        <span style="color: black;">Admin</span>
      </a>
    </div>-->
      <div class="d-none d-lg-block ml-1 site-notify__btn" *ngIf="currentUserProfile?.isCompanyAdmin">
        <a routerLinkActive="active" [attr.aria-label]="'Company Page'" tabindex="0" role="tab" role="link" [routerLink]="['/company/welcome']"
          class="btn btn-trans text-gray site-welcome-btn">
          <svg role="img">
            <use xlink:href="#icon-help"></use>
          </svg>
          <span class="gs-badge gs-badge--small" *ngIf="!completedAll"></span>
        </a>
      </div>


      <site-notification></site-notification>
      <div class="site-account dropdown mx-2 mx-lg-4 d-none d-lg-block">
        <div id="dropdown-account" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          class="btn btn-trans dropdown-toggle">
          <gs-avatar [src]="currentUserProfile?.avatar" isBackground="true" classCss="avatar avatar--sm">
          </gs-avatar>
        </div>
        <div aria-labelledby="dropdown-account" class="dropdown-menu dropdown-menu-right">
          <div class="account-info">
            <div class="account-info__avatar">
              <gs-avatar [src]="currentUserProfile?.avatar" isBackground="true" classCss="avatar">
              </gs-avatar>
            </div>
            <div class="account-info__content">
              <h6>{{currentUserProfile?.displayName}}</h6>
              <p>{{currentUserProfile?.title}}</p>
            </div>
          </div>
          <div class="gs-divider m-2"></div>
          <a routerLinkActive="active" [attr.aria-label]="'User Settings'" tabindex="0" role="tab" role="link" [routerLink]="['/user/settings']" class="dropdown-item">Personal Settings</a>
          <a *ngIf="currentUserProfile?.isCompanyAdmin" routerLinkActive="active" (click)="navigateToAdminPage()"
            class="dropdown-item">Company Settings</a>
            <a routerLinkActive="active" [attr.aria-label]="'Directory'" tabindex="0" role="tab" role="link" [routerLink]="['/directory']" class="dropdown-item">Directory</a>
          <a class="dropdown-item" href="https://help.goodseeker.com/">Help &amp; Support</a>
          <a href="#" [attr.aria-label]="'Logout'" tabindex="0" role="tab" role="link" (click)="logout($event)" class="dropdown-item">Logout</a>
        </div>
      </div>
      <button-story-actions *ngIf="hasCompany"></button-story-actions>
    </nav>
  </div>
  <!-- User not logged in -->
  <div class="site-header" *ngIf="!(isLoggedIn | async)">
    <nav class="navbar navbar-expand-lg bg-white fixed-top c4">
      <a [attr.aria-label]="'Company'" tabindex="0" role="tab" role="link" *ngIf="currentCompany.website" [href]="currentCompany.website" class="navbar-brand">
        <img [src]="currentCompany.logo" [alt]="currentCompany.name" *ngIf="currentCompany.logo" />
        <img src="/assets/front-end-minify/img/logo-short.png" [alt]="currentCompany.name"
          *ngIf="!currentCompany.logo" />
      </a>
      <a [attr.aria-label]="'Company'" tabindex="0" role="tab" role="link" *ngIf="!currentCompany.website" [routerLink]="['/c/' + currentCompany.slug]" class="navbar-brand">
        <img [src]="currentCompany.logo" [alt]="currentCompany.name" *ngIf="currentCompany.logo" />
        <img src="/assets/front-end-minify/img/logo-short.png" [alt]="currentCompany.name"
          *ngIf="!currentCompany.logo" />
      </a>
      <ul class="navbar-nav">
        <li>
          <a [attr.aria-label]="'Company'" tabindex="0" role="tab" role="link" [routerLink]="['/company']">{{currentCompany.name}}</a>
        </li>
      </ul>
      <div class="d-lg-none">
        <button data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation" class="btn btn-trans text-gray-dark-extra">
          <svg role="img">
            <use xlink:href="#icon-menu"></use>
          </svg>
        </button>
      </div>
      <div id="navbarSupportedContent" class="collapse navbar-collapse reversed">
        <div class="d-lg-none mb-4 text-right">
          <button data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="true" aria-label="Toggle navigation" class="btn btn-trans text-gray-dark-extra">
            <svg role="img">
              <use xlink:href="#icon-close"></use>
            </svg>
          </button>
        </div>
        <div class="ml-auto">
          <a [attr.aria-label]="'Login'" tabindex="0" role="tab" role="link" [routerLink]="['/user/login']" class="btn btn-sq btn-hdr">Login</a>
          <a [attr.aria-label]="'Register'" tabindex="0" role="tab" role="link" [routerLink]="['/user/signup']" class="btn btn-sq btn-hdr btn-mint">Register</a>
        </div>
      </div>
    </nav>
  </div>
  <!-- CLOSE HEADER-->


</div>