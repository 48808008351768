export interface StoryOutputApiModel {
    id: number;
    internal_id: number;
    title: string;
    slug: string;
    author: string;
    lead_count: number;
    like_count: number;
    player_count: number;
    published: Date;
    status: string;
    approver: string;
    approved: boolean;
    visible: boolean;
    active: boolean;
    public: boolean;
    internal: boolean;
    id_campaign: number;
    boost_requested: boolean;
    is_internal_story_approved: any;
    spotlight_only: boolean;
    published_to_draft: any;
    notes_count?: any;
    is_video?: boolean;
    user_requested?: any;
    interview_style?: boolean;
    final_approver?: any;
}

export class StoryOutputApiModel {
    constructor(data?: Partial<StoryOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}


