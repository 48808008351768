
<div class="modal-header">
    <h5 class="modal-title">Upload Video Not Complete</h5>
    <button type="button" (click)="cancel()" class="close"><span aria-hidden="true">
        <svg role="img">
          <use xlink:href="#icon-close"></use>
        </svg></span></button>
  </div>
  <div class="modal-body" [formGroup]="draftForm">
    <div class="row">
        <div class="col-12">
            <div class="custom-control custom-radio">
                <label class="mb-0">
                    <input type="radio" formControlName="draft_type" value="upload_background"
                        class="custom-control-input">
                    <span class="custom-control-label">Continue to upload in background.</span>
                </label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="custom-control custom-radio">
                <label class="mb-0">
                    <input type="radio" formControlName="draft_type" value="only_Draft"
                        class="custom-control-input">
                    <span class="custom-control-label">{{initialState.inbound ? 'Leave the Page without Video' : 'Save to My Drafts without video'}}</span>
                </label>
            </div>
        </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="cancel()"
      class="btn btn-outline btn-gray-light min-width"  >Cancel</button>
    <button type="button" (click)="confirm()" class="btn btn-pink min-width" [disabled]="draftForm.invalid">Save</button>
  </div>