
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { JobLinkacAction } from '../actions';
// import { AddUserHttpService, } from '../services';
import { JobsHttpService } from '../services/jobs-post.http-service';

@Injectable()
export class JobLinkEffect {

  
  addJobEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.AddJobs>(JobLinkacAction.ActionTypes.ADD_JOBS),
    switchMap((action: JobLinkacAction.AddJobs) => {
      return this.JobLinkHttpService.addJobs(action.companyId, action.payload).pipe(
        map((respone) => {
          return new JobLinkacAction.AddJobsSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.AddJobsFail(error));
        }));
    })
  ));

  
  updateJobEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.UpdateJobs>(JobLinkacAction.ActionTypes.UPDATE_JOBS),
    switchMap((action: JobLinkacAction.UpdateJobs) => {
      return this.JobLinkHttpService.updateJobs(action.jobid, action.payload).pipe(
        map((respone) => {
          return new JobLinkacAction.UpdateJobsSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.UpdateJobsFail(error));
        }));
    })
  ));


  
  loadLinkjobsStories$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.AdminLinkedJobs>(JobLinkacAction.ActionTypes.ADMIN_LINKED_JOBS),
    switchMap((action: JobLinkacAction.AdminLinkedJobs) => {
      return this.JobLinkHttpService.loadLinkjobsStories(action.cid,action.jid).pipe(
        map((respone) => {
          return new JobLinkacAction.AdminLinkedJobsSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.AdminLinkedJobsFail());
        }));
    })
  ));

  
  loadLinkjobsSpotlights$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.AdminLinkedSpotlightJobs>(JobLinkacAction.ActionTypes.ADMIN_SPOTLIGHT_JOBS),
    switchMap((action: JobLinkacAction.AdminLinkedSpotlightJobs) => {
      return this.JobLinkHttpService.loadLinkjobsSpotlight(action.cid,action.jid).pipe(
        map((respone) => {
          return new JobLinkacAction.AdminLinkedSpotlightJobsSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.AdminLinkedSpotlightJobsFail());
        }));
    })
  ));

  
  Addadminjobsstories$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.AddAdminJobstoStories>(JobLinkacAction.ActionTypes.ADD_ADMIN_JOBS_STORIES),
    switchMap((action: JobLinkacAction.AddAdminJobstoStories) => {
      return this.JobLinkHttpService.addJobsToStories(action.companyId,action.jobId,action.payload,action.fromStories).pipe(
        map((respone) => {
          return new JobLinkacAction.AddAdminJobstoStoriesSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.AddAdminJobstoStoriesFail());
        }));
    })
  ));

  
  removeallSpotlightandstories$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.RemoveAllJobsFromSpotlightAndStories>(JobLinkacAction.ActionTypes.REMOVE_ALL_JOBS_SPOTLIGHT_AND_STORIES),
    switchMap((action: JobLinkacAction.RemoveAllJobsFromSpotlightAndStories) => {
      return this.JobLinkHttpService.addRemoveitemsformjobs(action.cid,action.jid,action.isSpotlight,action.isAddorRemove,action.id).pipe(
        map((respone) => {
          return new JobLinkacAction.RemoveAllJobsFromSpotlightAndStoriesSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.RemoveAllJobsFromSpotlightAndStoriesFail());
        }));
    })
  ));

  
  LoadJobsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.Load>(JobLinkacAction.ActionTypes.LOAD),
    switchMap((action: JobLinkacAction.Load) => {
      return this.JobLinkHttpService.getJobs(action.companyId,action.query).pipe(
        map((respone) => {
          return new JobLinkacAction.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.LoadFail(error));
        }));
    })
  ));

  
  DeleteJobEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.DeleteJobs>(JobLinkacAction.ActionTypes.DELETE_JOBS),
    switchMap((action: JobLinkacAction.DeleteJobs) => {
      return this.JobLinkHttpService.deleteJob(action.jobid).pipe(
        map((respone) => {
          return new JobLinkacAction.DeleteJobsSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.DeleteJobsFail(error));
        }));
    })
  ));

  
  LinkJobEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.LinkJobs>(JobLinkacAction.ActionTypes.LINK_JOBS),
    switchMap((action: JobLinkacAction.LinkJobs) => {
      return this.JobLinkHttpService.LinkJobs(action.storyId,action.jobid).pipe(
        map((respone) => {
          return new JobLinkacAction.LinkJobsSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.LinkJobsFail(error));
        }));
    })
  ));

  
  LinkSpotlightJobEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.LinkSpotlightJobs>(JobLinkacAction.ActionTypes.LINK_JOBS_SPOTLIGHT),
    switchMap((action: JobLinkacAction.LinkSpotlightJobs) => {
      return this.JobLinkHttpService.SpotlightLinkJobs(action.spotlightId,action.jobid).pipe(
        map((respone) => {
          return new JobLinkacAction.LinkJobsSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.LinkJobsFail(error));
        }));
    })
  ));
  
  
  GetSpotlightJobEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.GetSpotlightJobs>(JobLinkacAction.ActionTypes.GET_SPOTLIGHT_JOBS),
    switchMap((action: JobLinkacAction.GetSpotlightJobs) => {
        return this.JobLinkHttpService.getSpotlightLinkedJobs(action.spotlightId).pipe(
          map((respone) => {
            return new JobLinkacAction.GetSpotlightJobsSuccess(respone);
          }),
          catchError((error: any) => {
            return of(new JobLinkacAction.GetSpotlightJobsFail(error));
          }))

    })
  ));


  
  GetLinkJobEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.GetLinkJobs>(JobLinkacAction.ActionTypes.GET_LINKED_JOBS),
    switchMap((action: JobLinkacAction.GetLinkJobs) => {
      return this.JobLinkHttpService.getLinkedJobs(action.storyId).pipe(
        map((respone) => {
          return new JobLinkacAction.GetLinkJobsSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.LinkJobsFail(error));
        }));
    })
  ));


  
  removeOneByOneJobsFromStories$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.RemoveJobsFromStories>(JobLinkacAction.ActionTypes.REMOVE_JOBS_FROM_STORIES),
    switchMap((action: JobLinkacAction.RemoveJobsFromStories) => {
      return this.JobLinkHttpService.removeonebyonejobsstories(action.companyId,action.jobid,action.payload).pipe(
        map((respone) => {
          return new JobLinkacAction.RemoveJobsFromStoriesSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.RemoveJobsFromStoriesFail());
        }));
    })
  ));


  
  removeOneByOneJobsFromSpotlights$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<JobLinkacAction.RemoveJobsFromSpotlight>(JobLinkacAction.ActionTypes.REMOVE_JOBS_FROM_SPOTLIGHT),
    switchMap((action: JobLinkacAction.RemoveJobsFromSpotlight) => {
      return this.JobLinkHttpService.removeonebyonejobspotlights(action.companyId,action.jobId,action.payload).pipe(
        map((respone) => {
          return new JobLinkacAction.RemoveJobsFromSpotlightSuccess();
        }),
        catchError((error: any) => {
          return of(new JobLinkacAction.RemoveJobsFromSpotlightFail());
        }));
    })
  ));





  
 











  constructor(
    private actions$: Actions,
    private JobLinkHttpService: JobsHttpService) { }
}

