<div class="form-label-group force-focus" [ngClass]="{cssClass: cssClass && cssClass.length > 0}">
  <!-- <input #inputControl [tabindex]="tabindex" [id]="name + customId" [type]="type" [placeholder]="placeholder"
    [(ngModel)]="value"  [(colorPicker)]="value" [cpPosition]="cpPosition" (colorPickerChange)="onChange()" class="form-control"
    [disabled]="formControl?.disabled" [ngClass]="{'is-invalid': !formControl?.valid && formControl?.dirty}" required> -->
    <div class="color__container_common">
      <div
      #inputControl
      (ngModelChange)="onChange($event)"
        ngx-colors-trigger
        class="color_picker-common"
        [ngModelOptions]="{standalone: true}"
        [style.background]="value"
        [(ngModel)]="value"
      ></div>
    </div>
  <label *ngIf="label" [for]="name + customId">{{label}} <small *ngIf="labelSmall">{{labelSmall}}</small></label>

  <ng-container *ngIf="formControl?.errors && formControl?.dirty && !formControl?.valid && !hideErrorMessage">
    <div *ngIf="formControl?.errors.required" class="invalid-feedback">{{name}} is required.</div>
    <div *ngIf="formControl?.errors.email" class="invalid-feedback">{{name}} must be valid.</div>
    <div *ngIf="formControl?.errors.maxlength" class="invalid-feedback">{{name}} must be less than
      {{formControl?.errors.maxlength.requiredLength}} characters.</div>
    <div *ngIf="formControl?.errors.minlength" class="invalid-feedback">{{name}} Min
      {{formControl?.errors.minlength.requiredLength}} characters.</div>
    <div *ngIf="formControl?.errors.isCustom" class="invalid-feedback">{{formControl?.errors.message}}</div>
  </ng-container>
</div>