
<ng-container>
    <div id="hippo-video-delivery-scripts"></div>
    <div class="site-container">
        <!-- START HEADER-->
    
        <!-- CLOSE HEADER-->
        <ng-container>
            <!-- START CONTENT-->
            <div class="b-story spacer">
                <div class="b-story-head__author mr-auto">
                    <div class="b-story-head">
                        <gs-avatar  [src]="storyDetail?.company?.logo" classCss="avatar"></gs-avatar>
                        <div class="name">
                            <span>{{storyDetail?.company?.name}}</span><br />
                            
                        </div>
                    </div>
                </div>

            </div>
            <div class="b-story-body">
                <div class="m-story-main">
                    <div class="m-story-content">
                        <div class="m-story-content__inner">
                            <div class="m-story-content__banner" *ngIf="storyDetail?.media && storyDetail?.media.length">
                                <div class="slideshow-container" style="background-position: center bottom;" style="background:unset">
                                  <div class="mySlides" *ngFor="let item of mediaPayload ; let i= index" [ngClass]="{'fade': i == activeIndex,'hidedisplay':i != activeIndex, 'showDisplay': i == activeIndex}">
                                    <ng-container  *ngIf="isVideo('video/mp4')">
                                      <!-- <video-control [src]="storyDetail.media.fullsize" [type]="storyDetail.media.saveIn"></video-control> -->
                                      <mux-player  stream-type="on-demand"
                                      default-hidden-captions
                                      attr.playback-id='{{item.url}}' attr.title="{{item.title}}" attr.poster="{{item.thumbnail_url}}"
                                      attr.primary-color="{{primaryColor}}"
                                      attr.accent-color="{{accentColor}}">
                                    </mux-player>
                                    <!-- <div class="group-action">
                                        <div>
                                          <button class="btn-action" (click)="downloadVideo(item)" title="Download Video">
                                            <i class="fa-solid fa-download"></i>
                                          </button>
                                        </div>
                                      </div> -->
                                    </ng-container>
                                  </div>
                                  <a class="prev" (click)="plusSlides(-1)" *ngIf="mediaPayload.length > 1"> <span class="previous_slide">❮</span></a>
                                  <a class="next" (click)="plusSlides(1)" *ngIf="mediaPayload.length > 1"><span class="next_slide">❯</span></a>
                              </div>
                              </div>
                              <div style="text-align:center" *ngIf="storyDetail?.media && storyDetail?.media.length">
                                <span class="dot"  *ngFor="let item of mediaPayload ; let i = index" [ngClass]="{'showIndiacatr': i == activeIndex}"></span> 
                                <!-- <span class="dot" (click)="currentSlide(item)"></span> 
                                <span class="dot" (click)="currentSlide(item)"></span>  -->
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CLOSE CONTENT-->
        </ng-container>
    </div>
</ng-container>

<div id="downloadfile"></div>