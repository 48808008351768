<div class="form-label-group spacing-lg" *ngIf="isBrowser && show" [ngClass]="{cssClass: cssClass && cssClass.length > 0}">

  <div class="froala-wysiwyg">
    <quill-editor [(ngModel)]="value" [readOnly]="isEditable" (ngModelChange)="onChange()" [placeholder]="placeholder"
      [styles]="{height: 22*rows + 'px',fontSize: '1rem'}" [modules]="quillModule"></quill-editor>
  </div>

  <ng-container *ngIf="formControl.dirty && !formControl.valid && !hideErrorMessage">
    <div *ngIf="formControl.errors.required" class="invalid-feedback">{{name}} is required.</div>
    <div *ngIf="formControl.errors.email" class="invalid-feedback">{{name}} must be valid.</div>
    <div *ngIf="formControl.errors.maxlength" class="invalid-feedback">{{name}} must be less than
      {{formControl.errors.maxlength.requiredLength}} characters.</div>
    <div *ngIf="formControl.errors.minlength" class="invalid-feedback">{{name}} Min
      {{formControl.errors.minlength.requiredLength}} characters.</div>
    <div *ngIf="formControl.errors.isCustom" class="invalid-feedback">{{formControl.errors.message}}</div>
  </ng-container>
</div>