import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { withLatestFrom, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { storyActions, storyBuilderActions } from '@modules/story/store/actions';
import { storyBuilderSelector } from '@modules/story/store/selectors';
import { CoreState } from '@app/store/core/states';
import { SegmentAnalyticsService } from '@framework/analytics/segment-analytics/services';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { UserProfileViewModel } from '@modules/user/store/models';

@Injectable()
export class StoryAnalyticsEffects {

    createStoryEffect$ = createEffect(() => this.actions$.pipe(
        ofType<storyActions.Create | storyActions.CreateInbound>(storyActions.ActionTypes.CREATE, storyActions.ActionTypes.CREATE_INBOUND),
        withLatestFrom(this.store$.select(userProfileSelectors.selectCurrentProfile)),
        tap(([action, userProfile]) => {
            this.createWorkStory(userProfile, action.data);
        })
    ), { dispatch: false });

    editWorkStoryEffect$ = createEffect(() => this.actions$.pipe(
        ofType<storyActions.Update>(storyActions.ActionTypes.UPDATE),
        withLatestFrom(this.store$.select(userProfileSelectors.selectCurrentProfile)),
        tap(([action, userProfile]) => {
            this.editWorkStory(userProfile, action.data);
        })
    ), { dispatch: false });

    publishStoryEffect$ = createEffect(() => this.actions$.pipe(
        ofType<storyActions.Publish>(storyActions.ActionTypes.PUBLISH),
        withLatestFrom(this.store$.select(userProfileSelectors.selectCurrentProfile)),
        tap(([, userProfile]) => {
            this.publishWorkStory(userProfile);
        })
    ), { dispatch: false });

    publishStorySuccessEffect$ = createEffect(() => this.actions$.pipe(
        ofType<storyActions.Publish>(storyActions.ActionTypes.PUBLISH_SUCCESS),
        withLatestFrom(
            this.store$.select(userProfileSelectors.selectCurrentProfile)
        ),
        tap(([data, userProfile]) => {
            this.publishStory(userProfile, data['data'].contributors)
        })
    ), { dispatch: false });

    switchStoryStepEffect$ = createEffect(() => this.actions$.pipe(
        ofType<storyBuilderActions.SetStepActive>(storyBuilderActions.StoryBuilderActionTypes.SET_STEP_ACTIVE),
        withLatestFrom(this.store$.select(storyBuilderSelector.selectInbound)),
        tap(([action, isInbound]) => {
            if (action.stepActive) {
                if (isInbound) {
                    this.switchPage(`Create Testimonial - ${action.stepActive.title}`);
                } else {
                    this.switchPage(`Create Story - ${action.stepActive.title}`);
                }
            }
        })
    ), { dispatch: false });

    loadStoryDetailEffect$ = createEffect(() => this.actions$.pipe(
        ofType<storyActions.LoadDetail>(storyActions.ActionTypes.LOAD_DETAIL),
        withLatestFrom(this.store$.select(userProfileSelectors.selectCurrentProfile)),
        tap(([action, userProfile]) => {
            this.loadStoryDetail(action.storyIdOrSlug, userProfile);
        })
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private store$: Store<CoreState>,
        private segmentAnalyticsService: SegmentAnalyticsService,
    ) {

    }

    getUserType(type: number) {
        switch (type) {
            case 1:
                return 'Invited';
            case 2:
                return 'Named';
            case 3:
                return 'Registered';
        }
    }

    private publishStory(userProfile: UserProfileViewModel, contributors?: any) {
        if (contributors && contributors.length) {
            contributors.forEach(contributor => {

                this.segmentAnalyticsService.identify(contributor.player_id.toString(), {
                    email: contributor.player_email,
                    first_name: contributor.player_first_name,
                    last_name: contributor.player_last_name,
                    'User Status': this.getUserType(contributor.player_status),
                    'Companyid': userProfile.company.id
                });
            })
        }
        if (userProfile && userProfile.id && userProfile.email) {
            this.segmentAnalyticsService.identify(userProfile.id.toString(), {
                email: userProfile.email,
                'User Status': this.getUserType(3),
                'Last Seen': Math.round((new Date()).getTime() / 1000),
                'User signed up': Math.round((new Date(userProfile.signupDate +'+00:00')).getTime() / 1000),
                'Companyid': userProfile.company.id
            });
        }
    }

    private createWorkStory(userProfile: UserProfileViewModel, data?: any) {
        if (userProfile && userProfile.id && userProfile.email && userProfile.status) {

            this.segmentAnalyticsService.identify(userProfile.id.toString(), {
                email: userProfile.email,
                'User Status': this.getUserType(userProfile.status),
                'Last Seen': Math.round((new Date()).getTime() / 1000),
                'User signed up': Math.round((new Date(userProfile.signupDate +'+00:00')).getTime() / 1000),
                'Companyid': userProfile.company.id
            });
        }

        this.segmentAnalyticsService.track('Started a Story', {
            email: userProfile.email,
            userId: userProfile.id.toString(),
            storyUrl: window.location.href
        });
    }

    private editWorkStory(userProfile: UserProfileViewModel, data?: any) {

    }

    private publishWorkStory(userProfile: UserProfileViewModel) {
        if (userProfile && userProfile.id && userProfile.email && userProfile.status) {

            this.segmentAnalyticsService.identify(userProfile.id.toString(), {
                email: userProfile.email,
                'User Status': this.getUserType(userProfile.status),
                'Last Seen': Math.round((new Date()).getTime() / 1000),
                'User signed up': Math.round((new Date(userProfile.signupDate +'+00:00')).getTime() / 1000),
                'Companyid': userProfile.company.id
            });
        }
        this.segmentAnalyticsService.track('Published a Story', {
            email: userProfile.email,
            userId: userProfile.id.toString(),
            storyUrl: window.location.href
        });
    }

    private loadStoryDetail(storyIdOrSlug: number | string, userProfile: UserProfileViewModel) {
        if (userProfile && userProfile.id && userProfile.email && userProfile.status) {

            this.segmentAnalyticsService.identify(userProfile.id.toString(), {
                email: userProfile.email,
                'User Status': this.getUserType(userProfile.status),
                'Last Seen': Math.round((new Date()).getTime() / 1000),
                'User signed up': Math.round((new Date(userProfile.signupDate +'+00:00')).getTime() / 1000),
                'Companyid': userProfile.company.id
            });
        }
    }

    private switchPage(pageName: string) {
        // this.segmentAnalyticsService.page(pageName);
    }
}
