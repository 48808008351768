import { Component, OnInit } from '@angular/core';
import { ComponentModalBase } from '@framework/base';
import { storyActions, testimonialActions } from '@modules/company-admin/store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-approvalmodal',
  templateUrl: './approvalmodal.component.html',
  styleUrls: ['./approvalmodal.component.scss']
})
export class ApprovalmodalComponent extends ComponentModalBase<any> {


  private sendAndDontSendEmailSubscription: Subscription;
  private  sendAndDontSendEmailonuserstoriesSubscription: Subscription;
  
  public approvalConfigForm: UntypedFormGroup;
  public isSendMail:boolean = false;
  constructor(  private store$: Store<CoreState>,private formBuilder: UntypedFormBuilder,
    private actions$: Actions,) {
    super();
    this.initForm();
  }

  protected onInit(): void { 
    this.subscribe()
    this.setTosubjectandBody();
  }

  protected onDestroy(): void { }

  protected onChanges(): void { }

  protected onDoCheck(): void { }

  protected onAfterContentInit(): void { }

  protected onAfterContentChecked(): void { }

  protected onAfterViewInit(): void { }

  protected onAfterViewChecked(): void { }

  public subscribe(){
    this.sendAndDontSendEmailSubscription = this.actions$.pipe(
      ofType<testimonialActions.ApproveSuccess>(testimonialActions.ActionTypes.APPROVE_SUCCESS)
    ).subscribe(() => {
      this.isSendMail = false
      this.onClose.emit(false)
    });

    this.sendAndDontSendEmailonuserstoriesSubscription = this.actions$.pipe(
      ofType<storyActions.ApprovalSuccess>(storyActions.ActionTypes.TURN_APPROVAL_OFF_SUCCESS)
    ).subscribe(() => {
      this.isSendMail = false
      this.onClose.emit(false)
    });
  }
  private initForm() {
    this.approvalConfigForm = this.formBuilder.group({
      sendTo:['1'],
      subject:[],
      body:[]
    });
  }
  setTosubjectandBody(){
    this.approvalConfigForm.controls.subject.setValue(this.initialState.subject);
    this.approvalConfigForm.controls.body.setValue(this.initialState.body);
  }

  public noemailtoall() {
    let obj = {
      subject:this.approvalConfigForm.value.subject,
      body:this.approvalConfigForm.value.body,
    }
    if(!this.initialState.fromStory){
      this.store$.dispatch(new testimonialActions.Approve(this.initialState.companyid, this.initialState.userid, this.initialState.id,false,3,obj));
    }
    else
    {
      this.store$.dispatch(new storyActions.Approval(this.initialState.companyid, this.initialState.userid, this.initialState.id,false,3,obj));
    }
   

  }
  public yesemailtoall() {
    this.isSendMail = true;
    let obj = {
      subject:this.approvalConfigForm.value.subject,
      body:this.approvalConfigForm.value.body,
    }

    if(!this.initialState.fromStory){
      this.store$.dispatch(new testimonialActions.Approve(this.initialState.companyid, this.initialState.userid, this.initialState.id,true,+this.approvalConfigForm.value.sendTo,obj));
    }
    else
    {
      this.store$.dispatch(new storyActions.Approval(this.initialState.companyid, this.initialState.userid, this.initialState.id,true,+this.approvalConfigForm.value.sendTo,obj));
    }
    
  }

  close(){
    this.onClose.emit(false);
  }
}
