<div class="dropdown" *ngIf="!includesCampaign">
  <div   [ngClass]="{'hide_btn_header_display':!requt_btn}" id="dropdown-create" role="button" role="tab" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Request Story" tabindex="5">
    <button (click)="requestStory()" [style.background]="requt_btn"  [style.color]="requet_text_Color" style="position: relative;right: 3px;" [hidden]="!currentUserProfile || !currentUserProfile.email" class="btn btn-create btn-sq btn-hdr text-small btn-mint btn-rqst">
      <span>REQUEST STORY</span>
    </button>
  </div>
  <div  [ngClass]="{'hide_btn_header_display':!submit_btn}"id="dropdown-create" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Submit Story" tabindex="6">
    <a [routerLink]="'/story/create'"  role="link" [style.background]="submit_btn"  [style.color]="submit_text_Color" [queryParams]="{prevUrl: currentUrl}" class="btn btn-create btn-sq btn-hdr text-small">
      <span>SUBMIT STORY</span>
    </a>
  </div>
  <!-- http://localhost:4200/interview/campaign/create/myxbych-nbcampauih -->
  <!-- <div aria-labelledby="dropdown-create" class="dropdown-menu dropdown-menu-right w-100">
    <a [routerLink]="'/story/create'" [queryParams]="{prevUrl: currentUrl}" class="dropdown-item">
      <svg role="img" class="text-mint mr-2">
        <use xlink:href="#icon-write"></use>
      </svg><span class="mr-2">Publish</span>
    </a>
    <a (click)="requestStory()" [hidden]="!currentUserProfile || !currentUserProfile.email" class="dropdown-item">
      <svg role="img" class="text-purple-light mr-2">
        <use xlink:href="#icon-suggest"></use>
      </svg><span class="mr-2">Request</span>
    </a>
  </div> -->
</div>