import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AuthService, BaseHttpService } from '@framework/services';
import { LoadCommentInputApiModel, CommentInputApiModel, RequestInputApiModel } from '../models/api-models/input';
import { CommentOutputApiModel } from '../models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models/api-models';

@Injectable()
export class CommentHttpService extends BaseHttpService {
    private enpoits = {
        comment: '/comments',
        story: '/stories',
        request: '/stories/request/'
    };

    private token:any

    constructor(httpClient: HttpClient, private authService: AuthService) {
        super(httpClient, '');
        this.authService.cognito.getIdToken().then((tokens: string) => {
             this.token = tokens;
    
          })
    }

    public load(resourceId: number, resourceType: string, queryData: LoadCommentInputApiModel) {
        return this.get(`${this.enpoits[resourceType]}/${resourceId}/comments/?${queryData.query}`)
            .pipe(map((response: ResponsePaginationApiModel<CommentOutputApiModel>) => {
                return response;
            }));
    }

    public request(payload:any) {
        const headers = new HttpHeaders({
            Authorization: `Bearer ${this.token}`,
          });

              return   this.http.post(`${window.location.origin}/api/v3${this.enpoits.request}`,payload,      { headers })
           
        // return this.post(this.enpoits.request, payload);
    }



    public create(payload: CommentInputApiModel) {
        return this.post(`${this.enpoits.comment}/`, payload)
            .pipe(map((response: any) => {
                return new CommentOutputApiModel(response);
            }));
    }

    public update(id: number, payload: Partial<CommentInputApiModel>) {
        return this.patch(`${this.enpoits.comment}/${id}/`, payload).pipe(map((response: any) => {
            return response;
        }));
    }

    public deleteComment(id: number, resourceType: string) {
        return this.delete(`${this.enpoits.comment}/${id}/?resource=${resourceType}`).pipe(map((response: any) => {
            return response;
        }));
    }

}
