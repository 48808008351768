<app-toolbar [hidden]="statusDetail.isLoading || !storyDetail?.public" aria-label="Toolbar" role="toolbar">
  <app-toolbar>
    <div [formGroup]="shareForm" role="group" aria-label="Share options">
      
      <!-- Share Button -->
      <app-toolbar-item>
        <button
          (click)="share()"
          class="btn text-gray text-hover-mint"
          aria-label="Share this story"
          tabindex="0"
        >
          <i class="fa-regular fa-share" aria-hidden="true"></i>
        </button>
      </app-toolbar-item>

      <!-- Approve and Send Email Button -->
      <app-toolbar-item *ngIf="currentUserProfile?.isCompanyAdmin && isLoggedInuser">
        <button
          (click)="approvePublic()"
          class="btn text-gray text-hover-orange"
          style="font-size: 18px;"
          tabindex="0"
          aria-label="Approve and send email"
        >
          <i class="fa-regular fa-envelope" aria-hidden="true"></i>
        </button>
      </app-toolbar-item>
    </div>
  </app-toolbar>
</app-toolbar>
