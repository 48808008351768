import {Component, ElementRef, Input, SimpleChanges, ViewChild} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component/component.base';
import { CoreState } from '@app/store/core/states';
import {companySelectors, searchBoxSelectors} from '@modules/company/store/selectors';
import {CompanyViewModel, SearchBoxStateViewModel} from '@modules/company/models/store-view-models';
import { UserProfileViewModel } from '@modules/user/store/models';
import {searchBoxActions} from "@modules/company/store/actions";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateProfileInternalInputApiModel } from '@modules/company-admin/store/models/api-models/input';
import { profilePublicActions } from '@modules/company-admin/store/actions';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'co-pu-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss'],
  animations: [
    trigger('showhide', [
      // ...
      state('show', style({
        opacity: 1,
        zIndex: 1000
      })),
      state('hide', style({
        opacity: 0,
        zIndex: -1
      })),
      transition('show => hide', [
        animate('0.2s ease-in-out')
      ]),
      transition('hide => show', [
        animate('0.5s ease-in-out')
      ]),
    ]),
  ]
})
export class InformationsComponent extends ComponentBase {
  @ViewChild('info') infoBar: ElementRef;
  @Input() showtags:boolean = true;
  @Input() showIFrame:boolean = false;
  // Private variables
  private selectCurrentCompany$: Observable<CompanyViewModel>;
  private selectSearchBoxStatus$: Observable<SearchBoxStateViewModel>;
  private selectCurrentCompanySubscription: Subscription;
  private selectSearchBoxStatusSubscription: Subscription;

  // Public variables
  public currentUserProfile: UserProfileViewModel;
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  public weAppreciate: number = 8;
  public isEditQuote: boolean = true;
  public weValue: number = 14;
  public ishide: boolean =false;
  public quoteForm: UntypedFormGroup;
  public ourVibes: number = 13;
  public ourteams: number = 2;
  public ourChallenges: number = 11;
  public companyId:any;
  public falgforFalse: number = 0
  public searchBoxStatus: SearchBoxStateViewModel;

  constructor(private store$: Store<CoreState>) {
    super();
    this.initForm()
    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectSearchBoxStatus$ = this.store$.select(searchBoxSelectors.selectSearchBoxStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {
      // this.infoBar.nativeElement.style.height =  this.infoBar.nativeElement.offsetHeight && this.infoBar.nativeElement.offsetHeight + 'px' || 'auto';
  }

  private initForm()
  {
    this.quoteForm = new UntypedFormGroup({
      quoteFormControl:new UntypedFormControl('', [Validators.required])
    })
  }
  // Private functions
  private subscribe() {
    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany)
      {
        this.currentCompany = currentCompany;
        this.companyId = currentCompany.id
        // setTimeout(() => {
        //     this.infoBar.nativeElement.style.height =  this.infoBar.nativeElement.offsetHeight && this.infoBar.nativeElement.offsetHeight + 'px' || 'auto';
        // }, 300);
        if(this.currentCompany.frequentTags){
          if(this.currentCompany && !this.currentCompany.frequentTags[this.ourteams]?.is_header_part){
             this.falgforFalse ++;
          }
          if(this.currentCompany && !this.currentCompany.frequentTags[this.ourVibes]?.is_header_part){
           this.falgforFalse ++;
         }
         if(!this.currentCompany && !this.currentCompany.frequentTags[this.weAppreciate]?.is_header_part){
          this.falgforFalse++
       }
       if(this.currentCompany && !this.currentCompany.frequentTags[this.weValue]?.is_header_part){
        this.falgforFalse++;
     }
    }
        currentCompany.logo = currentCompany.logo == 'assets/front-end-minify/img/logo-short.png' ? 'assets/front-end-minify/img/avatarstyle.png' : currentCompany.logo
        this.quoteForm.controls.quoteFormControl.setValue(this.currentCompany.slogan == ""  || this.currentCompany.slogan == null || this.currentCompany.slogan == undefined ? "" : this.currentCompany.slogan)
        if(this.currentCompany.slogan == ""  || this.currentCompany.slogan == null || this.currentCompany.slogan == undefined)
        {
          this.ishide = true
        }
      }


    });

    this.selectSearchBoxStatusSubscription = this.selectSearchBoxStatus$.subscribe((state: SearchBoxStateViewModel) => {
      this.searchBoxStatus = state;
    });
  }

  get isCompany() : boolean{

    if(window.location.pathname == `/c/${this.currentCompany.slug}`){
      return true
    }
    else{
    return false;
    }

  }

  private unsubscribe() {
    this.selectCurrentCompanySubscription.unsubscribe();
  }

  getNames(number){
    var name = '';
    if(this.currentCompany?.frequentTags){
      if(number == 2){
        name = this.currentCompany?.frequentTags[number]?.header_title.toLowerCase() == 'roles' ? 'Teams' : this.currentCompany?.frequentTags[number]?.header_title;
         
      }
      if(number == 8){
        name = this.currentCompany?.frequentTags[number]?.header_title.toLowerCase() == 'impact' ? 'Skills' : this.currentCompany?.frequentTags[number]?.header_title;
         
      }
      if(number == 13){
        name = this.currentCompany?.frequentTags[number]?.header_title.toLowerCase() == 'feeling' ? 'Vibes' : this.currentCompany?.frequentTags[number]?.header_title;
         
      }
      if(number == 14){
        name = this.currentCompany?.frequentTags[number]?.header_title.toLowerCase() == 'values' ? 'Values' : this.currentCompany?.frequentTags[number]?.header_title;
         
      }
    }

    return name;
  }

  // Public functions
  public filterTag(tag) {
    if(window.location.pathname == `/c/${this.currentCompany.slug}`){
      this.store$.dispatch(new searchBoxActions.ResetPage())
      var tagInserted = this.checktagshistory(tag)
      var payload = {tags: tagInserted}
      this.store$.dispatch(new searchBoxActions.SearchPayload(payload))
      this.store$.dispatch(new searchBoxActions.Show());
      this.store$.dispatch(new searchBoxActions.Search({tags: tagInserted}));
    }
    else{
      return;
    }

  }

  removeUrl(url){
    if(url.includes('ik.imagekit.io') || url.includes('images.goodseeker.com')){
      return url.split("?")[0]
    }
    else{
      return url
    }
  
   }

  checktagshistory(tag){

    var tagStore = [];
    if(this.searchBoxStatus && this.searchBoxStatus.searchpayload){

      if(this.searchBoxStatus.searchpayload.tags.length){
        
        tagStore = this.searchBoxStatus.searchpayload.tags;
        tagStore.push(tag);
      }
      else{
          
         tagStore = [tag]
      }
    }
    else{
      tagStore = [tag];
    }
    return tagStore;
  }

  public hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.3)';
    }
  }
  public getButtons() {
    if (this.currentCompany.buttons) {
      const x = this.currentCompany.buttons.filter(f => f.isSelected);
      return x;
    }
    return null;
  }
  navigatetocomapnywebsite()
  {
    window.open(this.currentCompany.website ,'_blank')
  }

  showorhide(data){
    if(this.currentCompany && this.currentCompany.frequentTags && this.currentCompany.frequentTags[data.toString()]){
      return this.currentCompany.frequentTags[data.toString()]?.is_header_part;
    }
  }
}

