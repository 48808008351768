<div class="comment-single is-focus fadeInDown" [ngClass]="{'is-active transform-none': isActiveAnimation}">
<div class="comment-single__avatar">
    <gs-avatar isBackground="true" [src]="comment?.avatar" classCss="avatar">
</gs-avatar>
  </div>
  <div class="comment-single__content">
    <div class="comment-single__head">
      <div class="comment-single__name">
        <a *ngIf="comment?.author?.slug">{{comment?.displayName}}</a>
        <span class="text-dark" *ngIf="!comment?.author?.slug">{{comment?.displayName}}</span>
      </div>
      <!-- <div *ngIf="!inbound" class="comment-single__like text-pink-light" (click)="toggleLike()">
        <i *ngIf="!isLikeProcessing" class="fa-regular fa-heart" style="font-size: 18px; font-weight:400"
          [ngClass]="{'far': !comment?.isLiked, 'fas': comment?.isLiked}"></i>
        <i *ngIf="isLikeProcessing" class="fas fa-spinner fa-pulse"></i>
      </div> -->
    </div>
    <div class="comment-single__desc" *ngIf="!isEditing">{{comment?.content}}</div>
    <div class="comment-single__button" *ngIf="isAllowEdit && !isEditing && !inbound">
      <a href="javascript:void(0)" (click)="edit()">Edit</a>
      <a href="javascript:void(0)" (click)="deleteComfirm()">• Delete</a>
    </div>
    <form [formGroup]="commentForm" (ngSubmit)="update()" [hidden]="!isEditing">
      <app-text-area-emoji formControlName="content" placeholder="Click to comment">
      </app-text-area-emoji>
      <div class="comment-single__button" *ngIf="isEditing && !inbound">
        <button type="submit" class="btn btn-blue-light-extra btn-rounded">
          Post
          <i *ngIf="isUpdateProcessing" class="fas fa-spinner fa-pulse"></i>
        </button>
        <button (click)="cancel()" type="button" class="btn btn-empty btn-rounded">Cancel</button>
      </div>
    </form>
  </div>
  <div class="comment-single__modal" [ngClass]="{'is-open': isConfirmCanceling}">
    <div class="comment-single__modal-content">
      <div class="comment-single__modal-header">Are you sure?</div>
      <div class="comment-single__modal-footer">
        <button type="button" (click)="confirmNo()"
          class="btn btn-xs btn-gray-light btn-rounded delete-comment-no">No</button>
        <button type="button" (click)="confirmYes()"
          class="btn btn-xs btn-blue-light-extra btn-rounded delete-comment-yes">Yes</button>
      </div>
    </div>
  </div>
</div>