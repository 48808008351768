import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentModalBase } from '@framework/base/component';
import { ConfirmEmailService } from '@modules/shared/services/confirm-email.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-draft',
  templateUrl: './confirm-draft.component.html',
  styleUrls: ['./confirm-draft.component.css']
})
export class ConfirmDraftComponent extends ComponentModalBase<any> {
  private activeRouteSubscription: Subscription;
  public draftForm: FormGroup;


  constructor(
    private activatedRoute: ActivatedRoute,
    private confirmEmailService: ConfirmEmailService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    super();
     this.initForm();
  }


  private initForm() {
    this.draftForm = this.formBuilder.group({
          draft_type:['',Validators.required],
    });

  }

    protected onInit(): void {
  

    }
    protected onDestroy(): void {
    }
    protected onChanges(): void {
  
    }
    protected onDoCheck(): void {
  
    }
    protected onAfterContentInit(): void {
  
    }
    protected onAfterContentChecked(): void {
  
    }
    protected onAfterViewInit(): void {
      // this.loadScript();    
    }
    protected onAfterViewChecked(): void {
  
    }
    confirm(){
    this.onClose.emit(this.draftForm.value.draft_type)
  }
  cancel(){
    this.onClose.emit(false)
  }

}
