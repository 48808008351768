import { Component, SimpleChanges, Inject, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component/component.base';
import { LandingPageViewModel } from '@modules/company/models/store-view-models';
import { CoreState } from '@app/store/core/states';
import { landingPageSelectors } from '@modules/company/store/selectors';
import { ModalService } from '@framework/services';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import {ShareModalContainerComponent} from "@modules/shared/components/commons/share-modal-container";

@Component({
  selector: 'co-pb-lp-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends ComponentBase {
  // Private variables
  private selectLandingPageDetail$: Observable<LandingPageViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;


  private selectLandingPageDetailSubscription: Subscription;
  private selectCurrentUserProfileSubscription: Subscription;
  // Public variables
  public landingPage: LandingPageViewModel;
  public currentUserProfile: UserProfileViewModel;
  public  insideIframe: boolean = false;

  @Input() showSubmitsttorybutton:boolean = true;



  constructor( private store$: Store<CoreState>, private modalService: ModalService) {
    super();

    this.selectLandingPageDetail$ = this.store$.select(landingPageSelectors.selectLandingPage);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    if (window.location !== window.parent.location) {
      this.insideIframe = true;
    } else {
      this.insideIframe = false;
    }
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectLandingPageDetailSubscription = this.selectLandingPageDetail$.subscribe((landingPage) => {
      if (landingPage) {
        this.landingPage = landingPage;
      }
    });

    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      this.currentUserProfile = userProfile;
    });
  }

  private unsubscribe() {
    this.selectLandingPageDetailSubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
  }
  // Public functions

  public openShareModal() {
    this.modalService.showModal(ShareModalContainerComponent, {}, { animated: false }).then(() => {

    });
  }

  public getButtons() {
    if (this.landingPage.buttons) {
      return this.landingPage.buttons.filter(f => f.isSelected);
    }
    return null;
  }
}
