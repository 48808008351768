import { Component, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';

import { ComponentBase } from '@framework/base/component/component.base';
import { GridViewFilterOptionsModel } from './models/grid-view-filter-options.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Constants } from '@modules/shared/constants';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-grid-view-filter',
  templateUrl: './grid-view-filter.component.html',
  styleUrls: ['./grid-view-filter.component.scss']
})
export class GridViewFilterComponent extends ComponentBase {
  // Inputs
  @Input() options: GridViewFilterOptionsModel = new GridViewFilterOptionsModel();
  // Outputs
  @Output() filter: EventEmitter<any> = new EventEmitter<any>();

  @Output() changeRoles: EventEmitter<any> = new EventEmitter<any>();

  @Input() showSearchbar: boolean = true;
  // Private variables
  private filterFormChangeSubscription: Subscription;
  // Public variables
  public filterForm: UntypedFormGroup;
  public columnType = Constants.columnType;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();

    this.initForm();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    this.updateFormControls();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions
  private subscribe() {
    this.filterFormChangeSubscription = this.filterForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((values) => {
        this.filter.emit(values);
      });
  }

  private unsubscribe() {
    this.filterFormChangeSubscription.unsubscribe();
  }

  private initForm() {
    this.filterForm = this.formBuilder.group({
      searchText: ['']
    });
  }

  private updateFormControls() {
    this.options.filters.forEach(filter => {
      this.filterForm.addControl(filter.name, new UntypedFormControl(filter.defaultValue));
    });
  }

  getGridvalue(data)
  {
    this.changeRoles.emit(data.target.value);
  }
  // Public functions

}

